import { IJob, IProject } from '@onarchipelago/dice/EnrichmentProjects/types';
import { FeatureTypes } from '@onarchipelago/platform/SystemSettings/Flags/types';
import { Organization } from '@app/graphql/types';
import { IGraphQLDocument } from '@app/queries/documents/types';
import { PropertyDataInput } from '@app/queries/properties/types';
import { AssignRoleV2Input, Role } from '../rbac/types';
import {
  CarrierExportCode,
  OrgExportConfigInput,
  SetOrgPropertyConfigInput,
  SetOrgSubmissionConfigInput,
} from './orgConfig.types';

export interface IGraphQLOrganization {
  createdBy: string;
  id: string;
  name: string;
  reportingType: string;
  salesChannel: string;
  orgType: string;
  orgLevel: string;
  tags: Array<string>;
  autoDomains: Array<string>;
  displayCurrency: string;
  enableUploadPortal: boolean;
  onboardingDocuments: Array<IGraphQLDocument>;
  projects: Array<IProject>;
  enableStreamDashboard: boolean;
  enableSubValues: boolean;
  enableCurrencyConversion: boolean;
  showNonEnrichmentDocs: boolean;
  carrierExports: CarrierExportCode[];
  enableRedirectOnSOVUpload: boolean;
  enableSecondaryModifiers: boolean;
  enableSendEmailOnSOVUpload: boolean;
  enablePropertyManagerEdit: boolean;
  excludeFromReporting: boolean;
  enableUnderwriterEdit: boolean;
  enableRiskManagerAssessment: boolean;
  enableCarrierAssessment: boolean;
  enabledFeatures?: string[];
  industry?: string;
  businessType?: string;
  unitOfMeasurement?: string;
  enablePreCheck?: boolean;
  enableSOVManager?: boolean;
}

// Like IGraphQLOrganization, but with mostly optional fields
// if not all data is available, for example as a DM or DE
export interface RestrictedOrg {
  name: string;
  createdBy?: string;
  id?: string;
  orgType?: string;
  tags?: Array<string>;
  autoDomains?: Array<string>;
  displayCurrency?: string;
  enableUploadPortal?: boolean;
  onboardingDocuments?: Array<IGraphQLDocument>;
  projects?: Array<IProject>;
  enableStreamDashboard?: boolean;
  enableSecondaryModifiers?: boolean;
  enablePropertyManagerEdit?: boolean;
  excludeFromReporting?: boolean;
  enableSendEmailOnSOVUpload?: boolean;
  enableRedirectOnSOVUpload?: boolean;
  enabledFeatures?: string[];
  industry?: string;
  businessType?: string;
}

interface ICreateOrgInput {
  orgName: string;
  reportingType: string;
  orgType: string | null;
  orgLevel: string | null;
  salesChannel: string;
  autoDomains: Array<string>;
  displayCurrency: string;
  enableUploadPortal: boolean;
  enableStreamDashboard: boolean;
  enableSecondaryModifiers: boolean;
  enableRiskManagerAssessment: boolean;
  enableCarrierAssessment: boolean;
  enableUnderwriterEdit: boolean;
  showNonEnrichmentDocs: boolean;
  enableSubValues: boolean;
  enableCurrencyConversion: boolean;
  excludeFromReporting: boolean;
  enableSendEmailOnSOVUpload: boolean;
  enableRedirectOnSOVUpload: boolean;
  industry?: string;
  businessType?: string;
  unitOfMeasurement?: string;
  salesforceAccountID: string | null;
}
export interface ICreateOrgVariables {
  input: ICreateOrgInput;
}
export interface ICreateOrgData {
  createOrg: IGraphQLOrganization;
}

interface IUpdateOrgInput {
  orgID: string;
  orgName: string;
  reportingType: string;
  orgType: string | null;
  salesChannel: string;
  autoDomains: Array<string>;
  displayCurrency: string;
  enableUploadPortal: boolean;
  enableStreamDashboard: boolean;
  enableSubValues: boolean;
  showNonEnrichmentDocs: boolean;
  enableCurrencyConversion: boolean;
  enableSecondaryModifiers: boolean;
  enablePropertyManagerEdit: boolean;
  enableUnderwriterEdit: boolean;
  enableRiskManagerAssessment: boolean;
  enableCarrierAssessment: boolean;
  excludeFromReporting: boolean;
  enableSendEmailOnSOVUpload: boolean;
  enableRedirectOnSOVUpload: boolean;
  industry?: string;
  businessType?: string;
  unitOfMeasurement?: string;
  salesforceAccountID: string | null;
  enableSOVManager?: boolean;
}

export interface IUpdateOrgVariables {
  input: IUpdateOrgInput;
  propertyInput: SetOrgPropertyConfigInput;
  exportInput: OrgExportConfigInput;
  submissionInput: SetOrgSubmissionConfigInput;
}

export interface IUpdateOrgData {
  updateOrg: IGraphQLOrganization;
}

export interface IGetJobVariables {
  jobID: string;
}
export interface IGetJobData {
  job: IJob;
}

export interface SubmitJobInput {
  jobID: string;
  comment?: string;
  followUpDocClassifications?: string[];
  propertyData?: PropertyDataInput;
  worksheet?: Blob;
}

export interface ISubmitJobVariables {
  input: SubmitJobInput;
}
export interface ISubmitJobData {
  submitJob: string;
}

export interface CheckoutJobData {
  checkoutJob: {
    id: string;
    contentType?: string;
    extension?: string;
    filename: string;
    httpURL: string;
    sizeInBytes: string;
  };
}

export interface StartEditPropertiesJobData {
  startEditPropertiesJob: StartJobReturn;
}

export interface StartEditLossesJobData {
  startEditLossesJob: StartJobReturn;
}

export interface StartAddLossesJobVariables {
  input: {
    orgName: string;
    projectName?: string;
  };
}
export interface SubmitAddLossesJobData {
  submitAddLossesJob: {
    transitionId: string;
  };
}

export interface SubmitAddLossesJobVariables {
  input: {
    jobID: string;
    worksheet: Blob;
    comment?: string;
  };
}
export interface StartAddLossesJobData {
  startAddLossesJob: StartJobReturn;
}

export interface StartJobReturn {
  jobID: string;
  worksheet: {
    id: string;
    contentType?: string;
    extension?: string;
    filename: string;
    httpURL: string;
    sizeInBytes: string;
  };
}

export interface StartEditPropertiesJobInput {
  input: {
    orgName: string;
    propertyIDs?: Array<string>;
    attributeNames?: Array<string>;
    streamSlug: string;
  };
}
export interface SubmitEditPropertiesJobInput {
  input: {
    jobID: string;
    worksheet?: Blob;
    comment?: string;
  };
}

export interface StartEditLossesJobInput {
  input: {
    orgName: string;
    propertyArchipelagoIDs?: Array<string>;
    attributeNames?: Array<string>;
    projectName?: string;
  };
}

export interface CheckoutJobVariables {
  input: {
    jobID: string;
    comment?: string;
  };
}

interface IDeleteOrgInput {
  orgName: string;
}
export interface IDeleteOrgVariables {
  input: IDeleteOrgInput;
}
export interface IDeleteOrgData {
  deleteOrg: boolean;
}

export interface IGetOrganizationsData {
  organizations: Array<IGraphQLOrganization>;
}

export interface OrgSubmissionConfig {
  orgId: string;
  orgName: string;
  enableDashboard: boolean;
  templateOrgName?: string;
}

export interface IGetOrganizationData {
  organization: Organization;
  orgExportConfig: {
    carrierExports: Array<CarrierExportCode>;
  };
  orgPropertyConfig: {
    orgName: string;
    enableLosses?: boolean;
    lossStartYear?: number;
  };
  orgSubmissionConfig: OrgSubmissionConfig;
}
export interface IGetOrganizationVariables {
  name: string;
  id: string;
}

export enum EJobType {
  Enrichment = 'Enrichment',
  EnrichmentWithProvenance = 'EnrichmentWithProvenance',
  ColumnMapping = 'ColumnMapping',
  Ingest = 'Ingest',
  Geo = 'Geo',
  AddProperties = 'AddProperties',
  AddLosses = 'AddLosses',
  EditProperty = 'EditProperty',
  EditLosses = 'EditLosses',
  DisposeProperties = 'DisposeProperties',
  PALIngest = 'PALIngest',
  EditProperties = 'EditProperties',
}

export enum EJobStatus {
  None = 'None',
  Ready = 'Ready',
  InProgress = 'InProgress',
  ValidateFailed = 'ValidateFailed',
  ReadyForReview = 'ReadyForReview',
  ChangesRequested = 'ChangesRequested',
  Failed = 'Failed',
  Complete = 'Complete',
  Archived = 'Archived',
  Expired = 'Expired',
  Reverted = 'Reverted',
}

export interface ICreateJobInput {
  name: string;
  type?: EJobType;
  description?: string;
  projectName?: string;
  orgName: string;
  dueOn?: string;
  doerUserID?: string;
  reviewerUserID?: string;
  requesterUserID?: string;

  propertyIDs?: Array<string>;
  attributeNames?: Array<string>;
  references?: Array<string>;

  expireInHours?: number;

  sovDocId?: string;
  worksheetFormat?: 'MultiColumn' | 'SingleColumn';
  streamSlug?: string;
}
export interface AddPropertiesJobInput {
  name: string;
  type: EJobType;
  projectName: string;
  orgName: string;
  dueOn?: string;
  doerUserID: string;
  reviewerUserID: string;
}
export interface ICreateJobVariables {
  input: ICreateJobInput;
}
export interface ICreateJobData {
  createJob: IJob;
}

interface IUpdateJobInput {
  jobID: string;
  description?: string;
  attributeName?: Array<string>;
  status?: EJobStatus;
  dueOn?: string;
  doerUserID?: string;
  reviewerUserID?: string;
  references?: Array<string>;
}
export interface IUpdateJobVariables {
  input: IUpdateJobInput;
}
export interface IUpdateJobData {
  updateJob: IJob;
}

export interface AddPropertiesJobVariables {
  input: AddPropertiesJobInput;
}

interface ApproveJobInput {
  jobID: string;
  comment?: string;
}

export interface ApproveJobVariables {
  input: ApproveJobInput;
}
export interface ApproveJobData {
  approveJob: IJob;
}

interface JobsFilter {
  orgName?: string;
  orgID?: string;
  projectName?: string;
  userID?: string;
  propertyID?: string;
  type?: EJobType;
  activeOnly?: boolean;
}
export interface GetJobsV2Variables {
  jobsFilter: JobsFilter;
  pageSize: number;
  pageCursor?: string;
}

export interface GetJobsV2PageInfo {
  cursor: string;
  totalCount: number;
}
export interface GetJobsV2Data {
  jobsV2: {
    jobs: Array<IJob>;
    pageInfo: GetJobsV2PageInfo;
  };
}

export interface IGetFeaturessData {
  features: Array<IGraphQLFeature>;
}

export interface IGraphQLFeatureOrg {
  id: string;
  name: string;
}

export interface IGraphQLFeature {
  id: number;
  name: string;
  enable: boolean;
  forAllOrgs: boolean;
  forOrgNames: Array<IGraphQLFeatureOrg>;
}

export interface IFeatureMutation {
  configureFeature: IGraphQLFeature;
}

export interface IFeatureMutationVariables {
  input: IFeatureMutationInput;
}

export interface IFeatureMutationInput {
  enable?: boolean;
  featureName: FeatureTypes;
  forAllOrgs?: boolean;
  forOrgNames?: Array<string>;
}

export interface IReportingViewTasksData {
  reportingViewTasks: [IReportingViewTask];
}

export interface IReportingViewTask {
  id: string;
  name: string;
  organizationId: string;
  orgName: string;
  streamId: string;
  snapshot: string;
  status: string;
  createdBy: UserProfile;
  errorDescription: string;
  createdAt: Date;
  errorAt: Date;
  doneAt: Date;
  readableDuration: string;
  stats: string;
}

export interface UserProfile {
  userID: string;
  email: string;
  givenName?: string;
  familyName?: string;
  title?: string;
  pictureURL?: string;
  phone?: string;
  userCode?: string;
  companyName?: string;
  lastLogin?: string; // Could contain datetime or 'temporarily_unavailable' value
}

export interface IReportingViewTasksVariables {
  input: {
    orgName: string;
    streamSlug: string;
  };
}

export interface INotifyReportingViewServiceVariables {
  input: {
    orgName: string;
    streamSlug: string;
  };
}

export interface INotifyReportingViewServiceData {
  notifyReportingViewService: boolean;
}
export interface IBatchProcessRolesVariables {
  input: {
    grants: AssignRoleV2Input[];
    // revokes // TODO
  };
}

export interface IBatchProcessRolesData {
  batchProcessRoles: boolean;
}
