import React, { FC, useContext } from 'react';
import { EuiText, EuiTextColor, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import BenchmarkChart from '../Charts/BenchmarkChart/BenchmarkChart';
import ChartTitle from '../Charts/ChartTitle/ChartTitle';
import ErrorState from '../components/ErrorState';
import { ReadinessReportContext } from './ReadinessReportContext';

const SnapshotBenchmark: FC = () => {
  const {
    snapshotBenchmarkData: data,
    snapshotBenchmarkLoading: loading,
    snapshotBenchmarkError: error,
  } = useContext(ReadinessReportContext);

  return (
    <>
      {loading && !error && <LoadingSpinnerV2 dataTestId="dqs-loading" />}
      {error && !loading && <ErrorState />}
      {data && !loading && !error && (
        <>
          <ChartTitle>Benchmark</ChartTitle>
          <EuiText size="s">
            <p>
              <EuiTextColor color="subdued">
                This is your data quality relative to the other portfolios on Archipelago.
              </EuiTextColor>
            </p>
          </EuiText>
          <Spacer size="s" />
          <BenchmarkChart data={data?.qualitySnapshotBenchmark} />
        </>
      )}
    </>
  );
};

export default SnapshotBenchmark;
