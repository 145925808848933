import styled from '@emotion/styled';
import { EuiFlexItem, EuiTitle } from 'ui';

export const ContentContainer = styled.div`
  overflow: hidden;
`;
export const MapContainer = styled(EuiFlexItem)<{ full: boolean }>`
  ${({ full }) =>
    full
      ? `
        grid-column: span 3;
        overflow: scroll;
        border: 1px solid #e0e0e0;
        `
      : `
        grid-column: span 2;
        grid-row: span 2;
        padding: 8px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        `};
`;

export const ViewContainer = styled(EuiFlexItem)<{ fullHeightVh: boolean }>`
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: scroll;
  grid-gap: 8px;
  height: ${({ fullHeightVh }) => (fullHeightVh ? '100vh' : '100%')};
  padding-bottom: ${({ fullHeightVh }) => (fullHeightVh ? '250px' : '0px')};
`;

export const OverviewHeader = styled(EuiTitle)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const ChartContainer = styled.div<{ height: number }>`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: ${({ height }) => height}px;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      border-bottom: none;
    }
  }
`;
