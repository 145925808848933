import gql from 'graphql-tag';

// ----- MUTATION ----- //

export const REQUEST_ACCESS = gql`
  mutation requestAccess($input: RequestAccessInput!) {
    requestAccess(input: $input)
  }
`;

// ----- TYPES ----- //

export interface RequestAccessData {
  requestAccess: boolean;
  accessPending: boolean;
}

export interface RequestAccessVariables {
  input: {
    streamSlug: string;
    userEmail: string;
  };
}
