import React from 'react';
import { Badge, EuiFlexGroup, EuiFlexItem, Icon } from 'ui';
import { tabToPath } from '../Layout';
import { InboxItemContainer, NotificationBadge } from './SideNav.emotion';

export const STREAMS_LABEL = 'Portfolio';
export const SOV_LABEL = 'SOV';
export const STREAMS_PATH = 'streams';
export const SUBMISSIONS_LABEL = 'Accounts';
export const SUBMISSIONS_PATH = 'accounts';
export const ALERTS_CONFIG_PATH = 'alerts-config';
export const ALERTS_CONFIG_LABEL = 'Alerts Config';
export const PROJECTS_LABEL = 'Projects';
export const PROJECTS_PATH = 'projects';
export const USERS_LABEL = 'Users';
export const USERS_PATH = 'users';
export const PRECHECK_LABEL = 'Pre-Check';
export const PRECHECK_PATH = 'precheck';
export const HOME_PATH = 'home';
export const HOME_LABEL = 'Home';
export const HUB_LABEL = 'Hub';
export const LIBRARY_PATH = 'library';
export const LIBRARY_LABEL = 'Library';
export const SUPPORT_PATH = 'support';
export const SUPPORT_LABEL = 'Support';
export const COPILOT_PATH = 'copilot';
export const COPILOT_LABEL = 'Copilot';
export const INBOX_LABEL = 'Inbox';
export const INBOX_PATH = 'inbox';
export const CASUALTY_LABEL = 'Casualty';
export const CASUALTY_PATH = 'casualty';

export const HOME_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="home" />
    </EuiFlexGroup>
  ),
  id: HOME_PATH,
  label: HOME_LABEL,
  path: HOME_PATH,
};

export const HUB_ITEM = (history, selectedOrganizationName) => ({
  icon: (
    <EuiFlexGroup>
      <Icon name="home" />
    </EuiFlexGroup>
  ),
  id: HOME_PATH,
  label: HUB_LABEL,
  onClick: () => {
    if (window.location.pathname === tabToPath(HOME_PATH, selectedOrganizationName)) {
      const goodDataIframe = document.getElementById('embedded-app-frame') as HTMLIFrameElement;
      if (goodDataIframe) {
        // reload the iframe when clicked on again
        goodDataIframe.src = `${goodDataIframe.src.split('?')[0]}?reload=${new Date().getTime()}`;
      }
    } else {
      history.push(tabToPath(HOME_PATH, selectedOrganizationName));
    }
  },
  path: HOME_PATH,
});

export const STREAMS_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="layers" />
    </EuiFlexGroup>
  ),
  id: STREAMS_PATH,
  label: STREAMS_LABEL,
  path: STREAMS_PATH,
};

export const PRECHECK_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="checkCircle" />
    </EuiFlexGroup>
  ),
  id: PRECHECK_PATH,
  label: PRECHECK_LABEL,
  path: PRECHECK_PATH,
};

export const SUBMISSIONS_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="send" />
    </EuiFlexGroup>
  ),
  id: SUBMISSIONS_PATH,
  label: SUBMISSIONS_LABEL,
  path: SUBMISSIONS_PATH,
};

export const PROJECTS_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="briefcase" />
    </EuiFlexGroup>
  ),
  id: PROJECTS_PATH,
  label: PROJECTS_LABEL,
  path: PROJECTS_PATH,
};

export const USERS_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="user" />
    </EuiFlexGroup>
  ),
  id: USERS_PATH,
  label: USERS_LABEL,
  path: USERS_PATH,
};

export const LIBRARY_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="fileText" />
    </EuiFlexGroup>
  ),
  id: LIBRARY_PATH,
  label: LIBRARY_LABEL,
  path: LIBRARY_PATH,
};

export const INBOX_ITEM = (notificationNumber: number) => ({
  icon: (
    <EuiFlexGroup>
      <Icon name="inbox" />
    </EuiFlexGroup>
  ),
  id: INBOX_PATH,
  label: (
    <InboxItemContainer alignItems="center" justifyContent="center">
      <EuiFlexItem>{INBOX_LABEL}</EuiFlexItem>
      {!!notificationNumber && (
        <EuiFlexItem grow={false}>
          <NotificationBadge>{notificationNumber}</NotificationBadge>
        </EuiFlexItem>
      )}
    </InboxItemContainer>
  ),
  path: INBOX_PATH,
});

export const SUPPORT_ITEM = (unreadCount: number) => ({
  icon: (
    <EuiFlexGroup>
      <Icon name="help" />
    </EuiFlexGroup>
  ),
  id: SUPPORT_PATH,
  label: (
    <EuiFlexGroup alignItems="center" justifyContent="center">
      <EuiFlexItem>{SUPPORT_LABEL}</EuiFlexItem>
      {!!unreadCount && (
        <EuiFlexItem grow={false}>
          <Badge color="primary" label={`${unreadCount}`} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  ),
  path: SUPPORT_PATH,
});

export const COPILOT_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="search" />
    </EuiFlexGroup>
  ),
  id: COPILOT_PATH,
  label: COPILOT_LABEL,
  path: COPILOT_PATH,
};

export const ALERTS_CONFIG_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="gear" />
    </EuiFlexGroup>
  ),
  id: ALERTS_CONFIG_PATH,
  label: ALERTS_CONFIG_LABEL,
  path: ALERTS_CONFIG_PATH,
};

export const CASUALTY_ITEM = {
  icon: (
    <EuiFlexGroup>
      <Icon name="hexagon" />
    </EuiFlexGroup>
  ),
  id: CASUALTY_PATH,
  label: CASUALTY_LABEL,
  path: CASUALTY_PATH,
};
