import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfigureAuthorizationMutationVariables = Types.Exact<{
  input: Types.ConfigureAuthorizationInput;
}>;


export type ConfigureAuthorizationMutation = { __typename?: 'Mutation', configureAuthorization: { __typename?: 'AuthorizationConfig', orgId: string, orgName: string, ssoConfigName?: string | null, accountAdminToManageAccountAdminDisabled: boolean, marketAccessDomains?: Array<string> | null } };


export const ConfigureAuthorizationDocument = gql`
    mutation ConfigureAuthorization($input: ConfigureAuthorizationInput!) {
  configureAuthorization(input: $input) {
    orgId
    orgName
    ssoConfigName
    accountAdminToManageAccountAdminDisabled
    marketAccessDomains
  }
}
    `;
export type ConfigureAuthorizationMutationFn = Apollo.MutationFunction<ConfigureAuthorizationMutation, ConfigureAuthorizationMutationVariables>;

/**
 * __useConfigureAuthorizationMutation__
 *
 * To run a mutation, you first call `useConfigureAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureAuthorizationMutation, { data, loading, error }] = useConfigureAuthorizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureAuthorizationMutation, ConfigureAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureAuthorizationMutation, ConfigureAuthorizationMutationVariables>(ConfigureAuthorizationDocument, options);
      }
export type ConfigureAuthorizationMutationHookResult = ReturnType<typeof useConfigureAuthorizationMutation>;
export type ConfigureAuthorizationMutationResult = Apollo.MutationResult<ConfigureAuthorizationMutation>;
export type ConfigureAuthorizationMutationOptions = Apollo.BaseMutationOptions<ConfigureAuthorizationMutation, ConfigureAuthorizationMutationVariables>;