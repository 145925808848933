import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonEmpty, EuiFlexGroup, EuiFlexItem } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { canManageUsers } from '@app/containers/AuthProvider/helper';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { Casualty } from '@app/graphql/types';
import { TrackGroupUserMgmt, useTracker } from '@app/hooks/useTracker';
import { CreateCasualtyFlyout } from './CasualtyFlyout';

interface Props {
  // HACK: to lookup the enterprise account id later.
  casualtyList: Array<Casualty>;
}

export const CasualtyDashboardButtons: FC<Props> = ({ casualtyList }) => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const history = useHistory();
  const { selectedOrganization } = useContext(UserSessionContext);
  const mixpanel = useTracker('mixpanel');
  const { showFlyout, closeFlyout } = useContext(FlyoutContext);
  const onClose = () => {
    closeFlyout();
  };
  const createFlyout = <CreateCasualtyFlyout casualtyList={casualtyList} onClose={onClose} />;

  return (
    <EuiFlexGroup
      data-testid="casualty-dashboard-buttons"
      direction="row"
      gutterSize="s"
      alignItems="center"
      justifyContent="flexEnd"
      responsive={false}
    >
      {canManageUsers(account, selectedOrganization?.id, selectedOrganization?.enabledFeatures) && (
        <EuiFlexItem grow={false}>
          <ButtonEmpty
            onClick={() => {
              mixpanel.track(`${TrackGroupUserMgmt.prefix}: Manage users clicked`);
              history.push(`/organizations/${selectedOrganization?.name}/users`);
            }}
            size="s"
            label="Manage users"
          />
        </EuiFlexItem>
      )}
      {(account?.submissionCreateOrgs?.[0]?.name || isAdmin) && (
        <EuiFlexItem grow={false}>
          <Button
            data-testid="add-casualty"
            onClick={() => {
              showFlyout(createFlyout);
            }}
            size="s"
            fill
            label="Submit a Casualty Account"
            iconName="plus"
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
