import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLink,
  EuiPanel,
  EuiText,
  HorizontalAlignment,
  Select,
  useToast,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useUpdateCasualtyMutation } from '@app/graphql/casualty/mutations/__generated__/updateCasualty.generated';
import { Casualty, CasualtyState } from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { CreateCasualtyFlyout } from './CasualtyFlyout';

interface Props {
  casualtyList: Array<Casualty>;
  loading: boolean;
}

export const InlineSelect = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  #select {
    flex: 4;
  }

  .euiFormControlLayout {
    max-width: 150px;
  }

  #finalizationButton {
    flex: 1;
  }
`;

export const EditCasualtyCell: FC<{
  casualty: Casualty;
}> = ({ casualty }) => {
  const { account } = useAuth();
  const toast = useToast();
  const [updateCasualty, { loading }] = useUpdateCasualtyMutation({
    onCompleted: () => {
      toast({ title: 'Succesfully set state to complete' });
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const isAdmin = account?.permissions?.admin;

  if (!isAdmin) {
    return <>{casualty.accountStatus}</>;
  }
  if (casualty.accountStatus === 'ProcessingComplete') {
    return <>Processing Complete</>;
  }
  if (loading) {
    return <LoadingSpinnerV2 variant="m" />;
  }
  return (
    <InlineSelect>
      <div id="select">
        <Select
          options={[
            {
              label: 'Submitted',
              value: 'Submitted',
            },
            {
              label: 'Processing Complete',
              value: 'ProcessingComplete',
            },
          ]}
          fullWidth
          value={casualty.accountStatus}
          onChange={(newValue) => {
            if (newValue === 'ProcessingComplete') {
              updateCasualty({
                variables: {
                  input: {
                    id: casualty.id,
                    state: CasualtyState.ProcessingComplete,
                  },
                },
              });
            }
          }}
        />
      </div>
    </InlineSelect>
  );
};

export const CasualtyDashboard: FC<Props> = ({ casualtyList, loading }) => {
  const history = useHistory();
  const [casualty, setCasualty] = useState<Casualty>();
  const { showFlyout, closeFlyout } = useContext(FlyoutContext);

  const onClose = () => {
    closeFlyout();
    setCasualty(undefined);
  };

  const createFlyout = (
    <CreateCasualtyFlyout casualtyList={casualtyList} viewCasualty={casualty} onClose={onClose} />
  );

  useEffect(() => {
    if (casualty !== undefined) {
      showFlyout(createFlyout);
    }
  }, [casualty]);

  const goTo = (accountName: string) => {
    history.replace(encodeUrl`/organizations/${accountName}/library`);
  };

  const columns = [
    {
      name: 'Account',
      render: (item: Casualty) => {
        const link = (
          <EuiLink
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              goTo(item.accountOrgName);
            }}
          >
            {item.accountOrgName}
          </EuiLink>
        );
        return link;
      },
      width: '200px',
    },
    {
      name: 'Status',
      render: (item: Casualty) => <EditCasualtyCell casualty={item} />,
      width: '200px',
    },
    {
      name: 'Client Name',
      render: (item: Casualty) => item.clientName,
    },
    {
      name: 'Client/Prospect',
      render: (item: Casualty) => item.clientProspect,
    },
    {
      name: 'Type of business',
      render: (item: Casualty) => item.typeOfBusiness,
    },
    {
      name: 'SIC Code',
      render: (item: Casualty) => item.sicCode,
    },
    {
      name: 'NAICS Code',
      render: (item: Casualty) => item.naicsCode,
    },
    {
      name: 'Primary Contact',
      render: (item: Casualty) => item.primaryContactEmail,
    },
    {
      name: 'Office',
      render: (item: Casualty) => item.office,
    },
    {
      name: 'Region',
      render: (item: Casualty) => item.region,
    },
    {
      align: 'right' as HorizontalAlignment,
      render: (item: Casualty) => (
        <Button
          onClick={() => {
            setCasualty(item);
          }}
          label="View"
        />
      ),
      width: '112px',
    },
  ];

  const renderBody = () => {
    if (loading) {
      return (
        <EuiFlexItem grow={false}>
          <LoadingSpinnerV2 variant="xl" dataTestId="loading-spinner" />
        </EuiFlexItem>
      );
    }
    if (!casualtyList?.length && !loading) {
      return (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" direction="column" justifyContent="center">
            <EuiFlexItem grow={false}>
              <EuiText>
                <p>No casualty in the list.</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      );
    }
    if (!loading && casualtyList?.length > 0) {
      return (
        <EuiFlexItem grow={false}>
          <EuiInMemoryTable
            data-testid="submission-table"
            items={casualtyList || []}
            columns={columns}
            itemId="id"
            isExpandable={false}
            hasActions={false}
            loading={loading}
          />
        </EuiFlexItem>
      );
    }
    return null;
  };

  return (
    <EuiPanel
      style={{
        margin: '16px',
        paddingBottom: '60px' /* padding bottom to account for zendesk widget */,
      }}
    >
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>{/** TODO Toolbar? */}</EuiFlexItem>
        {renderBody()}
      </EuiFlexGroup>
    </EuiPanel>
  );
};
