import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import gql from 'graphql-tag';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor, Icon, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ChartTitle from '../Charts/ChartTitle/ChartTitle';
import ErrorState from '../components/ErrorState';
import HazardExposure from './HazardExposure/HazardExposure';

interface ExposureSummary {
  hazard: string;
  locations: number;
  tiv: number;
  tivPct: number;
}

interface ExposureSummaryData {
  qualitySnapshotExposureSummary: ExposureSummary[];
}

interface ExposureSummaryVars {
  input: { snapshotName: string; streamSlug?: string };
}

export const hazardOrder = ['Earthquake', 'Wind', 'Flood', 'All'];

export const hazardTitles = {
  All: 'Exposure to any of the above',
  Earthquake: 'Earthquake',
  Flood: 'Flood',
  Wind: 'Wind',
};

export const GET_EXPOSURE_SUMMARY = gql`
  query getQualitySnapshotExposureSummary($input: QualitySnapshotExposureSummaryInput!) {
    qualitySnapshotExposureSummary(input: $input) {
      hazard
      locations
      tiv
      tivPct
    }
  }
`;

const ExposureSummary: FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug, displayCurrency } = stream;
  const { currentHazard, currentSnapshot } = snapshots;
  const snapshotName = currentSnapshot?.snapshotName || '';

  const { data, loading, error } = useQuery<ExposureSummaryData, ExposureSummaryVars>(
    GET_EXPOSURE_SUMMARY,
    {
      skip: !streamSlug || !snapshotName,
      variables: {
        input: {
          snapshotName,
          streamSlug,
        },
      },
    },
  );

  const sortByOrder = (a: any, b: any) =>
    hazardOrder.indexOf(a.hazard) - hazardOrder.indexOf(b.hazard);

  const exposureData = [...(data?.qualitySnapshotExposureSummary || [])].sort(sortByOrder);

  return (
    <>
      {loading && !error && <LoadingSpinnerV2 dataTestId="dqs-loading" />}
      {error && !loading && <ErrorState />}
      {data && !loading && !error && (
        <>
          <ChartTitle>Exposure Summary</ChartTitle>
          <EuiText size="s">
            <p>
              <EuiTextColor color="subdued">
                Properties in hazard zones present higher risk and their data quality is even more
                important for the certainty of risk assessment.
              </EuiTextColor>
            </p>
          </EuiText>
          <Spacer size="m" />
          <EuiFlexGroup direction="column" gutterSize="s">
            {exposureData.map((item) => (
              <EuiFlexItem data-testid={`hazard-summary-${item.hazard}`} key={item.hazard}>
                <HazardExposure
                  title={hazardTitles[item.hazard]}
                  TIV={item.tiv}
                  locations={item.locations}
                  percent={item.tivPct}
                  active={item.hazard === currentHazard || currentHazard === 'All'}
                  currency={displayCurrency}
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
          <EuiText size="s">
            <h6 style={{ textTransform: 'none' }}>
              % of TIV <Icon name="arrowRight" size="xs" />
            </h6>
          </EuiText>
        </>
      )}
    </>
  );
};

export default ExposureSummary;
