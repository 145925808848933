import React, { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import gql from 'graphql-tag';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor, EuiToolTip, Icon } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { formatCurrency, formatNumber } from '@app/utils/format';
import ErrorState from '../components/ErrorState';
import ScoreIndicatorBar from '../components/ScoreIndicatorBar';
import { ReadinessReportContext } from './ReadinessReportContext';
import Stat from './Stat';
import { getReadinessGrade, getReadinessGradeMessage } from './utils';

export const GET_SNAPSHOT_SCORE = gql`
  query getQualitySnapshotOverall($input: QualitySnapshotOverallInput!) {
    qualitySnapshotOverall(input: $input) {
      score
      locationCount
      tiv
      scoreChangePct
    }
  }
`;

const PlacementGradeSummary: FC = () => {
  const { snapshotBenchmarkData } = useContext(ReadinessReportContext);
  const { snapshots, stream } = useReportsContext();
  const { streamSlug, displayCurrency } = stream;
  const { currentHazard, currentSnapshot } = snapshots;
  const snapshotName = currentSnapshot?.snapshotName || '';

  const { data, loading, error } = useQuery(GET_SNAPSHOT_SCORE, {
    skip: !streamSlug || !snapshotName || !currentHazard,
    variables: {
      input: {
        hazard: currentHazard,
        snapshotName,
        streamSlug,
      },
    },
  });

  const formattedProperties = formatNumber(data?.qualitySnapshotOverall?.locationCount);
  const formattedTIV = formatCurrency(data?.qualitySnapshotOverall?.tiv, {
    currency: displayCurrency,
  });

  const snapshotScore = snapshotBenchmarkData?.qualitySnapshotBenchmark?.snapshotScore * 100 || 0;
  const topPercentile = getReadinessGrade(snapshotScore);
  const scoreMessage = getReadinessGradeMessage(snapshotScore);

  return (
    <>
      {loading && !error && <LoadingSpinnerV2 dataTestId="dqs-loading" />}
      {error && !loading && <ErrorState />}
      {data && !loading && !error && (
        <>
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <EuiFlexGroup direction="row" alignItems="center" gutterSize="xs">
                <EuiFlexItem grow={false}>
                  <EuiText>
                    <h3>Readiness Summary</h3>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiToolTip
                    position="top"
                    content="The Readiness Summary level of the SoV is an aggregation of the data quality of the individual properties, taking into account their importance (TIV) and riskiness (exposure to hazards)."
                  >
                    <Icon color="primary" name="info" />
                  </EuiToolTip>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="none">
                <EuiFlexItem>
                  <Stat
                    data-testid="dqs-top-percentile"
                    title=""
                    value={`Data Quality ${topPercentile}`}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup gutterSize="none">
                <EuiFlexItem grow={false}>
                  <EuiText size="s">
                    <p style={{ marginLeft: '4px' }}>
                      <EuiTextColor color="subdued">{scoreMessage}</EuiTextColor>
                    </p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup gutterSize="none">
                <EuiFlexItem grow={false}>
                  <ScoreIndicatorBar percentile={topPercentile} tooltips />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="none">
                <EuiFlexItem>
                  <Stat title="TIV" size="small" value={formattedTIV} data-testid="dqs-tiv" />
                </EuiFlexItem>
                <EuiFlexItem>
                  <Stat
                    title="Properties"
                    size="small"
                    value={formattedProperties}
                    data-testid="dqs-properties"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </>
  );
};

export default PlacementGradeSummary;
