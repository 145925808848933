import { ReactElement } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import { GroupByV2, IDistillers } from '@onarchipelago/cx/Stream/types';
import {
  IJob,
  StateTransition,
  TransitionStatus,
} from '@onarchipelago/dice/EnrichmentProjects/types';
import moment from 'moment';
import { FilterQuery } from '@app/components/Filter/types';
import { getMapPositionAsString } from '@app/components/GoogleMap/GoogleMap';
import { IMapPosition } from '@app/components/GoogleMap/types';
import { ColumnKey } from '@app/components/PropertiesGrid/types';
import { SizeBy } from '@app/components/PropertiesMap/types';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { QueryStateParams, useQueryState } from '@app/hooks/useQueryState';
import { SortDirection } from '@app/queries/properties/types';
import { IGraphlQLOrganizationAttributeMetadata , IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLSnapshot } from '@app/queries/streams/types';
import {
  FormatCurrency as _FormatCurrency,
  formatCurrency,
  IFormatCurrencyOptions,
  Val,
} from '@app/utils/format';
import { isGroupable } from '@app/utils/metadata';
import { MODE } from './Distillers/types';
import { IPageState, ISnapshot } from './types';

export type FormatCurrency = _FormatCurrency;

const shortDateLayout = 'MMM DD, YYYY';
export const getShortDate = (date: string | null): string =>
  date ? moment(date).utc().format(shortDateLayout) : '-';

export const getSnapshotsWithLabels = (
  snapshots: Array<IGraphQLSnapshot>,
  propertyStatusLabel: string,
): Array<ISnapshot> => {
  const snapshotWithLabels: Array<ISnapshot> = [];
  let lastBoundSeen = false;
  for (let i = 0; i < snapshots.length; i++) {
    const snapshot = snapshots[i];
    const date = moment(snapshot.date).utc();

    const shortDateLabel = date.format(shortDateLayout);
    let label = snapshot.name;
    if (snapshot.bound && !lastBoundSeen) {
      label = `${propertyStatusLabel || 'Last bound'} - ${label}`;
      lastBoundSeen = true;
    } else if (i === 0) {
      label = `Latest - ${label}`;
    }
    const longDateLabel = `${shortDateLabel} at ${date.format('h:mma')}`;

    snapshotWithLabels.push({ ...snapshot, label, longDateLabel, shortDateLabel });
  }

  return snapshotWithLabels;
};

const isValidSizeBy = (sizeBy: string = '', canViewLosses: boolean = false) =>
  (
    [
      SizeBy.TIV,
      SizeBy.PropertyCount,
      ...(canViewLosses ? [SizeBy.TotalGrossLoss, SizeBy.LossCountTotal] : []),
    ] as Array<string>
  ).includes(sizeBy);

export type IPageStateParams = Partial<IPageState>;

export const KEYS = {
  changesSince: 'changesSince',
  comments: 'comments',
  currentSnapshot: 'currentSnapshot',
  commentsAttribute: 'commentsAttribute',
  expandedColumns: 'expandedColumns',
  explorerPosition: 'explorerPosition',
  explorerTab: 'explorerTab',
  filters: 'filters',
  gridPosition: 'gridPosition',
  groupByFilterValues: 'groupByFilterValues',
  groupByV2: 'groupByV2',
  highlights: 'highlights',
  mapPosition: 'mapPosition',
  mode: 'mode',
  propertyPageTab: 'propertyPageTab',
  sizeBy: 'sizeBy',
  sort: 'sort',
  sortOrder: 'sortOrder',
  userCode: 'uCode',
};

export const getSnapshotValues = (distillers: IDistillers = {}): string => {
  if (distillers?.changesSince?.value) {
    return `${distillers.changesSince.value}`;
  }
  return '';
};

export const getStringAsMapPosition = (mapPosition: string | null): null | IMapPosition => {
  if (!mapPosition) {
    return null;
  }

  const [zoom, lat, lng] = mapPosition.split(',');

  return {
    center: {
      lat: Number(lat),
      lng: Number(lng),
    },
    zoom: Number(zoom),
  };
};

// TODO: Can we DRY these functions out by making them more generic?
export function isMode(mode: string | null): mode is MODE {
  return !!mode && ['gallery', 'grid', 'map'].includes(mode);
}

const isSortOrder = (order: string | null): boolean =>
  !!order && ['ASCENDING', 'DESCENDING'].includes(order);

const getExplorerTab = (tab: string | null): string | null => {
  if (tab && tab.length > 0) {
    return tab;
  }

  if (tab === '') {
    return null;
  }

  return 'Standard';
};

export const VALID_KEYS_TO_OVERRULE_HIGHLIGHTS = [
  KEYS.mode,
  KEYS.explorerTab,
  KEYS.explorerPosition,
  KEYS.filters,
  KEYS.groupByV2,
  KEYS.changesSince,
  KEYS.sizeBy,
  KEYS.sort,
  KEYS.gridPosition,
  KEYS.mapPosition,
];

const SORT_DELIMITER = ',';

const getSort = (sort: string | null): IGraphQLSort | null => {
  if (!sort) {
    return null;
  }

  const [attributeName, order] = sort.split(SORT_DELIMITER);

  if (isSortOrder(order)) {
    const graphQLSort: IGraphQLSort = {
      attributeName: attributeName as ColumnKey,
      order: order as SortDirection,
    };
    return graphQLSort;
  }

  return null;
};

function isRule(rule: RuleGroupType | RuleType): rule is RuleType {
  return Object.prototype.hasOwnProperty.call(rule, 'field');
}

const OPERATORS = {
  CONTAINS: 'c',
  EQUAL: '=',
  GREATER_OR_EQUAL: '>=',
  LESS_OR_EQUAL: '<=',
};

const FILTER_DELIMITER = ';';
const FILTER_INNER_DELIMITER = ',';

type GetFilterQueryAsString = (
  filterQuery: undefined | null | FilterQuery,
) => string | null | undefined;
export const getFilterQueryAsString: GetFilterQueryAsString = (filterQuery) => {
  if (filterQuery === null) {
    return null;
  }
  if (filterQuery === undefined) {
    return undefined;
  }

  return filterQuery.rules
    .map((rule) => {
      if (isRule(rule)) {
        if (Array.isArray(rule.value)) {
          rule.value = rule.value.join(FILTER_INNER_DELIMITER);
        }
        return [rule.field, OPERATORS[rule.operator] || rule.operator, rule.value].join(
          FILTER_INNER_DELIMITER,
        );
      }

      return undefined;
    })
    .filter(Boolean)
    .join(FILTER_DELIMITER);
};

type GetSortAsString = (sort: IGraphQLSort | null | undefined) => string | null | undefined;
export const getSortAsString: GetSortAsString = (sort) => {
  if (sort === null) {
    return null;
  }
  if (sort === undefined) {
    return undefined;
  }

  return [sort.attributeName, sort.order].join(SORT_DELIMITER);
};

export const getFilterStringAsQuery = (filters: string | null): null | FilterQuery => {
  if (!filters) {
    return null;
  }

  const REVERSE_OPERATORS = Object.entries(OPERATORS).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [val]: key,
    }),
    {},
  );

  const rules: Array<RuleType> = filters.split(FILTER_DELIMITER).map((filter, index) => {
    const [field, operator, ...value] = filter.split(FILTER_INNER_DELIMITER);
    return {
      field,
      id: `field-${index}`,
      operator: REVERSE_OPERATORS[operator] || operator,
      value,
    };
  });

  return {
    combinator: 'and',
    id: `field-${rules.length}`,
    rules,
  };
};

const DEFAULT_SORT: IGraphQLSort = {
  attributeName: 'totalInsuredValue',
  order: 'DESCENDING',
};

const encodeBase64 = (data: string) => Buffer.from(data).toString('base64');
export const decodeBase64 = (data: string) => Buffer.from(data, 'base64').toString('ascii');

export const getPageStateFromQueryState = (queryState: URLSearchParams): IPageState => {
  const mode = queryState.get(KEYS.mode);
  const explorerTab = queryState.get(KEYS.explorerTab);
  const explorerPosition = queryState.get(KEYS.explorerPosition);
  const filters = queryState.get(KEYS.filters);

  const decodedGroupByV2 = decodeBase64(queryState.get(KEYS.groupByV2) || '');
  const groupByV2: GroupByV2[] = decodedGroupByV2 ? JSON.parse(decodedGroupByV2) : [];

  const currentSnapshot = queryState.get(KEYS.currentSnapshot);
  const changesSince = queryState.get(KEYS.changesSince);
  const sizeBy = queryState.get(KEYS.sizeBy);
  const userCode = queryState.get(KEYS.userCode);
  const sort = queryState.get(KEYS.sort);
  const gridPosition = queryState.get(KEYS.gridPosition);
  const expandedColumns = queryState.get(KEYS.expandedColumns);
  const highlights = queryState.get(KEYS.highlights);
  const mapPosition = queryState.get(KEYS.mapPosition);
  const comments = queryState.get(KEYS.comments);
  const commentsAttribute = queryState.get(KEYS.commentsAttribute);
  const propertyPageTab = queryState.get(KEYS.propertyPageTab);

  return {
    changesSince,
    currentSnapshot,
    expandedColumns,
    comments,
    explorerPosition,
    commentsAttribute,
    explorerTab: getExplorerTab(explorerTab),
    filters: getFilterStringAsQuery(filters),
    gridPosition,
    groupByV2,
    highlights,
    mapPosition: getStringAsMapPosition(mapPosition),
    mode: isMode(mode) ? mode : MODE.GRID,
    // FIX ME
// @ts-ignore
propertyPageTab,
    

sizeBy,
    

sort: getSort(sort) || DEFAULT_SORT,
    
    
    userCode,
    // highlights: getHighlights(highlights, queryState),
  };
};

export const usePageState = (): [IPageState, (params: IPageStateParams) => void] => {
  const [queryState, setQueryState] = useQueryState();
  const pageState = getPageStateFromQueryState(queryState);

  const setPageState = (nextPageState: IPageStateParams) => {
    const nextQueryState = Object.entries(KEYS).reduce((obj, [key, value]) => {
      if (key === 'filters') {
        return {
          ...obj,
          [value]: getFilterQueryAsString(nextPageState.filters),
        };
      }
      if (key === 'sort') {
        return {
          ...obj,
          [value]: getSortAsString(nextPageState.sort),
        };
      }
      if (key === 'mapPosition') {
        return {
          ...obj,
          [value]: getMapPositionAsString(nextPageState.mapPosition),
        };
      }
      if (key === KEYS.groupByV2 && nextPageState.groupByV2 !== undefined) {
        return {
          ...obj,
          [value]: encodeBase64(JSON.stringify(nextPageState.groupByV2)),
        };
      }

      return {
        ...obj,
        [value]: nextPageState[key],
      };
    }, {} as QueryStateParams);

    setQueryState(nextQueryState);
  };

  return [pageState, setPageState];
};

type Attribute = {
  value: string;
  label: string | ReactElement;
};
type GetGroupByAttributes = (groupable: Array<IGraphQLAttributeMetadata>) => Array<Attribute>;

export const getGroupByAttributes: GetGroupByAttributes = (groupable) => {
  const attributes: Array<Attribute> = [];
  groupable?.forEach(({ name, displayName }) => {
    if (isGroupable(name)) {
      attributes.push({
        label: displayName,
        value: name,
      });
    }
  });

  return attributes;
};

type GetDefaultDistillers = (
  metadata: {
    groupable: Array<IGraphQLAttributeMetadata>;
  },
  pageState: IPageState,
  permissions,
) => IDistillers;
export const getDefaultDistillers: GetDefaultDistillers = (
  { groupable },
  pageState,
  permissions,
) => {
  const lossesDropdownOptions = permissions?.canViewLosses
    ? [
        {
          label: 'Total Gross Loss',
          value: SizeBy.TotalGrossLoss,
        },
        {
          label: 'Count of Losses',
          value: SizeBy.LossCountTotal,
        },
      ]
    : [];

  const { groupByV2, sizeBy } = pageState;
  return {
    groupBy: {
      inputs: [
        {
          label: 'Pivot by',
          name: 'group-by-1',
          options: getGroupByAttributes(groupable),
        },
      ],
      label: 'Groups',
      order: 0,
      value: groupByV2?.map((groupBy) => groupBy.attributeName) || [],
    },
    sizeBy: {
      enableNone: false,
      inputs: [
        {
          label: '',
          name: 'size-by',
          options: [
            {
              label: 'TIV',
              value: SizeBy.TIV,
            },
            {
              label: 'Property Count',
              value: SizeBy.PropertyCount,
            },
            ...lossesDropdownOptions,
          ],
        },
      ],
      label: 'Size By',
      order: 2,
      value: sizeBy && isValidSizeBy(sizeBy, permissions?.canViewLosses) ? [sizeBy] : [SizeBy.TIV],
      visible: (mode: MODE) => mode === MODE.MAP,
    },
  };
};

type GetMetadataByType = (
  propertyFieldMetadata: Array<IGraphQLAttributeMetadata>,
  field: 'groupable' | 'filterable',
) => Array<IGraphQLAttributeMetadata>;
export const getMetadataByType: GetMetadataByType = (propertyFieldMetadata, field) =>
  propertyFieldMetadata?.filter((metadata) => metadata[field]);

type GetNestedMetadataByType = (
  orgMetadata: Array<IGraphlQLOrganizationAttributeMetadata>,
  field: 'groupable' | 'filterable',
) => any;
export const getNestedMetadataByType: GetNestedMetadataByType = (orgMetadata, field) =>
  orgMetadata.filter((o) => o.metadata[field]).map((m) => m.metadata);

type LifeCycleEvent = (transition: StateTransition) => void;
interface CheckCurrentTransitionVariables {
  job: IJob;
  onStarted: LifeCycleEvent;
  onFailed: LifeCycleEvent;
  onCompleted: LifeCycleEvent;
}
export const checkCurrentTransition = ({
  job,
  onStarted,
  onFailed,
  onCompleted,
}: CheckCurrentTransitionVariables) => {
  if (job !== null && job.lifeCycle.length > 0) {
    const currentTransition = job.lifeCycle[0];

    switch (currentTransition.progress) {
      case TransitionStatus.Started:
        onStarted(currentTransition);
        break;

      case TransitionStatus.Failed:
        onFailed(currentTransition);
        break;

      case TransitionStatus.Completed:
        onCompleted(currentTransition);
        break;

      default:
        break;
    }
  }
};

export const showManageStreamButton = (permissions, options) =>
  permissions?.canEditProperties ||
  permissions?.canAddProperties ||
  permissions?.canEditLosses ||
  permissions?.canAddLosses ||
  permissions?.canManageDomainAllowList ||
  permissions?.canManageUserManagers ||
  permissions?.canManageViewers ||
  options.enableStreamEditBtn;

export const formatStreamCurrency = (
  val: Val,
  displayCurrency?: string,
  options?: IFormatCurrencyOptions,
) =>
  formatCurrency(val, {
    ...options,
    currency: displayCurrency || 'USD',
  });
