import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthorizationConfigQueryVariables = Types.Exact<{
  orgName: Types.Scalars['String']['input'];
}>;


export type AuthorizationConfigQuery = { __typename?: 'Query', authorizationConfig: { __typename?: 'AuthorizationConfig', orgId: string, orgName: string, ssoConfigName?: string | null, accountAdminToManageAccountAdminDisabled: boolean, marketAccessDomains?: Array<string> | null } };


export const AuthorizationConfigDocument = gql`
    query AuthorizationConfig($orgName: String!) {
  authorizationConfig(orgName: $orgName) {
    orgId
    orgName
    ssoConfigName
    accountAdminToManageAccountAdminDisabled
    marketAccessDomains
  }
}
    `;

/**
 * __useAuthorizationConfigQuery__
 *
 * To run a query within a React component, call `useAuthorizationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizationConfigQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useAuthorizationConfigQuery(baseOptions: Apollo.QueryHookOptions<AuthorizationConfigQuery, AuthorizationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorizationConfigQuery, AuthorizationConfigQueryVariables>(AuthorizationConfigDocument, options);
      }
export function useAuthorizationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorizationConfigQuery, AuthorizationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorizationConfigQuery, AuthorizationConfigQueryVariables>(AuthorizationConfigDocument, options);
        }
export type AuthorizationConfigQueryHookResult = ReturnType<typeof useAuthorizationConfigQuery>;
export type AuthorizationConfigLazyQueryHookResult = ReturnType<typeof useAuthorizationConfigLazyQuery>;
export type AuthorizationConfigQueryResult = Apollo.QueryResult<AuthorizationConfigQuery, AuthorizationConfigQueryVariables>;