import React, { FC } from 'react';
import moment from 'moment';
import { EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, Spacer } from 'ui';
import { LineOfBusiness, PolicyEffectiveYearId } from '@app/graphql/types';
import { useCasualtyData } from '../context/context';
import { AllPolicyEffectiveYearID, dateFormat, humanReadablePolicyEffectiveYear } from '../utils';

export const StepPolicyEffectiveDates: FC = () => {
  const { casualty, setCasualty } = useCasualtyData();
  const updatePolicyEffectiveDate = (
    e: moment.Moment,
    key: 'al' | 'wc' | 'gl',
    label: PolicyEffectiveYearId,
  ) => {
    setCasualty({
      ...casualty,
      policyEffectiveDates: casualty.policyEffectiveDates.map((policyEffectiveDate) => {
        if (policyEffectiveDate.policyEffectiveYear === label) {
          return {
            ...policyEffectiveDate,
            [key]: e.toDate(),
          };
        }
        return policyEffectiveDate;
      }),
    });
  };
  return (
    <>
      <EuiText>
        <h3>Policy Effective Dates</h3>
      </EuiText>
      <Spacer size="l" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <strong>Policy Year ID</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>AL</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>WC</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>GL</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size="xs" />
      {AllPolicyEffectiveYearID.map((label) => {
        const policyEffectiveYear =
          casualty !== undefined
            ? casualty.policyEffectiveDates?.find(
                (policyEffectiveDate) => policyEffectiveDate.policyEffectiveYear === label,
              )
            : undefined;

        const alDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Al,
        );
        const wcDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Wc,
        );
        const glDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Gl,
        );

        return (
          <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="&nbsp;">
                  <>{humanReadablePolicyEffectiveYear(label)}</>
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiDatePicker
                  selected={
                    !alDisabled && policyEffectiveYear?.al
                      ? moment.utc(policyEffectiveYear?.al)
                      : undefined
                  }
                  onChange={(e) => {
                    updatePolicyEffectiveDate(e, 'al', label);
                  }}
                  dateFormat={dateFormat}
                  disabled={alDisabled}
                  readOnly={!alDisabled && label === PolicyEffectiveYearId.ProspectiveYear}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiDatePicker
                  selected={
                    !wcDisabled && policyEffectiveYear?.wc
                      ? moment.utc(policyEffectiveYear?.wc)
                      : undefined
                  }
                  onChange={(e) => {
                    updatePolicyEffectiveDate(e, 'wc', label);
                  }}
                  dateFormat={dateFormat}
                  disabled={wcDisabled}
                  readOnly={!wcDisabled && label === PolicyEffectiveYearId.ProspectiveYear}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiDatePicker
                  selected={
                    !glDisabled && policyEffectiveYear?.gl
                      ? moment.utc(policyEffectiveYear?.gl)
                      : undefined
                  }
                  onChange={(e) => {
                    updatePolicyEffectiveDate(e, 'gl', label);
                  }}
                  dateFormat={dateFormat}
                  disabled={glDisabled}
                  readOnly={!glDisabled && label === PolicyEffectiveYearId.ProspectiveYear}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <Spacer size="xs" />
          </>
        );
      })}
    </>
  );
};
