import { Moment } from 'moment';
import { IAccountState } from '@app/containers/AuthProvider/types';

export const getHomepageTitle = (localTime: Moment, account: IAccountState): string => {
  let title = 'Hello';
  const hour = localTime.hour();
  if (hour < 12) {
    title = 'Good morning';
  } else if (hour < 18) {
    title = 'Good afternoon';
  } else {
    title = 'Good evening';
  }

  return `${title  }, ${account?.givenName || account?.username || 'welcome to Archipelago'}`;
};
