import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemFragment = {
  __typename?: 'InboxItem';
  type: Types.InboxItemType;
  itemID: string;
  taskType?: Types.TaskType | null;
  assignee?: string | null;
  subject: string;
  descriptionLine1?: string | null;
  descriptionLine2?: string | null;
  creationDate: string;
  priority?: string | null;
  assigneeProfile?: {
    __typename?: 'UserProfile';
    email: string;
    givenName?: string | null;
    familyName?: string | null;
  } | null;
  chatRoomInfo?: {
    __typename?: 'ChatRoomInfo';
    id: string;
    name: string;
    joined: boolean;
    unread: boolean;
    userMention: boolean;
  } | null;
};

export type GetInboxQueryVariables = Types.Exact<{
  input: Types.InboxInput;
}>;

export type GetInboxQuery = {
  __typename?: 'Query';
  inbox: {
    __typename?: 'InboxResponse';
    all?: Array<{
      archId?: string;
      __typename?: 'InboxItem';
      type: Types.InboxItemType;
      itemID: string;
      taskType?: Types.TaskType | null;
      assignee?: string | null;
      subject: string;
      descriptionLine1?: string | null;
      descriptionLine2?: string | null;
      creationDate: string;
      priority?: string | null;
      assigneeProfile?: {
        __typename?: 'UserProfile';
        email: string;
        givenName?: string | null;
        familyName?: string | null;
      } | null;
      chatRoomInfo?: {
        __typename?: 'ChatRoomInfo';
        id: string;
        name: string;
        joined: boolean;
        unread: boolean;
        userMention: boolean;
      } | null;
    }> | null;
    assigned?: Array<{
      __typename?: 'InboxItem';
      type: Types.InboxItemType;
      itemID: string;
      taskType?: Types.TaskType | null;
      assignee?: string | null;
      subject: string;
      descriptionLine1?: string | null;
      descriptionLine2?: string | null;
      creationDate: string;
      priority?: string | null;
      assigneeProfile?: {
        __typename?: 'UserProfile';
        email: string;
        givenName?: string | null;
        familyName?: string | null;
      } | null;
      chatRoomInfo?: {
        __typename?: 'ChatRoomInfo';
        id: string;
        name: string;
        joined: boolean;
        unread: boolean;
        userMention: boolean;
      } | null;
    }> | null;
    unassigned?: Array<{
      __typename?: 'InboxItem';
      type: Types.InboxItemType;
      itemID: string;
      taskType?: Types.TaskType | null;
      assignee?: string | null;
      subject: string;
      descriptionLine1?: string | null;
      descriptionLine2?: string | null;
      creationDate: string;
      priority?: string | null;
      assigneeProfile?: {
        __typename?: 'UserProfile';
        email: string;
        givenName?: string | null;
        familyName?: string | null;
      } | null;
      chatRoomInfo?: {
        __typename?: 'ChatRoomInfo';
        id: string;
        name: string;
        joined: boolean;
        unread: boolean;
        userMention: boolean;
      } | null;
    }> | null;
    mentions?: Array<{
      __typename?: 'InboxItem';
      type: Types.InboxItemType;
      itemID: string;
      taskType?: Types.TaskType | null;
      assignee?: string | null;
      subject: string;
      descriptionLine1?: string | null;
      descriptionLine2?: string | null;
      creationDate: string;
      priority?: string | null;
      assigneeProfile?: {
        __typename?: 'UserProfile';
        email: string;
        givenName?: string | null;
        familyName?: string | null;
      } | null;
      chatRoomInfo?: {
        __typename?: 'ChatRoomInfo';
        id: string;
        name: string;
        joined: boolean;
        unread: boolean;
        userMention: boolean;
      } | null;
    }> | null;
  };
};

export const ItemFragmentDoc = gql`
  fragment item on InboxItem {
    type
    itemID
    taskType
    assignee
    assigneeProfile {
      email
      givenName
      familyName
    }
    subject
    descriptionLine1
    descriptionLine2
    creationDate
    priority
    chatRoomInfo {
      id
      name
      joined
      unread
      userMention
    }
  }
`;
export const GetInboxDocument = gql`
  query GetInbox($input: InboxInput!) {
    inbox(input: $input) {
      all {
        ...item
      }
      assigned {
        ...item
      }
      unassigned {
        ...item
      }
      mentions {
        ...item
      }
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useGetInboxQuery__
 *
 * To run a query within a React component, call `useGetInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInboxQuery(
  baseOptions: Apollo.QueryHookOptions<GetInboxQuery, GetInboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, options);
}
export function useGetInboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInboxQuery, GetInboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, options);
}
export type GetInboxQueryHookResult = ReturnType<typeof useGetInboxQuery>;
export type GetInboxLazyQueryHookResult = ReturnType<typeof useGetInboxLazyQuery>;
export type GetInboxQueryResult = Apollo.QueryResult<GetInboxQuery, GetInboxQueryVariables>;
