import React, { createContext, FC, useContext, useState } from 'react';
import moment from 'moment';
import { Casualty } from '@app/graphql/types';

interface ICasualtyDataContextProps {
  casualty?: Casualty;
  setCasualty: React.Dispatch<React.SetStateAction<Casualty>>;
  viewOnly: boolean;
}

export const CasualtyDataContext = createContext<ICasualtyDataContextProps>({
  setCasualty: () => {},
  viewOnly: false,
});

export const CasualtyDataProvider: FC<{
  viewCasualty?: Casualty;
}> = ({ viewCasualty, children }) => {
  const [renewalDate, setRenewalDate] = useState<Date | null>(
    viewCasualty !== undefined
      ? viewCasualty?.policyEffectiveDates[0].al ||
          viewCasualty?.policyEffectiveDates[0].gl ||
          viewCasualty?.policyEffectiveDates[0].wc
      : undefined,
  );
  const [casualty, setCasualty] = useState<Casualty>(
    viewCasualty !== undefined
      ? {
          ...viewCasualty,
          analysisDate: viewCasualty.analysisDate
            ? moment.utc(viewCasualty.analysisDate)
            : undefined,
        }
      : {
          accountOrgId: '',
          accountOrgName: '',
          exposureBase: {
            al: 'Power Unit',
            wc: 'Payroll',
          },
          id: '',
          orgName: '',
          organizationId: '',
        },
  );

  return (
    <CasualtyDataContext.Provider
      value={{
        casualty,
        setCasualty,
        viewOnly: !!viewCasualty,
      }}
    >
      {children}
    </CasualtyDataContext.Provider>
  );
};

export const useCasualtyData = () => useContext(CasualtyDataContext);
