import React, { FC, useContext } from 'react';
import HorizontalBarChart from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/HorizontalBarChart';
import { EuiText, EuiTextColor, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../components/ErrorState';
import { scores } from './const';
import { ReadinessReportContext } from './ReadinessReportContext';
import { qualityScoresForAddress } from './utils';

const CoreAttributes: FC = () => {
  const {
    attributeScoresData: data,
    attributeScoresError: error,
    attributeScoresLoading: loading,
    displayCurrency,
  } = useContext(ReadinessReportContext);

  const orderedFields = [
    'specificOccupancy',
    'constructionType',
    'yearBuilt',
    'floorArea',
    'numberOfStoriesAboveGround',
    'numberOfBuildings',
  ];

  const labels = {
    constructionType: 'Specific Construction Class',
    floorArea: 'Floor Area',
    numberOfBuildings: 'Number of Buildings',
    numberOfStoriesAboveGround: 'Stories Above Ground',
    specificOccupancy: 'Specific Occupancy',
    yearBuilt: 'Year Built',
  };

  const addressBar = (data || [])
    .filter((item: any) => item.attributeName === 'geoCodeType')
    .map((item: any) => ({
      ...item,
      label: 'Address',
      scores: item.scores.map((score: any) =>
        score.scoreQuality === 'Precise' ? { ...score, scoreQuality: 'Evidenced' } : score,
      ),
    }));

  const sortBars = (a: any, b: any) =>
    orderedFields.indexOf(a.attributeName) - orderedFields.indexOf(b.attributeName);

  const filterBars = (item: any) => Object.keys(labels).includes(item.attributeName);

  const attributeBars = (data || [])
    .filter(filterBars)
    .sort(sortBars)
    .map((item: any) => ({ ...item, label: labels[item.attributeName] }));

  const hasAttributeBars = attributeBars.length > 0;
  const hasAddressBar = addressBar.length > 0;

  return (
    <>
      {loading && !error && <LoadingSpinnerV2 />}
      {error && !loading && <ErrorState />}
      {data && !hasAttributeBars && !loading && !error && <div />}
      {data && hasAttributeBars && !loading && !error && (
        <>
          <EuiText>
            <h3>Core attribute data quality</h3>
          </EuiText>
          <EuiText size="s">
            <p>
              <EuiTextColor color="subdued">
                Archipelago uses core attributes to classify the risk for a building, determining
                the level of damage, on average, the building is likely to receive given a certain
                level of hazard.
              </EuiTextColor>
            </p>
          </EuiText>
          <Spacer size="l" />
          <EuiText>
            <h4>Address data quality</h4>
          </EuiText>
          <EuiText size="s">
            <p>
              <EuiTextColor color="subdued">
                To determine address data quality, Archipelago assesses the precision of your
                location data. If we can't geocode a property to Roof Centroid resolution , we label
                the property as Unconfirmed.
              </EuiTextColor>
            </p>
          </EuiText>
          <Spacer size="s" />
          <HorizontalBarChart
            bars={addressBar}
            keyItems={qualityScoresForAddress}
            keyGutterSize="m"
            currency={displayCurrency}
            closeBarLabels
          />
          <Spacer size="m" />
          <EuiText>
            <h4>Primary attribute data quality</h4>
          </EuiText>
          <Spacer size="s" />
          <HorizontalBarChart
            bars={attributeBars}
            keyItems={scores}
            keyGutterSize="m"
            currency={displayCurrency}
            closeBarLabels
          />
        </>
      )}
    </>
  );
};

export default CoreAttributes;
