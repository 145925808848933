import React, { FC, useEffect } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from 'ui';
import EmptyMessage from '@app/components/EmptyMessage/EmptyMessage';
import { ReportProtectedModalRoutes } from '@app/containers/App/Routes/ProtectedModalRoutes';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { ExportCode } from '@app/cx/Stream/types';
import { useTracker } from '@app/hooks/useTracker';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import { PropertyDataQualityImprovementsTable } from './PropertyDataQualityImprovementsTable/PropertyDataQualityImprovementsTable';
import CoreAttributes from './CoreAttributes';
import DataQualityExportButton from './DataQualityExportButton';
import ExposureSummary from './ExposureSummary';
import Filters from './Filters';
import Line from './Line';
// FIX ME
// @ts-ignore
import QualityDistribution from './QualityDistribution';
import ReadinessGradeSummary from './ReadinessGradeSummary';
import { Column, ContentContainer, Divider, ReportsContainer } from './ReadinessReport.emotion';
import { ReadinessReportProvider } from './ReadinessReportContext';
import SecondaryAttributes from './SecondaryAttributes';
import SnapshotBenchmark from './SnapshotBenchmark';

const ReadinessReport: FC = () => {
  const mixpanel = useTracker('mixpanel');
  const { snapshots, stream } = useReportsContext();
  const { snapshotOptions, currentSnapshot } = snapshots;
  const { streamId, streamName, streamSlug, ...streamRest } = stream;
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  useEffect(() => {
    mixpanel.track('Open Report', {
      report_name: 'Readiness Report',
      snapshot: currentSnapshot?.snapshotName,
      stream_id: streamId,
      stream_name: streamName,
      stream_slug: streamSlug,
      ...streamRest,
      ...snapshots,
    });
  }, []);

  const DetailedQualityExportButton = isAdmin && (
    <EuiFlexItem grow={false}>
      <DataQualityExportButton
        exportCode={ExportCode.DQD_INTERNAL}
        label="Detailed Export"
        // FIX ME
        // @ts-ignore
        buttonProps={{
          style: {
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
            color: '#0F766E',
          },
        }}
      />
    </EuiFlexItem>
  );

  if (snapshotOptions.length < 1) {
    return (
      <ReportsContainer direction="column" responsive={false} data-testid="report-data-quality">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <ReportsHeader header="Archipelago Readiness Report" />
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="s" direction="row">
                {DetailedQualityExportButton}
                <EuiFlexItem grow={false}>
                  <DataQualityExportButton />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <Filters />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EmptyMessage
            header="There are no snapshots scored yet."
            subText="Score at least one to see this report."
          />
        </EuiFlexItem>
      </ReportsContainer>
    );
  }

  return (
    <ReportsContainer direction="column" responsive={false} data-testid="report-data-quality">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" justifyContent="spaceBetween">
          <ReportsHeader header="Archipelago Readiness Report" />
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" direction="row">
              {DetailedQualityExportButton}
              <EuiFlexItem grow={false}>
                <DataQualityExportButton />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <Filters />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <ContentContainer direction="column">
        <EuiFlexItem style={{ marginTop: '0' }} grow={false}>
          <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
            <EuiFlexGroup direction="row">
              <EuiFlexItem grow={2} style={{ minHeight: 332 }}>
                <ReadinessGradeSummary />
              </EuiFlexItem>
              <Line />
              <EuiFlexItem grow={2}>
                <SnapshotBenchmark />
              </EuiFlexItem>
              <Line />
              <EuiFlexItem grow={2}>
                <ExposureSummary />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <EuiFlexGroup direction="column">
                <EuiFlexItem style={{ flex: '1 1 auto' }}>
                  <EuiPanel paddingSize="l" hasShadow={false}>
                    <Column>
                      <CoreAttributes />
                    </Column>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem style={{ flex: '1 1 auto' }}>
                  <EuiPanel paddingSize="l" hasShadow={false}>
                    <QualityDistribution />
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiPanel paddingSize="l" hasShadow={false}>
                <Column>
                  <SecondaryAttributes />
                </Column>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
          <PropertyDataQualityImprovementsTable />
        </EuiFlexItem>
      </ContentContainer>
    </ReportsContainer>
  );
};

interface Props {
  visible: boolean;
}
const ReadinessReportWithContext: FC<Props> = ({ visible }) => (
  <ReadinessReportProvider>
    {visible && (
      <StreamProvider>
        <>
          <ReportProtectedModalRoutes />
          <ReadinessReport />
        </>
      </StreamProvider>
    )}
  </ReadinessReportProvider>
);

export default ReadinessReportWithContext;
