import gql from 'graphql-tag';
import { AccessRequest } from '../types';

// ----- MUTATION ----- //

export const GET_ACCESS_REQUESTS = gql`
  query accessRequests($input: AccessRequestsInput!) {
    accessRequests(input: $input) {
      createdAt
      streamSlug
      userEmail
    }
  }
`;

// ----- TYPES ----- //

export interface GetAccessRequestsData {
  accessRequests: AccessRequest[];
}

export interface GetAccessRequestsVariables {
  input: {
    streamSlug: string;
    userEmail: string;
  };
}
