import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Comparators,
  EuiBadge,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from 'ui';

import moment from 'moment';
import Currency from '@app/components/Currency/Currency';
import { SOV_LABEL, STREAMS_LABEL } from '@app/components/Layout/SideNav/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import AddStreamModal from '@app/cx/Stream/AddModal/AddStreamModal';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { formatDate } from '@app/utils/format';
import { Helmet } from '../Helmet';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { StyledBadge, StyledBoundTable, StyledTablesDiv } from './Portfolios.emotion';

export const Portfolios = () => {
  const { account } = useAuth();
  const { stream } = useStreamContext();
  const isAdmin = account?.permissions?.admin;
  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProjects || o.editProperties || o.editSubmissions,
  );
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name || null;
  const useOrgInQuery = !!(isAdmin || hasNonViewManagedOrgs);

  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const history = useHistory();
  const { loading, error, data } = useGetStreamsQuery({
    skip: useOrgInQuery && !organizationName,
    variables: {
      orgName: useOrgInQuery ? organizationName : null,
    },
  });

  const [isModalShown, setIsModalShown] = useState(false);

  if (loading) {
    return <LoadingSpinnerV2 variant="xxl" label="Loading" dataTestId="loading-spinner" />;
  }

  if (error || !data?.streamsV2?.streams) {
    return (
      <EuiPanel paddingSize="l" hasBorder={false} hasShadow={false}>
        <EuiPageHeader pageTitle={`You have no ${STREAMS_LABEL}`} />
      </EuiPanel>
    );
  }

  const columns = [
    {
      field: 'name',
      name: 'Name',
      width: '40%',
      sortable: true,
      render: (name, stream) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
          {stream.expiryDate && moment.unix(stream.expiryDate || 0).isBefore(moment()) ? (
            <EuiFlexItem grow={false}>
              <StyledBadge>Expired</StyledBadge>
            </EuiFlexItem>
          ) : null}
        </EuiFlexGroup>
      ),
    },
    {
      field: 'totalInsuredValue',
      name: `TIV (${stream?.displayCurrency})`,
      render: (tiv, stream) => (
        <EuiText>
          <Currency value={tiv} currency={stream.displayCurrency} />
        </EuiText>
      ),
      width: '15%',
      sortable: true,
    },
    {
      field: 'propertiesCount',
      name: '# properties',
      width: '15%',
      sortable: true,
    },
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      render: (effectiveDate) => <EuiText>{formatDate(effectiveDate)}</EuiText>,
      width: '15%',
      sortable: true,
    },
    {
      field: 'expiryDate',
      name: 'Expiry Date',
      render: (expiryDate) => <EuiText>{formatDate(expiryDate)}</EuiText>,
      width: '15%',
      sortable: true,
    },
  ];

  const getRowProps = (row) => ({
    className: `customRowClass ${
      row.expiryDate && moment.unix(row.expiryDate || 0).isBefore(moment()) ? 'expired' : ''
    }`,
    style: { color: 'red' },
    onClick: () => {
      history.push(`/streams/${row.slug}`);
    },
  });

  const defaultGroupedStreams = { bound: [], 'In Progress': [], 'My Properties': [] };

  const groupedStreams = (data?.streamsV2?.streams || []).reduce((acc, item) => {
    let result = acc;
    let section = 'bound';

    if (item.isMyProperties) {
      section = 'My Properties';
    } else if (moment.unix(item?.effectiveDate || 0).isAfter(moment())) {
      section = 'In Progress';
    }

    result = { ...acc, ...{ [section]: [...acc?.[section], item] } };
    return result;
  }, defaultGroupedStreams);

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const onTableChange = ({ sort }) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  return (
    <EuiPanel paddingSize="xl" hasBorder={false} hasShadow={false}>
      <Helmet title={STREAMS_LABEL} />
      <EuiPageHeader
        pageTitle={STREAMS_LABEL}
        rightSideItems={[
          isAdmin && (
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem data-testid="add-stream-btn" grow={false}>
                <Button
                  iconName="plusCircle"
                  onClick={() => setIsModalShown(true)}
                  size="s"
                  label={`Add ${SOV_LABEL}`}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ),
        ]}
      />

      <EuiSpacer size="s" />

      <EuiPanel paddingSize="l" hasShadow={false}>
        <EuiBasicTable
          data-testid="portfolio-my-properties"
          columns={[
            { ...columns[0], width: '41%' },
            { ...columns[1], width: '14%' },
            { ...columns[2], width: '45%' },
          ]}
          items={groupedStreams['My Properties']}
          rowProps={getRowProps}
        />

        <StyledTablesDiv>
          <EuiTitle size="s">
            <h3>In Progress</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiPanel paddingSize="l" hasShadow={false}>
            <EuiBasicTable
              data-testid="portfolio-in-progress"
              columns={columns}
              items={groupedStreams['In Progress']}
              rowProps={getRowProps}
              sorting={sorting}
              onChange={onTableChange}
            />
          </EuiPanel>
          <EuiSpacer size="l" />
          <EuiTitle size="s">
            <h3>Prior</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiPanel paddingSize="l" hasShadow={false}>
            <StyledBoundTable
              data-testid="portfolio-prior"
              columns={columns}
              items={groupedStreams.bound.sort(
                Comparators.property(sortField, Comparators.default(sortDirection)),
              )}
              sorting={sorting}
              onChange={onTableChange}
              rowProps={getRowProps}
            />
          </EuiPanel>
          {isModalShown && (
            <AddStreamModal
              hasMyPropertiesStream={false}
              onCloseModal={() => setIsModalShown(false)}
            />
          )}
        </StyledTablesDiv>
      </EuiPanel>
    </EuiPanel>
  );
};
