import React from 'react';
import { useQuery } from '@apollo/client';
import {
  AttributeScoresData,
  AttributeScoresVariables,
  GET_ATTRIBUTE_SCORES,
} from '@onarchipelago/cx/Reports/queries/getAttributeScores';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_PRECHECK_WORKFLOWS_V2 } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DataJourney } from '../../components/DataJourney/DataJourney';
import { AttributeInsightContainer } from '../Components/AttributeInsightContainer';
import { usePrecheckContext } from '../context/PrecheckContext';

export enum Group {
  Earthquake = 'EARTHQUAKE',
  Cope = 'COPE',
  Flood = 'FLOOD',
  Wind = 'WIND',
  Fire = 'FIRE',
  Attritional = 'ATTRIIONAL',
  Convective = 'SEVERE_CONVECTIVE_STORM',
  Geocode = 'GEOCODE',
}

export const useDataInsights = () => {
  const { params, snapshot } = usePrecheckContext();
  const { selectedOrganization } = useUserSession();

  const { data: primaryData, loading: primaryLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'All',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const { data: eqData, loading: eqLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'Earthquake',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const { data: floodData, loading: floodLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'Flood',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const { data: windData, loading: windLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'Wind',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const { data: fireData, loading: fireLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'Fire',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const { data: stormData, loading: stormLoading } = useQuery<
    AttributeScoresData,
    AttributeScoresVariables
  >(GET_ATTRIBUTE_SCORES, {
    skip: !params.streamId || !snapshot?.name,
    variables: {
      input: {
        hazard: 'ConvectiveStorm',
        snapshotName: snapshot.name,
        streamSlug: params.streamId,
      },
    },
  });

  const isPrecheckWorkflowsV2Enabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_PRECHECK_WORKFLOWS_V2,
  );

  const tabs = [];

  tabs.push({
    content: (
      <AttributeInsightContainer
        key="primary"
        title="Primary"
        group={Group.Cope}
        data={primaryData}
      />
    ),
    label: 'Primary',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer
        key={Group.Attritional}
        title="Attritional"
        group={Group.Attritional}
        data={primaryData}
      />
    ),
    label: 'Attritional',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer key={Group.Fire} title="Fire" group={Group.Fire} data={fireData} />
    ),
    label: 'Fire',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer
        key="eq"
        title="Earthquake"
        group={Group.Earthquake}
        data={eqData}
      />
    ),
    label: 'Earthquake',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer key="wind" title="Wind" group={Group.Wind} data={windData} />
    ),
    label: 'Wind',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer key="flood" title="Flood" group={Group.Flood} data={floodData} />
    ),
    label: 'Flood',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer
        key={Group.Convective}
        title="Severe Convective Storm"
        group={Group.Convective}
        data={stormData}
      />
    ),
    label: 'Severe Convective Storm',
  });

  tabs.push({
    content: (
      <AttributeInsightContainer
        key="geocode"
        title="geocode"
        group={Group.Geocode}
        data={primaryData}
      />
    ),
    label: 'Geocoding',
  });

  if (isPrecheckWorkflowsV2Enabled) {
    tabs.push({
      content: <DataJourney />,
      label: 'Data Changes',
    });
  }
  return tabs;
};
