import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import Bar from './Bar';
import Title from './Title';

interface Props {
  title: string;
  TIV: number;
  locations: number;
  percent: number;
  active?: boolean;
  currency?: string;
}

const HazardExposure: FC<Props> = ({ title, TIV, locations, percent, active = true, currency }) => (
  <EuiFlexGroup direction="column" gutterSize="xs">
    <EuiFlexItem grow={false}>
      <Title title={title} TIV={TIV} currency={currency} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <Bar locations={locations} percent={percent} active={active} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default HazardExposure;
