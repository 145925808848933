import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import MyProperties from '@onarchipelago/cx/Stream/MyProperties';
import Stream from '@onarchipelago/cx/Stream/Stream';
import { AccountSettings } from '@app/components/AccountSettings/AccountSettings';
import { CasualtyPage } from '@app/components/CasualtyPage/CasualtyPage';
import { Copilot } from '@app/components/Copilot/Copilot';
import { HomePage } from '@app/components/HomePage/HomePage';
import { STREAMS_PATH } from '@app/components/Layout/constants';
import { Portfolios } from '@app/components/Portfolios/Portfolios';
import { SupportPage } from '@app/components/SupportPage/SupportPage';
import { SupportTicket } from '@app/components/SupportPage/SupportTicket/SupportTicket';
import { useRedirectURL } from '@app/containers/Auth/RedirectURL';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { userHasSubmissionAccess } from '@app/cx/Dashboard/helpers';
import { PropertiesPage } from '@app/cx/Properties/PropertiesPage';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { FEATURE_TYPE_SIMPLIFIED_SOV } from '@app/platform/SystemSettings/Flags/types';
import { Page } from '@app/precheck/Pages/CMS/Page';
import { PrecheckRank } from '@app/precheck/Pages/PrecheckRank';
import { PrecheckReport } from '@app/precheck/Pages/PrecheckReport';
import { PrecheckRoot } from '@app/precheck/Pages/PrecheckRoot';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import {
  DOMAINS_PATH,
  FLAGS_PATH,
  INTEGRATIONS_PATH,
  MP_ADD_PROPERTIES_PATH,
  MP_EDIT_PROPERTIES_PATH,
  MP_STREAM_PATH,
  ORG_SUBMISSIONS_PATH,
  ORG_SYSTEM_OVERVIEW,
  PROJECTS_DETAILS_PATH,
  PROJECTS_PATH,
  REPORT_PATH,
  STREAM_ACTION_PATH,
  STREAM_ADD_PROPERTIES_PATH,
  STREAM_EDIT_PROPERTIES_PATH,
  STREAM_PATH,
  STREAM_SYSTEM_OVERVIEW,
  SUBMISSIONS_PATH_BASE,
  SYSTEM_ALERTS_CONFIG_PATH,
  VIEWERS_PATH,
} from './constants';
import { getRedirectPathName, shouldRedirectToUpload } from './utils';

const JobsController = loadable(() => import('@app/dice/ProvenanceEnrichment/JobsController'));

const StreamsDashboard = loadable(
  () => import('@onarchipelago/cx/Dashboard/Streams/StreamsDashboard'),
);
const AccountsPage = loadable(() => import('@app/cx/Dashboard/Submissions/AccountsPage'));
const Logout = loadable(() => import('@app/containers/Auth/Logout'));
const Callback = loadable(() => import('@app/components/Callback/Callback'));
const SmartFolder = loadable(() => import('@onarchipelago/cx/SmartFolder/SmartFolder'));
const SmartFolderDetails = loadable(
  () => import('@onarchipelago/cx/SmartFolder/SmartFolderDetails/SmartFolderDetails'),
);
const Share = loadable(() => import('@app/containers/Share/Share'));

const Organizations = loadable(() => import('@onarchipelago/cx/Organizations/Organizations'));
const File = loadable(() => import('@app/containers/File/File'));
const MyJobs = loadable(() => import('@onarchipelago/dice/MyJobs/MyJobs'));

const EnrichmentProjects = loadable(
  () => import('@onarchipelago/dice/EnrichmentProjects/EnrichmentProjects'),
);
const Personnel = loadable(() => import('@onarchipelago/cx/Personnel/Personnel'));
const ProjectDetails = loadable(
  () => import('@onarchipelago/dice/EnrichmentProjects/ProjectDetails'),
);
const DocumentsOnboarding = loadable(
  () => import('@onarchipelago/cx/DocumentsOnboarding/DocumentsOnboarding'),
);
const UploadPortal = loadable(() => import('@onarchipelago/cx/UploadPortal/UploadPortal'));

const Reports = loadable(() => import('@onarchipelago/cx/Reports/Reports'));

const Flags = loadable(() => import('@onarchipelago/platform/SystemSettings/Flags/Flags'));

const Library = loadable(() => import('@onarchipelago/platform/Library/Library'));

const Tasks = loadable(() => import('@onarchipelago/platform/Workflow/Tasks'));
const Inbox = loadable(() => import('@onarchipelago/platform/Inbox/Inbox'));
const InboxDetails = loadable(() => import('@onarchipelago/platform/Inbox/InboxDetails'));

const System = loadable(() => import('@onarchipelago/platform/SystemSettings/SystemSettings'));
const StreamUsers = loadable(() => import('@onarchipelago/platform/StreamUsers/StreamUsers'));
const StreamSystemOverview = loadable(
  () => import('@app/platform/SystemOverview/StreamSystemOverview'),
);
const AddPropertiesPage = loadable(
  () => import('@onarchipelago/cx/Stream/AddPropertiesPage/AddPropertiesPage'),
);

const PropertyPage = loadable(() => import('@onarchipelago/cx/PropertyPage/PropertyPage'));
const Integrations = loadable(() => import('@app/platform/Integrations/Integrations'));

const SmartDocsController = loadable(() => import('@app/dice/Pages/SmartDocs/SmartDocsController'));

const AccountUsers = loadable(() => import('@onarchipelago/platform/AccountUsers/AccountUsers'));

const Routes: FC = () => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  // If there's a redirect URL stored in localStorage, then guide the user to that URL.
  if (useRedirectURL.exists()) {
    const uri = useRedirectURL.get() || '';
    useRedirectURL.clear();
    window.location.href = uri;
  }

  const { selectedOrganization } = useUserSession();

  let hasSubmissionAccess = userHasSubmissionAccess(
    account?.submissionCreateOrgs || [],
    selectedOrganization,
  );
  if (!hasSubmissionAccess) {
    const permissions = getOrgPermissions(account, selectedOrganization?.id);
    const canViewAccounts = permissions?.includes('canViewAccounts');
    hasSubmissionAccess = canViewAccounts;
  }

  const willRedirectToSubmissions =
    (isAdmin || hasSubmissionAccess) && selectedOrganization
      ? selectedOrganization?.enableAccountDashboard || hasSubmissionAccess
      : false;

  const willRedirectToUpload = shouldRedirectToUpload(account?.managedOrgs || [], '/', isAdmin);

  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );

  const redirectPathname = getRedirectPathName(
    willRedirectToUpload,
    willRedirectToSubmissions,
    isSimplifiedSOVEnabled
      ? `/organizations/${encodeURIComponent(selectedOrganization?.name)}/home`
      : '',
  );

  return (
    <Switch>
      <Route path="/logout" render={() => <Logout />} />
      <Route path="/callback" render={() => <Callback />} />
      <ProtectedRoute path="/cms" render={() => <Page />} />
      <ProtectedRoute path="/organizations/:organizationName/cms/:slug" render={() => <Page />} />
      <ProtectedRoute
        path="/organizations/:organizationName/precheck/:streamId/rank"
        render={() => <PrecheckRank />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/precheck/:streamId"
        render={() => <PrecheckReport />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/precheck/"
        render={() => <PrecheckRoot />}
      />
      {isSimplifiedSOVEnabled && (
        <ProtectedRoute
          path="/organizations/:organizationName/home"
          render={() => (
            <StreamProvider>
              <HomePage />
            </StreamProvider>
          )}
        />
      )}
      <ProtectedRoute
        path="/organizations/:organizationName/casualty"
        render={() => <CasualtyPage />}
      />
      <ProtectedRoute exact path="/support" render={() => <SupportPage />} />
      <ProtectedRoute path="/support/:ticketId" render={() => <SupportTicket />} />
      <ProtectedRoute path="/organizations/:organizationName/copilot" render={() => <Copilot />} />
      <ProtectedRoute
        path="/organizations/:organizationName/alerts-config"
        render={() => <AccountSettings />}
      />
      <ProtectedRoute
        path={SYSTEM_ALERTS_CONFIG_PATH}
        render={() => <System />}
        requiredPermissions={isAdmin}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType/:status"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType/properties"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID/:jobType"
        render={() => <JobsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/jobs/:jobID"
        render={() => <JobsController />}
      />
      <ProtectedRoute path="/jobs/:jobID/:jobType/:status" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs/:jobID/:jobType" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs/:jobID" render={() => <JobsController />} />
      <ProtectedRoute path="/jobs" render={() => <MyJobs />} />
      <ProtectedRoute
        path="/organizations/:organizationName/smartfolders/:smartfolderName"
        component={SmartFolderDetails}
        requiredPermissions={isAdmin}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/smartfolders"
        render={() => <SmartFolder />}
        requiredPermissions={isAdmin}
      />
      <ProtectedRoute path={PROJECTS_DETAILS_PATH} render={() => <ProjectDetails />} />
      <ProtectedRoute path={PROJECTS_PATH} render={() => <EnrichmentProjects />} />
      <ProtectedRoute
        path="/organizations/:organizationName/users"
        render={() => <AccountUsers />}
      />
      <ProtectedRoute path={ORG_SYSTEM_OVERVIEW} render={() => <StreamSystemOverview />} />
      {isSimplifiedSOVEnabled ? (
        <ProtectedRoute
          path="/organizations/:organizationName/streams"
          render={() => (
            <StreamProvider>
              <Portfolios />
            </StreamProvider>
          )}
        />
      ) : (
        <ProtectedRoute
          path="/organizations/:organizationName/streams"
          render={() => <StreamsDashboard />}
        />
      )}
      {isSimplifiedSOVEnabled && (
        <ProtectedRoute
          path="/streams/:streamSlug/property/:propertyId"
          render={() => (
            <StreamProvider>
              <PropertyPage />
            </StreamProvider>
          )}
        />
      )}
      <ProtectedRoute path={ORG_SUBMISSIONS_PATH} render={() => <AccountsPage />} />
      <ProtectedRoute path={SUBMISSIONS_PATH_BASE} render={() => <AccountsPage />} />
      {/* Need to define redirects here above the /organizations route definition */}
      <Redirect path="/organizations/:organizationName/submissions" to={ORG_SUBMISSIONS_PATH} />
      <Redirect path="/submissions" to={SUBMISSIONS_PATH_BASE} />
      <ProtectedRoute
        path="/organizations/:organizationName/personnel"
        render={() => <Personnel />}
      />
      <ProtectedRoute
        path={[MP_ADD_PROPERTIES_PATH, STREAM_ADD_PROPERTIES_PATH]}
        render={() => <AddPropertiesPage />}
      />
      <ProtectedRoute
        path={[MP_EDIT_PROPERTIES_PATH, STREAM_EDIT_PROPERTIES_PATH]}
        render={() => <AddPropertiesPage edit />}
      />
      <ProtectedRoute path={MP_STREAM_PATH} render={() => <MyProperties />} />
      <ProtectedRoute path="/organizations/:organizationName/library" render={() => <Library />} />
      <ProtectedRoute
        path="/organizations/:organizationName/documents-admin"
        render={() => <DocumentsOnboarding />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/documents/:status"
        requiredPermissions={isAdmin}
        render={() => <SmartDocsController />}
      />
      <ProtectedRoute
        path="/organizations/:organizationName/documents"
        requiredPermissions={isAdmin}
        render={() => <SmartDocsController />}
      />
      <ProtectedRoute exact strict path="/tasks" render={() => <Tasks />} />
      <ProtectedRoute path="/organizations/:organizationName/tasks" render={() => <Tasks />} />
      <ProtectedRoute exact strict path="/inbox/:taskId" render={() => <InboxDetails />} />
      <ProtectedRoute exact strict path="/inbox" render={() => <Inbox />} />
      <ProtectedRoute
        path="/organizations/:organizationName/inbox/:taskId"
        render={() => <InboxDetails />}
      />
      <ProtectedRoute path="/organizations/:organizationName/inbox" render={() => <Inbox />} />
      <ProtectedRoute path="/organizations" render={() => <Organizations />} />
      <ProtectedRoute path="/files/:fileId" render={() => <File />} />
      <ProtectedRoute path="/share" render={() => <Share />} />
      <ProtectedRoute portal path="/upload" render={() => <UploadPortal />} />
      <ProtectedRoute
        exact
        strict
        path="/uploads"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: '/upload',
            }}
          />
        )}
      />
      <ProtectedRoute path={REPORT_PATH} render={() => <Reports />} />
      <ProtectedRoute exact strict path="/library" render={() => <Library />} />
      <ProtectedRoute
        exact
        strict
        path="/streams"
        component={({ location }) =>
          isSimplifiedSOVEnabled ? (
            <Redirect
              to={{
                ...location,
                pathname: `/organizations/${selectedOrganization?.name}/${STREAMS_PATH}`,
              }}
            />
          ) : (
            <StreamsDashboard />
          )
        }
      />
      <ProtectedRoute
        exact
        strict
        path="/streams/"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: '/streams',
            }}
          />
        )}
      />
      <ProtectedRoute path={[DOMAINS_PATH, VIEWERS_PATH]} render={() => <StreamUsers />} />
      <ProtectedRoute path={[STREAM_SYSTEM_OVERVIEW]} render={() => <StreamSystemOverview />} />
      <ProtectedRoute path={[INTEGRATIONS_PATH]} render={() => <Integrations />} />
      {isSimplifiedSOVEnabled ? (
        <ProtectedRoute
          path={[STREAM_PATH, STREAM_ACTION_PATH]}
          render={() => <PropertiesPage />}
        />
      ) : (
        <ProtectedRoute path={[STREAM_PATH, STREAM_ACTION_PATH]} render={() => <Stream />} />
      )}
      <ProtectedRoute path={FLAGS_PATH} render={() => <Flags />} />
      <ProtectedRoute
        path="/"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: redirectPathname,
            }}
          />
        )}
      />
      {/* Code Path never reached because "/" will always trigger */}
      <Route path="*" render={() => <div>Not found</div>} />
    </Switch>
  );
};

export default Routes;
