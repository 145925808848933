import React, { FC } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import gql from 'graphql-tag';
import { ButtonProps } from 'ui';
import PollingExportButton from '@app/components/PollingExportButton/PollingExportButtonContainer';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';

export const GET_STREAM_INFO = gql`
  query GetStream($slug: ID!) {
    streamV2(slug: $slug) {
      stream {
        id
        name
        slug
      }
    }
  }
`;

interface DataQualityExportButtonProps {
  exportCode?: ExportCode;
  label?: string;
  buttonProps?: ButtonProps;
}

const DataQualityExportButton: FC<DataQualityExportButtonProps> = ({
  exportCode = ExportCode.DQD,
  label = 'Export',
  buttonProps,
}) => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const snapshotName = snapshots.currentSnapshot?.snapshotName || '';

  const { account } = useAuth();

  const currentOrg = stream.orgName;
  const isRMOnCurrentOrg = account?.managedOrgs?.some((o) => o?.org?.name === currentOrg);
  const canExport = account?.permissions?.admin || isRMOnCurrentOrg;

  if (!canExport) {
    return null;
  }

  return (
    <>
      <PollingExportButton
        exportCode={exportCode}
        streamSlug={streamSlug}
        snapshotName={snapshotName}
        label={label}
        buttonProps={buttonProps}
      />
    </>
  );
};
export default DataQualityExportButton;
