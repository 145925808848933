import React from 'react';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { EuiFlexItem } from 'ui';
// @ts-ignore
import GET_EXPLORERS from '@app/queries/explorers/getExplorers.gql';
import { getChart, getExplorerTitle } from '@app/cx/Stream/Aside/Explorers/chartCatalog';
import {
  ChartContainer,
  ContentContainer,
  MapContainer,
  OverviewHeader,
  ViewContainer,
} from './Dashboard.emotion';

export const Dashboard = () => {
  const { stream } = useStreamContext();
  const streamSlug = stream?.slug;

  const { error: explorersError, data: explorerData } = useStandardQuery(
    GET_EXPLORERS,
    {
      skip: !streamSlug,
      variables: {
        filter: undefined,
        streamSlug,
        toSnapshot: '',
      },
    },
    {
      errorMessage: (err) => ({
        additionalLoggingInformation: [
          'Error in ExplorersContainer',
          `Query: ${GET_EXPLORERS}`,
          `Error: ${JSON.stringify(err)}`,
        ].join('\n'),
        message: err?.message,
      }),
    },
  );

  if (explorersError || !explorerData) {
    return null;
  }

  const {
    explorers: { groups: explorerGroups },
  } = explorerData;

  const maxHeight = Math.max(
    ...explorerGroups.map((chartGroup) =>
      chartGroup.charts.map((explorer) => {
        const chart = getChart(explorer);

        return chart.height;
      }),
    ),
  );

  return (
    <ContentContainer>
      <OverviewHeader size="m">
        <h1>Overview</h1>
      </OverviewHeader>
      <ViewContainer fullHeightVh={true}>
        <MapContainer full={false}></MapContainer>
        {explorerGroups.map((chart) =>
          chart.charts.map((explorer) => {
            const { name } = explorer;

            if (explorer.name === 'tiv_by_us_state' || explorer.name === 'tiv_by_country') {
              return null;
            }
            const c = getChart(explorer);

            if (!c) {
              return null;
            }

            const title = getExplorerTitle(name);

            const Explorer = c.Explorer;
            const explorerID = title.split(' ').join('-').toLowerCase();
            return (
              <ChartContainer
                height={maxHeight}
                data-tourid={`${explorerID}-explorer`}
                data-testid={`${explorerID}-explorer`}
                key={name}
              >
                <EuiFlexItem>
                  <Explorer explorer={explorer} title={title} />
                </EuiFlexItem>
              </ChartContainer>
            );
          }),
        )}
      </ViewContainer>
    </ContentContainer>
  );
};
