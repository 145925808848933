import { ApolloError } from '@apollo/client';

const hasExtensionDetail = (key: string, value: string, error?: ApolloError): boolean => {
  if (error?.graphQLErrors) {
    const apolloError = error?.graphQLErrors[0];
    const details = apolloError?.extensions?.Details;
    // FIX ME
    // @ts-ignore
    const detail = details?.filter((item) => item.key === key);
    return detail?.length === 1 && detail[0].value === value;
  }
  return false;
};

export const canRequestAccess = (error?: ApolloError): boolean =>
  hasExtensionDetail('canRequestAccess', 'yes', error);

export const passwordAccountRequired = (error?: ApolloError): boolean =>
  hasExtensionDetail('passwordAccountRequired', 'yes', error);
