import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { EuiFlexItem, EuiLink, EuiText, EuiTextColor, Icon } from 'ui';
import Currency from '@app/components/Currency/Currency';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import HazardsIcon from '@app/components/PropertiesGrid/GridCell/HazardsIcon';
import UploadFlyout from '@app/components/Upload/UploadFlyout';
import { useFlyout } from '@app/contexts/FlyoutContext';
// FIX ME
// @ts-ignore
import GET_DQS_PROPERTY_RECOMMENDATIONS from '@app/queries/integrations/dqsPropertyRecommendation.gql';
import {
  IconContainer,
  StyledTable,
  TableContainer,
  TextContainer,
  UploadButton,
  UploadButtonContainer,
} from './PropertyDataQualityImprovementsTable.emotion';

enum RecommendedDocumentClassifications {
  'PROPERTY_CONDITION_ASSESSMENT' = 'Property Condition Assessment',
  'LOSS_ENGINEERING_REPORT' = 'Loss Engineering Report',
  'STRUCTURAL_DRAWING' = 'Structural Drawings',
  'ROOF_INSPECTION_REPORT' = 'Roof Inspection Report',
  'ARCHITECTURAL_DRAWING' = 'Architectural Drawings',
  'FLOOD_ELEVATION_CERTIFICATE' = 'Flood Elevation Certificate',
  'FLOOD_RISK_ASSESSMENT' = 'Flood Risk Assessment',
  'SEISMIC_ASSESSMENT' = 'Seismic Assessment (if not included in PCA)',
}

export const PropertyDataQualityImprovementsTable = () => {
  const { showFlyout } = useFlyout();
  const history = useHistory();
  const { pathname } = useLocation();
  const { stream, snapshots } = useReportsContext();
  const { currentSnapshot } = snapshots;
  const { streamSlug, orgId, orgName } = stream;
  const snapshotName = currentSnapshot?.snapshotName;

  const { data, loading } = useQuery(GET_DQS_PROPERTY_RECOMMENDATIONS, {
    variables: { input: { filter: { hazard: 'All' }, snapshotName, streamSlug } },
  });

  return (
    <TableContainer paddingSize="l" hasShadow={false}>
      <EuiText>
        <h3>Top properties to improve data quality</h3>
      </EuiText>
      <TextContainer size="s">
        <p>
          <EuiTextColor color="subdued">
            These are the top (up to 10) properties that could benefit the most from additional
            information. Upload documents to improve the specificity of the data and increase the
            trust in the information. Click the property to review or update its data directly on
            platform as well.
          </EuiTextColor>
        </p>
      </TextContainer>
      {loading ? (
        <EuiFlexItem>
          <LoadingSpinnerV2 />
        </EuiFlexItem>
      ) : (
        <StyledTable
          columns={[
            { field: 'archipelagoID', name: 'Archipelago ID' },
            { field: 'clientID', name: 'Client ID' },
            {
              name: 'Building Name',
              render: (props) => (
                <EuiLink onClick={() => history.push(`${pathname}/properties/${props.id}`)}>
                  {props.buildingName}
                </EuiLink>
              ),
            },
            { field: 'streetAddress', name: 'Street Address' },
            {
              name: 'Hazard Summary',
              render: (props) =>
                HazardsIcon({
                  // FIX ME
                  // @ts-ignore
                  column: { id: props.id },
                  prop: props,
                }).body,
            },
            {
              field: 'recommendedDocumentClassifications',
              name: 'Recommended documents to upload',
              render: (documents) =>
                documents
                  .map((document) => RecommendedDocumentClassifications[document])
                  .join(', '),
            },
            {
              field: 'totalInsuredValue',
              name: '$TIV',
              render: (tiv) => <Currency value={tiv} currency={stream.displayCurrency} />,
            },
          ]}
          items={data?.dqsPropertyRecommendations?.properties || []}
        />
      )}
      <UploadButtonContainer>
        <EuiText size="s">
          <UploadButton
            onClick={() =>
              showFlyout(<UploadFlyout organizationId={orgId} organizationName={orgName} />)
            }
          >
            Upload more documents to enrich property data
            <IconContainer>
              <Icon name="chevronRight" size="m" />
            </IconContainer>
          </UploadButton>
        </EuiText>
      </UploadButtonContainer>
    </TableContainer>
  );
};
