import React, { FC, useContext, useState } from 'react';
import { EuiFlyoutFooter, EuiStepsHorizontalProps, useToast } from 'ui';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useCreateCasualtyMutation } from '@app/graphql/casualty/mutations/__generated__/createCasualty.generated';
import { Casualty } from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { Buttons } from './components/Buttons';
import { CasualtyDataProvider, useCasualtyData } from './context/context';
import { StepOne } from './steps/StepOne';
import { StepPolicyEffectiveDates } from './steps/StepPolicyEffectiveDates';
import { StepSix } from './steps/StepSix';
import { StepTwo } from './steps/StepTwo';
import { StepX } from './steps/StepX';
import { FlyoutBody, StepBody, StepsHorizontal } from './styled';
import { casualtyInput } from './utils';

export const CasualtyWizard: FC<{
  viewCasualty?: Casualty;
  // HACK: to lookup the enterprise account id later.
  casualtyList: Array<Casualty>;
  onClose: () => void;
}> = ({ viewCasualty, casualtyList, onClose }) => (
  <CasualtyDataProvider viewCasualty={viewCasualty}>
    <Wizard onClose={onClose} casualtyList={casualtyList} />
  </CasualtyDataProvider>
);

const Wizard: FC<{
  // HACK: to lookup the enterprise account id later.
  casualtyList: Array<Casualty>;
  onClose: () => void;
}> = ({ casualtyList, onClose }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const { casualty, viewOnly } = useCasualtyData();
  const toast = useToast();
  const { closeFlyout } = useContext(FlyoutContext);

  const [createCasualty, { loading }] = useCreateCasualtyMutation({
    onCompleted: () => {
      toast({ title: 'Succesfully submitted casualty account' });
      closeFlyout();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const { selectedOrganization } = useContext(UserSessionContext);

  const [documents, setDocuments] = useState<Array<File>>([]);

  // This is a little hack to get the enterprise account name. We lookup the enterprise account name
  // from the underlying account in the casualty list from the "currently opened" account.
  const c = casualtyList.find((c) => c.accountOrgName === selectedOrganization.name);
  const accountName = c?.orgName || selectedOrganization.name;

  const next = () => {
    setPageIndex((prev) => prev + 1);
  };

  const prev = () => {
    setPageIndex((prev) => prev - 1);
  };

  const save = () => {
    createCasualty({
      variables: {
        input: {
          casualty: casualtyInput(casualty),
          documents: documents,
          enterpriseOrgName: accountName,
        },
      },
    });
  };

  const renderStep = () => {
    switch (pageIndex) {
      case 0:
        return <StepOne casualtyList={casualtyList} />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepX sectionKey={'retentions'} />;
      case 3:
        return <StepX sectionKey={'deductibleAggregateLimit'} />;
      case 4:
        return <StepPolicyEffectiveDates />;
      case 5:
        return <StepSix documents={documents} setDocuments={setDocuments} />;
    }
    return null;
  };

  const horizontalStepClick = (stepIndex: number) => {
    if (pageIndex < stepIndex) {
      if (!disableNextBtn()) {
        setPageIndex(stepIndex);
      } else {
        // do nothing
      }
    } else {
      setPageIndex(stepIndex);
    }
  };

  const horizontalSteps: EuiStepsHorizontalProps['steps'] = [
    {
      onClick: () => {
        setPageIndex(0);
      },
      status: pageIndex === 0 ? 'current' : pageIndex > 0 ? 'complete' : 'incomplete',
      title: 'Analysis request details',
    },
    {
      onClick: () => {
        horizontalStepClick(1);
      },
      status: pageIndex === 1 ? 'current' : pageIndex > 1 ? 'complete' : 'incomplete',
      title: 'Exposures',
    },
    {
      onClick: () => {
        horizontalStepClick(2);
      },
      status: pageIndex === 2 ? 'current' : pageIndex > 2 ? 'complete' : 'incomplete',
      title: 'Retentions / Deductibles',
    },
    {
      onClick: () => {
        horizontalStepClick(3);
      },
      status: pageIndex === 3 ? 'current' : pageIndex > 3 ? 'complete' : 'incomplete',
      title: 'Deductible Aggregate Limit',
    },
    {
      onClick: () => {
        horizontalStepClick(4);
      },
      status: pageIndex === 4 ? 'current' : pageIndex > 4 ? 'complete' : 'incomplete',
      title: 'Policy Effective Dates',
    },
  ];
  if (!viewOnly) {
    horizontalSteps.push({
      onClick: () => {
        horizontalStepClick(5);
      },
      status: pageIndex === 5 ? 'current' : pageIndex > 5 ? 'complete' : 'incomplete',
      title: 'Upload your files',
    });
  }

  const disableNextBtn = () => {
    if (pageIndex === 4 && viewOnly) {
      return true;
    }

    if (!casualty?.clientName?.length) {
      return true;
    }
    return false;
  };

  return (
    <>
      <FlyoutBody>
        <StepsHorizontal steps={horizontalSteps} size={'s'} />
        <StepBody>{renderStep()}</StepBody>
      </FlyoutBody>
      <EuiFlyoutFooter>
        <Buttons
          cancel={onClose}
          next={pageIndex < 5 ? next : undefined}
          save={pageIndex === 5 ? save : undefined}
          prev={pageIndex > 0 ? prev : undefined}
          loading={loading}
          options={{ disableNext: disableNextBtn() }}
        />
      </EuiFlyoutFooter>
    </>
  );
};
