import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';

interface Props {
  title: string;
}

const Title: FC<Props> = ({ title }) => (
  <EuiFlexGroup gutterSize="none" justifyContent="spaceBetween">
    <EuiFlexItem grow={false}>
      <EuiText size="s">
        <small>{title}</small>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default Title;
