import { Role } from '@app/graphql/types';

export const displayRoleName = (role: string): string => {
  switch (role) {
    case Role.RiskManager:
      return 'Account Admin';
    case Role.UserManager:
      return 'Stream Manager';
    case Role.EnterpriseAdmin:
      return 'Enterprise Owner';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};

export const internalRoleName = (role: string): string => {
  switch (role) {
    case Role.RiskManager:
      return 'AccountAdmin';
    case Role.UserManager:
      return 'StreamManager';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};
