import React, { createContext, ReactNode } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  AttributeScoresData,
  AttributeScoresVariables,
  GET_ATTRIBUTE_SCORES,
} from '@onarchipelago/cx/Reports/queries/getAttributeScores';
import gql from 'graphql-tag';

export const GET_SNAPSHOT_BENCHMARK = gql`
  query getQualitySnapshotBenchmark($input: QualitySnapshotBenchmarkInput!) {
    qualitySnapshotBenchmark(input: $input) {
      unenriched {
        score
        count
      }
      avgUnenrichedScore
      enriched {
        score
        count
      }
      avgEnrichedScore
      snapshotScore
      snapshotPercentile
      totalStreamCount
    }
  }
`;

export interface ReadinessReportContext {
  earthquakeAttributes?: any;
  windAttributes?: any;
  floodAttributes?: any;
  attributeScoresData: any;
  attributeScoresLoading: boolean;
  attributeScoresError?: ApolloError;
  snapshotBenchmarkData: any;
  snapshotBenchmarkLoading: boolean;
  snapshotBenchmarkError?: ApolloError;
  displayCurrency?: string;
}

export const ReadinessReportContext = createContext({} as ReadinessReportContext);

export const ReadinessReportProvider = ({ children }: { children: ReactNode }) => {
  const { snapshots, stream } = useReportsContext();
  const { currentHazard, currentSnapshot } = snapshots;
  const { streamSlug, displayCurrency } = stream;
  const snapshotName = currentSnapshot?.snapshotName || '';

  const useAttributeScoresByHazard = (hazard) =>
    useQuery<AttributeScoresData, AttributeScoresVariables>(GET_ATTRIBUTE_SCORES, {
      skip: !streamSlug || !snapshotName || !currentHazard,
      variables: {
        input: {
          hazard,
          snapshotName,
          streamSlug,
        },
      },
    });

  const {
    data: attributeScoresData,
    loading: attributeScoresLoading,
    error: attributeScoresError,
  } = useAttributeScoresByHazard(currentHazard);

  const earthquakeAttributes = useAttributeScoresByHazard('Earthquake');
  const windAttributes = useAttributeScoresByHazard('Wind');
  const floodAttributes = useAttributeScoresByHazard('Flood');

  const {
    data: snapshotBenchmarkData,
    loading: snapshotBenchmarkLoading,
    error: snapshotBenchmarkError,
  } = useQuery(GET_SNAPSHOT_BENCHMARK, {
    skip: !streamSlug || !snapshotName || !currentHazard,
    variables: {
      input: {
        hazard: currentHazard,
        snapshotName,
        streamSlug,
      },
    },
  });

  return (
    <ReadinessReportContext.Provider
      value={{
        attributeScoresData: attributeScoresData?.qualityAttribute,
        attributeScoresError,
        attributeScoresLoading,
        displayCurrency,
        earthquakeAttributes,
        floodAttributes,
        snapshotBenchmarkData,
        snapshotBenchmarkError,
        snapshotBenchmarkLoading,
        windAttributes,
      }}
    >
      {children}
    </ReadinessReportContext.Provider>
  );
};
