import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  CustomSelect,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiTextColor,
  EuiToolTip,
  Icon,
  Select,
  Spacer,
  TextField,
  useToast,
} from 'ui';
import { API_BASE_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import JobErrorsCallout from '@app/cx/Stream/EditDataFlyout/Callouts/JobErrorsCallout';
import { getIndustryEnumData } from '@app/cx/utils/utils';
import { JobErrors } from '@app/dice/EnrichmentProjects/types';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { JobType, ScopeType } from '@app/graphql/types';
// FIX ME
// @ts-ignore
import GET_JOBS_V2 from '@app/queries/organizations/getJobsV2.gql';
// FIX ME
// @ts-ignore
import GET_ORGANIZATIONS from '@app/queries/organizations/getOrganizations.gql';
import {
  EJobType,
  GetJobsV2Data,
  GetJobsV2Variables,
  IGetOrganizationsData,
  IGraphQLOrganization,
} from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import GET_ROLE_ASSIGNMENTS from '@app/queries/rbac/getRoleAssignments.gql';
// FIX ME
// @ts-ignore
import IMPORT_PROPERTY_MANAGERS from '@app/queries/user/registerPropertyManagers.gql';
import {
  RegisterPropertyManagersData,
  RegisterPropertyManagersVariable,
} from '@app/queries/user/types';
import download from '@app/utils/download';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useOrgModalContext } from '../../context/OrgModal.context';
import OrgLevelSelect from '../../OrgLevelSelect';
import OrgTypeSelect from '../../OrgTypeSelect';
import PropertyManagersErrors from '../../PropertyManagersErrors';
import usePALJob from '../../usePALJob';

const GeneralTab: React.FC = () => {
  const toast = useToast();
  const {
    org,
    action,
    values,
    selectedTemplateOrg,
    disabled,
    onValueChange,
    setSelectedTemplateOrg,
    updateValues,
    domainOptions,
  } = useOrgModalContext();

  const { account, getIdToken } = useAuth();

  const [jobErrors, setJobErrors] = useState<JobErrors | null>(null);
  const [defaultJobID, setDefaultJobID] = useState<string | null>(null);

  const { data: orgsData } = useQuery<IGetOrganizationsData>(GET_ORGANIZATIONS);
  const [orgEnumData, setOrgEnumData] = useState([]);

  const fetchData = () => getIndustryEnumData().then((data) => setOrgEnumData(data));

  const inputRef = useRef<HTMLInputElement>(null);
  const palImportRef = useRef<HTMLInputElement>(null);

  const jobsApolloClient = useJobsApolloClient();

  const [registerPropertyManagers, registerResult] = useMutation<
    RegisterPropertyManagersData,
    RegisterPropertyManagersVariable
  >(IMPORT_PROPERTY_MANAGERS);

  useQuery<GetJobsV2Data, GetJobsV2Variables>(GET_JOBS_V2, {
    client: jobsApolloClient,
    onCompleted: ({ jobsV2: { jobs } }) => {
      if (jobs.length > 0) {
        const { id } = jobs[0];
        setDefaultJobID(id);
      }
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !org || action === 'add',
    variables: {
      jobsFilter: {
        activeOnly: true,
        orgName: org?.name,
        type: JobType.PalIngest as any as EJobType,
        userID: account?.userId,
      },
      pageSize: 1,
    },
  });

  const { submit: submitPAL, loading: loadingPAL } = usePALJob({
    defaultJobID,
    onAPIError: (err) => {
      toast({ title: getErrorMessage(err), type: 'danger' });
    },
    onJobError: (e) => {
      toast({
        title: 'There were some issues when importing your worksheet. Scroll down for more details',
        type: 'danger',
      });
      setJobErrors(e);
    },
    onSuccess: () => {
      toast({ title: 'Successfully imported Policy and Loss file', type: 'success' });
    },
    onTimeout: () => {
      toast({
        title:
          'Your PAL changes are still being processed. Please come back later to see your changes or try another upload',
        type: 'warning',
      });
    },
    orgName: org?.name as string,
  });

  const [registerErrors, setRegisterErrors] = useState<Array<string>>([]);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (registerResult.called && !registerResult.loading) {
      if (registerResult.error) {
        if (registerResult.error.message?.includes('requested action not authorized')) {
          toast({ title: 'Import property managers is not authorized', type: 'danger' });
        } else {
          toast({ title: registerResult.error.message, type: 'danger' });
        }
      } else {
        const errors = registerResult.data?.registerPropertyManagers || [];
        if (errors.length === 0) {
          toast({ title: 'Successfully imported property managers', type: 'success' });
        } else {
          setRegisterErrors(errors);
        }
      }
    }
  }, [registerResult.loading, registerResult.data, registerResult.error]);

  if (!account) {
    return null;
  }

  const displayCurrencyOptions = [
    { label: 'USD - US Dollar', value: 'USD' },
    { label: 'EUR - Euro', value: 'EUR' },
    { label: 'GBP - Pound Sterling', value: 'GBP' },
    { label: 'CAD - Canadian Dollar', value: 'CAD' },
    { label: 'None - No currency prefix', value: 'ZZZ' },
  ];

  const unitOfMeasurementOptions = [
    { label: 'Imperial', value: 'imperial' },
    { label: 'Metric', value: 'metric' },
  ];

  const [isDomainInvalid, setDomainInvalid] = useState(false);

  const isValidDomain = (value: string) => {
    // Check for valid domain
    const domain = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    return value.match(domain) !== null;
  };

  const getBusinessTypes = (industry: string | undefined) => {
    const industryBusinesses = orgEnumData?.filter((i: any) => i.value === industry);

    return industryBusinesses[0]?.list;
  };

  const businessTypes = getBusinessTypes(values.industry);

  const onCreateOption = (searchValue: string) => {
    if (!isValidDomain(searchValue)) {
      // Return false to explicitly reject the user's input.
      return false;
    }

    // Sends just the string/text of the option so it will be added to the list
    return onValueChange('autoDomains')(searchValue);
  };

  const onDomainChange = (selectedOptions: any) => {
    // Sends the entire new list so it will replace the existing list
    onValueChange('autoDomains')(selectedOptions);
    setDomainInvalid(false);
  };

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files.length === 0) {
      return;
    }

    setRegisterErrors([]);

    registerPropertyManagers({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ROLE_ASSIGNMENTS,
          variables: {
            scope: {
              type: ScopeType.OrgId,
              value: org?.id as string,
            },
          },
        },
      ],
      variables: {
        input: {
          document: files[0],
          orgName: `${org?.name}`,
        },
      },
    });

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onPALFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files.length === 0) {
      return;
    }

    setJobErrors(null);
    submitPAL(files[0]);

    if (palImportRef.current) {
      palImportRef.current.value = '';
    }
  };

  const exportPropertyManagers = async () => {
    await download({
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${getIdToken ? getIdToken() : ''}`,
        },
      ],
      url: `${API_BASE_URL}/organizations/${org?.id}/export/property/managers?attributes=archipelagoId,locationId,locationName`,
    }).catch((err) => {
      if (err.statusCode === 403) {
        toast({ title: 'Export property managers is not authorized', type: 'danger' });
      } else {
        toast({ title: 'Could not export property managers', type: 'danger' });
      }
    });
  };

  const exportPAL = async () => {
    await download({
      headers: [
        {
          name: 'Authorization',
          value: `Bearer ${getIdToken ? getIdToken() : ''}`,
        },
      ],
      url: `${API_BASE_URL}/organizations/${org?.id}/pal/export`,
    }).catch(() => toast({ title: 'Could not export PAL', type: 'danger' }));
  };

  return (
    <>
      <EuiForm>
        <input
          data-testid="import-pm-upload-input"
          ref={inputRef}
          onChange={onFileSelect}
          type="file"
          style={{ display: 'none' }}
        />
        <input
          ref={palImportRef}
          onChange={onPALFileSelect}
          type="file"
          style={{ display: 'none' }}
        />
        <EuiFormRow label={getRequiredLabel('Account Name')} fullWidth>
          <TextField
            data-testid="organization-name-input"
            name="orgName"
            value={values.orgName}
            onChange={onValueChange('orgName')}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <OrgTypeSelect
          withSystemType={org?.orgType !== 'Template'}
          values={values}
          disabled={disabled}
          action={action}
          onChange={(orgTypes) => updateValues({ ...values, ...orgTypes })}
        />
        <EuiFormRow label="Sales Channel" fullWidth>
          <TextField
            data-testid="sales-channel-input"
            name="salesChannel"
            value={values.salesChannel}
            onChange={onValueChange('salesChannel')}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow label="Salesforce Account ID" fullWidth>
          <TextField
            data-testid="salesforce-account-id"
            name="salesforceAccountID"
            value={values.salesforceAccountID}
            onChange={onValueChange('salesforceAccountID')}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        {action === 'add' && values.orgType === 'Template' && (
          <EuiFormRow label="Template From Account" fullWidth>
            <Select
              options={orgsData?.organizations?.map((organization: IGraphQLOrganization) => ({
                label: `${organization.name}`,
                value: organization.name,
              }))}
              value={`${selectedTemplateOrg}`}
              onChange={(value) => setSelectedTemplateOrg(value)}
              fullWidth
            />
          </EuiFormRow>
        )}
        <EuiFormRow
          fullWidth
          label={
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>Domains</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content="If a user's email address matches an assigned domain at signup they will automatically be added to this account."
                  position="right"
                >
                  <Icon name="help" size="m" />
                </EuiToolTip>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
          isInvalid={isDomainInvalid}
          error={isDomainInvalid ? 'Must be a valid domain name' : undefined}
          helpText="Press enter to add domain"
        >
          <CustomSelect
            placeholder="No domains assigned"
            initialSelected={(values.autoDomains || []).map((v) => ({ label: v, value: v }))}
            options={domainOptions
              ?.sort((a, b) => a.localeCompare(b))
              .map((v) => ({ label: v, value: v }))}
            onCreateOption={onCreateOption}
            onChange={onDomainChange}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow label={getRequiredLabel('Industry')} fullWidth>
          <Select
            data-testid="industry-select"
            options={orgEnumData?.map((key: any) => ({
              label: key.value,
              value: key.value,
            }))}
            value={values.industry}
            onChange={onValueChange('industry')}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow label={getRequiredLabel('Business Type')} fullWidth>
          <Select
            data-testid="business-type-select"
            options={businessTypes?.map((bizType: string) => ({
              label: bizType,
              value: bizType,
            }))}
            value={values.businessType}
            onChange={onValueChange('businessType')}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label={
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>Display Currency</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content="The currency the organization's streams will show in. This setting is cached and can take up to one hour to show on platform."
                  position="right"
                >
                  <Icon name="help" size="m" />
                </EuiToolTip>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        >
          <Select
            options={displayCurrencyOptions}
            onChange={onValueChange('displayCurrency')}
            value={values.displayCurrency}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label={
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>Unit of Measurement</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content="The unit of measurement for floor space - Imperial = Sq Ft, Metric = Sq Meters."
                  position="right"
                >
                  <Icon name="help" size="m" />
                </EuiToolTip>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        >
          <Select
            options={unitOfMeasurementOptions}
            onChange={onValueChange('unitOfMeasurement')}
            value={values.unitOfMeasurement}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <Spacer />
        <OrgLevelSelect onChange={onValueChange('orgLevel')} values={values} action={action} />
        {action === 'edit' && (
          <>
            <EuiFormRow label="Property managers" fullWidth>
              <>
                <Checkbox
                  id="enablePropertyManagerEdit"
                  label="Allow PropertyManagers to edit"
                  checked={values.enablePropertyManagerEdit}
                  onChange={onValueChange('enablePropertyManagerEdit')}
                />
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <Button
                      data-testid="import-property-manager-button"
                      label="Import"
                      onClick={() => inputRef.current?.click()}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <Button
                      data-testid="export-property-manager-button"
                      label="Export"
                      onClick={exportPropertyManagers}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            </EuiFormRow>
            <EuiFormRow label="Programs, Policies and Losses" fullWidth>
              <>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <Button
                      label="Import"
                      loading={loadingPAL}
                      onClick={() => palImportRef.current?.click()}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <Button label="Export" onClick={exportPAL} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            </EuiFormRow>
            {!!jobErrors && (
              <EuiFormRow fullWidth>
                <JobErrorsCallout jobErrors={jobErrors} />
              </EuiFormRow>
            )}
          </>
        )}
      </EuiForm>
      {registerErrors.length > 0 && (
        <EuiFlexGroup style={{ marginTop: '16px' }}>
          <EuiFlexItem>
            <PropertyManagersErrors errors={registerErrors} onClose={() => setRegisterErrors([])} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};

export default GeneralTab;

const getRequiredLabel = (label: string) => (
  <>
    {label}
    <EuiTextColor color="danger"> *</EuiTextColor>
  </>
);
