import styled from '@emotion/styled';
import { EuiBasicTable,EuiFlexItem, EuiLink, EuiPanel, EuiText } from 'ui';

export const TableContainer = styled(EuiPanel)`
  margin-top: 24px;
`;

export const TextContainer = styled(EuiText)`
  max-width: 720px;
`;

export const CurrencyCell = styled.div`
  background-color: black;
`;

export const UploadButtonContainer = styled(EuiFlexItem)`
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
`;

export const IconContainer = styled(EuiFlexItem)`
  padding-left: 8px;
  height: 100%;
  justify-content: center;
`;

export const UploadButton = styled(EuiLink)`
  padding: 6px 12px 6px 12px;
  color: #0f766e;
  background-color: #ccf2f0;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

// FIX ME
// @ts-ignore
export const StyledTable = styled(EuiBasicTable)`
  padding-top: 16px;
  .euiTableHeaderCell:nth-child(1) {
    width: 110px;
  }
  .euiTableHeaderCell:nth-child(2) {
    width: 80px;
  }
  .euiTableHeaderCell:nth-child(3) {
    width: 200px;
  }
  .euiTableHeaderCell:nth-child(4) {
    width: 200px;
  }
  .euiTableHeaderCell:nth-child(5) {
    width: 120px;
  }
  .euiTableHeaderCell:nth-child(6) {
    width: 336px;
  }
  .euiTableHeaderCell:nth-child(7) {
    width: 90px;
    .euiTableCellContent {
      justify-content: right;
    }
  }
  .euiTableRow {
    .euiTableRowCell:nth-child(7) {
      .euiTableCellContent {
        width: 90px;
        justify-content: right;
      }
    }
  }
`;
