import React, { FC, useEffect } from 'react';
import { useToast } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useStreamContext } from '../StreamProvider';
import { usePageState } from '../utils';
import PasswordAccountRequired from './PasswordAccountRequired/PasswordAccountRequired';
import RequestAccess from './RequestAccess/RequestAccess';
import { canRequestAccess, passwordAccountRequired } from './canRequestAccess';
import Redirect from './Redirect';
import { shouldRedirectToUpload } from './shouldRedirectToUpload';
import StreamRenderStepTwo from './StreamRenderStepTwo';
import { Props } from './types';
import { isUnauthorizedSnapshotErr } from './utils';

const StreamRenderStepOne: FC<Props> = (props) => {
  const { account } = useAuth();
  const { error, stream } = useStreamContext();
  const [, setPageState] = usePageState();
  const toast = useToast();

  const isUnauthorizedSnapshot = isUnauthorizedSnapshotErr(error);

  useEffect(() => {
    if (isUnauthorizedSnapshot) {
      toast({
        label: 'We’re redirecting you to the most recent data you can view.',
        title: 'You don’t have access to the requested link.',
        type: 'warning',
      });
      setPageState({ currentSnapshot: null });
    }
  }, [isUnauthorizedSnapshot]);

  if (isUnauthorizedSnapshot) {
    return <LoadingSpinnerV2 dataTestId="loading-spinner" />;
  }

  if (canRequestAccess(error)) {
    return <RequestAccess />;
  }

  if (passwordAccountRequired(error)) {
    return <PasswordAccountRequired />;
  }

  if (!account || !!error) {
    return <Redirect path="/" />;
  }

  if (shouldRedirectToUpload(account, stream)) {
    return <Redirect path="/upload" />;
  }

  return <StreamRenderStepTwo {...props} />;
};

export default StreamRenderStepOne;
