import React from 'react';
import StreamMessagePage from '../StreamMessagePage';
import { useRequestAccess } from './useRequestAccess';

const RequestAccess: React.FC = () => {
  const { data, loading, requestAccess } = useRequestAccess();

  if (data?.requestAccess) {
    return (
      <StreamMessagePage
        hideButton
        iconName="checkCircle"
        subTitle="We've got your request. Once you've been approved, we'll send you an email allowing you to join."
        title="Your request has been sent!"
      />
    );
  }
  if (data?.accessPending) {
    return (
      <StreamMessagePage
        hideButton
        iconName="checkCircle"
        subTitle="We've got your request. Once you've been approved, we'll send you an email allowing you to join."
        title="Your request is pending"
      />
    );
  }

  return (
    <StreamMessagePage
      buttonLabel="Request access to this Stream"
      buttonLoading={loading}
      handleClick={requestAccess}
      subTitle={`
        Your access would first need to be approved before you can view this stream. Click the button below to request
        access.
      `}
      title="You don't have access to this Stream"
    />
  );
};

export default RequestAccess;
