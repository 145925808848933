import React from 'react';
import { useHistory } from 'react-router';
import StarRatings from 'react-star-ratings';
import styled from '@emotion/styled';
import { Button, EuiCard, EuiFlexGroup, EuiFlexItem, EuiSpacer, Icon, Spinner } from 'ui';
import BuildingEnvelope from 'ui/src/neutrons/icon/svgs/BuildingEnvelope';

interface CardProps {
  loading: boolean;
  canManageProperties: boolean;
  config: {
    background: string;
    color: string;
    button: string;
    icon: string;
    label: string;
    title: string;
    subtitle: string;
    to: string;
    data: number;
  };
}

export const CARD_CONFIG = {
  recommendations: {
    background: '#FFF9E8',
    button: '#FFF3D0',
    color: '#DA8B45',
    icon: 'checkCircle',
    label: 'Pre-Check',
    subtitle: '',
    title: 'View Recommendations',
  },
  sov: {
    background: '#E6F1FA',
    button: '#CCE4F5',
    color: '#0071C2',
    icon: 'buildingEnvelope',
    label: 'Properties',
    subtitle: 'SOV\'s',
    title: 'Manage Sov',
  },
  tasks: {
    background: '#E6F9F7',
    button: '#CCF2F0',
    color: '#007E77',
    icon: 'inbox',
    label: 'Inbox',
    subtitle: 'TASKS',
    title: 'Tasks',
  },
};

export const CARD_CONFIG_CASUALTY = {
  casualty: {
    background: '#E6F9F7',
    button: '#CCF2F0',
    color: '#007E77',
    icon: 'hexagon',
    label: 'Casualty',
    subtitle: 'Casualty',
    title: '',
  },
};

interface IColorIcon {
  color: string;
  strokeWidth?: string;
}

const ColoredIcon = styled(Icon)<IColorIcon>`
  color: ${(props) => props.color} !important;
  stroke-width: ${(props) => props.strokeWidth || '1'};
`;

const StyledCard = styled(EuiCard)`
  .euiCard__titleButton {
    width: 100%;
  }
`;

export const Card: React.FC<CardProps> = ({ loading, config, canManageProperties }) => {
  const history = useHistory();

  if (config.label === CARD_CONFIG.recommendations.label && !config.data) {
    return <></>;
  }

  if (
    (config.label === CARD_CONFIG.sov.label || config.label === CARD_CONFIG.tasks.label) &&
    !canManageProperties
  ) {
    return <></>;
  }
  const title = (
    <EuiFlexGroup justifyContent="spaceBetween">
      <EuiFlexItem grow={2}>
        {config.label === CARD_CONFIG.sov.label ? (
          <BuildingEnvelope color={config.color} size={32} />
        ) : (
          <ColoredIcon color={config.color} name={config.icon} size="xl" />
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={1}>
        <Button
          size="s"
          color={config.button}
          style={{ backgroundColor: config.button, borderRadius: '16px', width: '128px' }}
          label={
            <>
              {config.label}{' '}
              <ColoredIcon strokeWidth={'3'} color={config.color} name="arrowRight" size="s" />{' '}
            </>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const description = (
    <div style={{ marginTop: '32px' }}>
      <h4 style={{ color: config.color, fontSize: '22px', fontWeight: 400 }}>
        {config.title.toUpperCase()}
      </h4>
      <EuiSpacer size="s" />
      {config.label === CARD_CONFIG.recommendations.label ? (
        <StarRatings
          rating={config.data || 0}
          starDimension="22px"
          starRatedColor={config.color}
          numberOfStars={5}
          name="rating"
        />
      ) : (
        <EuiFlexGroup gutterSize="s" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <h4 style={{ fontSize: '34px', fontWeight: 600 }}>{config.data}</h4>
          </EuiFlexItem>
          <EuiFlexItem style={{ color: '#69707D', fontSize: '16px', fontWeight: 400 }} grow={false}>
            {config.subtitle.toLowerCase()}
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </div>
  );
  return (
    <EuiFlexItem grow={false} style={{ width: '360px' }}>
      <StyledCard
        style={{ backgroundColor: config.background, borderRadius: '16px' }}
        onClick={() => history.push(config.to)}
        textAlign="left"
        titleSize="s"
        title={title}
        description={loading ? <Spinner /> : description}
      />
    </EuiFlexItem>
  );
};
