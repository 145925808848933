import { PropertyDataInput } from '@app/graphql/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import {
  ADDRESS_INPUT_KEYS,
  CITY_INPUT_KEY,
  COUNTRY_INPUT_KEY,
  COUNTY_INPUT_KEY,
  DERIVED_CURRENCY_KEYS,
  GEO_INPUT_KEYS,
  POSTAL_CODE_INPUT_KEY,
  STATE_INPUT_KEY,
  STREET_ADDRESS_INPUT_KEY,
} from './constants';

export const hasAddressFields = (data: PropertyDataInput) => Object.keys(data).some((key) => ADDRESS_INPUT_KEYS.includes(key));

export const hasGeoFields = (data: PropertyDataInput) => Object.keys(data).some((key) => GEO_INPUT_KEYS.includes(key));

export const makeOriginalAddressField = (data: PropertyDataInput) => {
  const addressFieldsInOrder = [
    data[STREET_ADDRESS_INPUT_KEY],
    data[CITY_INPUT_KEY],
    data[COUNTY_INPUT_KEY],
    data[STATE_INPUT_KEY],
    data[POSTAL_CODE_INPUT_KEY],
    data[COUNTRY_INPUT_KEY],
  ].filter(Boolean);

  return addressFieldsInOrder.join(' ');
};

export const getSubValueCurrencyFieldsToParentMap = (
  propertyAttributeMetadata: IGraphQLAttributeMetadata[],
): { [key: string]: IGraphQLAttributeMetadata[] } => {
  const derivedCurrencyFields = propertyAttributeMetadata
    .filter((attribute) => DERIVED_CURRENCY_KEYS.includes(attribute.name) && attribute.derived)
    .map((attribute) => attribute.name);

  const subValueParentMap = propertyAttributeMetadata
    .filter((attribute) => derivedCurrencyFields.includes(attribute.parent) && !attribute.derived)
    .reduce((acc, value) => {
      if (!value.parent) {
        return;
      }
      if (!acc[value.parent]) {
        acc[value.parent] = [value];
      } else {
        acc[value.parent].push(value);
      }
      return acc;
    }, {});

  return subValueParentMap;
};
