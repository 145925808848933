import React, { FC } from 'react';
import { CurrencyField, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, Spacer } from 'ui';
import { LineOfBusiness, PolicyEffectiveYearId } from '@app/graphql/types';
import { useCasualtyData } from '../context/context';
import {
  AllPolicyEffectiveYearID,
  getTitle,
  humanReadablePolicyEffectiveYear,
  mySectionKey,
} from '../utils';

interface StepXProps {
  sectionKey: mySectionKey;
}

export const StepX: FC<StepXProps> = ({ sectionKey }) => {
  const { casualty, setCasualty, viewOnly } = useCasualtyData();

  return (
    <>
      <EuiText>
        <h3>{getTitle(sectionKey)}</h3>
      </EuiText>
      <Spacer size="l" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <strong>Policy Year ID</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>AL</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>WC</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>GL</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size="xs" />
      {AllPolicyEffectiveYearID.map((label) => {
        const policyEffectiveYear =
          casualty !== undefined
            ? casualty[sectionKey]?.find((exposure) => exposure.policyEffectiveYear === label)
            : undefined;

        const alDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Al,
        );
        const wcDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Wc,
        );
        const glDisabled = !casualty?.requestedLinesOfBusiness?.find(
          (l) => l.lineOfBusiness === LineOfBusiness.Gl,
        );

        const setCurrencyValue = (
          label: PolicyEffectiveYearId,
          key: 'al' | 'wc' | 'gl',
          value: number,
        ) => {
          const exists =
            casualty !== undefined
              ? casualty[sectionKey]?.find((e) => e.policyEffectiveYear === label)
              : false;

          if (casualty[sectionKey] === undefined) {
            setCasualty({
              ...casualty,
              [sectionKey]: [
                {
                  al: key === 'al' ? value : 0,
                  gl: key === 'gl' ? value : 0,
                  policyEffectiveYear: label,
                  wc: key === 'wc' ? value : 0,
                },
              ],
            });
          } else {
            setCasualty({
              ...casualty,
              [sectionKey]: exists
                ? casualty[sectionKey].map((exposure) => {
                    if (exposure.policyEffectiveYear === label) {
                      return {
                        ...exposure,
                        [key]: value,
                      };
                    }
                    return exposure;
                  })
                : casualty[sectionKey]?.concat(casualty[sectionKey], {
                    al: key === 'al' ? value : 0,
                    gl: key === 'gl' ? value : 0,
                    policyEffectiveYear: label,
                    wc: key === 'wc' ? value : 0,
                  }),
            });
          }
        };

        return (
          <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="&nbsp;">
                  <>
                    <>{humanReadablePolicyEffectiveYear(label)}</>
                  </>
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!alDisabled ? (policyEffectiveYear?.al || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'al', e);
                  }}
                  disabled={alDisabled}
                  prepend="$"
                  readOnly={!alDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!wcDisabled ? (policyEffectiveYear?.wc || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'wc', e);
                  }}
                  disabled={wcDisabled}
                  prepend="$"
                  readOnly={!wcDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <CurrencyField
                  value={!glDisabled ? (policyEffectiveYear?.gl || 0) + '' : '0'}
                  onChange={(e) => {
                    setCurrencyValue(label, 'gl', e);
                  }}
                  disabled={glDisabled}
                  prepend="$"
                  readOnly={!glDisabled ? viewOnly : undefined}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <Spacer size="xs" />
          </>
        );
      })}
    </>
  );
};
