import React, { createContext, ReactNode, useContext } from 'react';
import { Organization } from '@app/graphql/types';
import { IForm, ModalAction } from '../types';

export interface IntegrationsSettings {
  enabled: boolean;
  limit: number;
}
interface IOrgModalContext {
  org: Organization;
  orgId: string | null;
  orgName: string | null;
  action: ModalAction;
  values: IForm;
  integrationsSettings: IntegrationsSettings;
  selectedTemplateOrg: string | null;
  domainOptions: Array<string>;
  ssoConfigName: string;
  accountAdminToManageAccountAdminDisabled: boolean;
  precheckValue: boolean;
  disabled: boolean;
  onValueChange: (field: string) => (e: any) => void;
  setSelectedTemplateOrg: (v: string) => void;
  setSSOConfigName: (v: string) => void;
  setAccountAdminToManageAccountAdminDisabled: (v: boolean) => void;
  setPrecheckValue: (v: boolean) => void;
  updateValues: (newValues: IForm) => void;
  marketAccessDomainOptions: Array<string>;
  setMarketAccessDomainOptions: (v: string[]) => void;
  setIntegrationsSettings: (s: IntegrationsSettings) => void;
}

const OrgModalContext = createContext({
  domainOptions: [],
  marketAccessDomainOptions: [],
} as IOrgModalContext);

export const OrgModalProvider = ({
  children,
  org,
  orgId,
  orgName,
  action,
  values,
  integrationsSettings,
  selectedTemplateOrg,
  domainOptions,
  ssoConfigName,
  accountAdminToManageAccountAdminDisabled,
  precheckValue,
  disabled,
  onValueChange,
  setSelectedTemplateOrg,
  setSSOConfigName,
  setAccountAdminToManageAccountAdminDisabled,
  setPrecheckValue,
  updateValues,
  marketAccessDomainOptions,
  setMarketAccessDomainOptions,
  setIntegrationsSettings,
}: {
  org: Organization;
  orgId: string | null;
  orgName: string | null;
  action: ModalAction;
  values: IForm;
  integrationsSettings: IntegrationsSettings;
  selectedTemplateOrg: string | null;
  domainOptions: Array<string>;
  accountAdminToManageAccountAdminDisabled: boolean;
  ssoConfigName: string;
  precheckValue: boolean;
  disabled: boolean;
  onValueChange: (field: string) => (e: any) => void;
  setSelectedTemplateOrg: (v: string) => void;
  setSSOConfigName: (v: string) => void;
  setAccountAdminToManageAccountAdminDisabled: (v: boolean) => void;
  setPrecheckValue: (v: boolean) => void;
  updateValues: (newValues: IForm) => void;
  marketAccessDomainOptions: Array<string>;
  setMarketAccessDomainOptions: (v: string[]) => void;
  setIntegrationsSettings: (s: IntegrationsSettings) => void;
  children: ReactNode;
}) => (
    <OrgModalContext.Provider
      value={{
        accountAdminToManageAccountAdminDisabled,
        action,
        disabled,
        domainOptions,
        integrationsSettings,
        marketAccessDomainOptions,
        onValueChange,
        org,
        orgId,
        orgName,
        precheckValue,
        selectedTemplateOrg,
        setAccountAdminToManageAccountAdminDisabled,
        setIntegrationsSettings,
        setMarketAccessDomainOptions,
        setPrecheckValue,
        setSSOConfigName,
        setSelectedTemplateOrg,
        ssoConfigName,
        updateValues,
        values,
      }}
    >
      {children}
    </OrgModalContext.Provider>
  );

export const useOrgModalContext = () => useContext(OrgModalContext);
