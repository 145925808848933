import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  Badge,
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  Spinner,
} from 'ui';
import { useGetSupportTicketsPageQuery } from '@app/graphql/queries/support/__generated__/getSupportTicketsPage.generated';
import {
  BinaryOperator,
  OrgInfo,
  SortOrder,
  SupportTicket,
  TicketStatus,
} from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useChatContext } from '../Chat/ChatProvider';
import EmptyMessage from '../EmptyMessage/EmptyMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface Props {
  includeClosedTickets?: boolean;
}
export const TicketsTable: React.FC<Props> = ({ includeClosedTickets }) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const history = useHistory();

  const { data, loading, error } = useGetSupportTicketsPageQuery({
    variables: {
      input: {
        cursor: '',
        filter: [
          {
            name: 'customFields.status',
            operator: includeClosedTickets ? BinaryOperator.In : BinaryOperator.NotIn,
            values: [TicketStatus.Closed, TicketStatus.Resolved],
          },
        ],
        includeClosedTickets: null,
        sort: [{ attributeName: 'ts', order: SortOrder.Descending }],
      },
    },
  });

  const { roomSubscriptionsMap } = useChatContext();

  const columns: Array<EuiBasicTableColumn<SupportTicket>> = [
    {
      field: 'title',
      name: 'Title',
      render: (title: string, ticket) => {
        const subscription = roomSubscriptionsMap?.[ticket.chatRoomID];
        return (
          <EuiFlexGroup alignItems="center" justifyContent="flexStart">
            <EuiFlexItem>{title}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Badge
                color="primary"
                label={`${subscription?.unread}`}
                hidden={!subscription?.unread}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'org',
      name: 'Account',
      render: (org: OrgInfo) => org?.name || 'None',
    },
    {
      field: 'authorEmail',
      name: 'Author',
    },
    {
      field: 'assigneeEmails',
      name: 'Assigned to',
      render: (v) => v?.join(', ') || 'No one',
    },
    {
      field: 'status',
      name: 'Status',
    },
    {
      field: 'createdAt',
      name: 'Created on',
      render: (v) => moment(v).format('YYYY-MM-DD HH:MM'),
    },
  ];

  // Manually handle pagination of data
  const findTickets = (tickets: Array<SupportTicket>, pageIndex: number, pageSize: number) => {
    let pageOfItems;

    if (!pageIndex && !pageSize) {
      pageOfItems = tickets;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = tickets.slice(startIndex, Math.min(startIndex + pageSize, tickets.length));
    }

    return {
      pageOfItems,
      totalItemCount: tickets.length,
    };
  };

  const { pageOfItems, totalItemCount } = findTickets(
    // FIX ME
    // @ts-ignore
    data?.supportTicketsPage?.tickets || [],
    pageIndex,
    pageSize,
  );

  const onTableChange = ({ page }: Criteria<SupportTicket>) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={getErrorMessage(error)} />;
  }

  if (!data?.supportTicketsPage?.tickets?.length) {
    return (
      <EmptyMessage
        header="No tickets found"
        subText='Click on "Open Ticket" button to get started'
      />
    );
  }

  return (
    <>
      <EuiBasicTable
        style={{ overflowY: 'auto', paddingBottom: '48px' }}
        columns={columns}
        pagination={{
          pageIndex,
          pageSize,
          totalItemCount,
        }}
        loading={loading}
        onChange={onTableChange}
        items={pageOfItems}
        rowProps={(row) => ({
          onClick: () => {
            history.push(`/support/${row.chatRoomID}`);
          },
          style: {
            fontWeight: roomSubscriptionsMap?.[row.chatRoomID]?.unread ? 'bold' : 'unset',
          },
        })}
      />
    </>
  );
};
