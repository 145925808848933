import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiToolTip } from 'ui';
import { formatNumber } from '@app/utils/format';

interface Props {
  locations: number;
  percent: number;
  active?: boolean;
}

const Bar: FC<Props> = ({ locations, percent, active = true }) => {
  const formattedProperties = formatNumber(locations);
  const formattedPercent = Number.parseFloat((percent || 0).toFixed(2));
  const remainingPercent = Number.parseFloat((100 - formattedPercent).toFixed(2));

  return (
    <EuiFlexGroup gutterSize="none" style={{ alignItems: 'center' }}>
      <EuiFlexItem style={{ flexGrow: formattedPercent }}>
        <EuiToolTip
          position="bottom"
          content={
            <p>
              {formattedPercent}% of TIV
              <br />
              {formattedProperties} properties
            </p>
          }
        >
          <div
            style={{
              // Must be an odd number for the other line to be centered
              backgroundColor: active ? '#21B59A' /* euiColorSuccess */ : '#E0E6EB',
              height: 9 /* euiColorLightShade */,
              margin: 'auto 1px',
            }}
          />
        </EuiToolTip>
      </EuiFlexItem>
      <EuiFlexItem style={{ flexGrow: remainingPercent }}>
        <div
          style={{
            backgroundColor: '#98A2B3',
            height: 1 /* euiColorMediumShade */,
            margin: 'auto 0',
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Bar;
