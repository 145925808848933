import React, { FC } from 'react';
import { SeriesOptionsType } from 'highcharts';
import { chartColors } from 'ui';
import colors from '@app/utils/colors';
import { DonutColumn } from '../types';
import Chart from './Chart';
import tooltip from './ChartTooltip';

interface IProps {
  column: DonutColumn;

  width: number;
  height: number;
}

const DEFAULT_OPTIONS = {
  chart: {
    margin: 0,
    type: 'pie',
  },
  colors: chartColors,
  credits: undefined,
  exporting: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      shadow: false,
    },
  },
  title: {
    text: '',
  },
  yAxis: {
    title: {
      text: '',
    },
  },
};
const getPie = (column: DonutColumn): SeriesOptionsType => {
  const pie: SeriesOptionsType = {
    dataLabels: {
      enabled: false,
    },
    innerSize: '60%',
    name: `${column.label}`,
    showInLegend: false,
    size: '100%',
    type: 'pie',
  };

  const data = column.data.map((d) => ({
    myData: d.data,
    name: d.label,
    y: d.value,
  }));

  if (data.length === 0) {
    data.push({ myData: null, name: 'Empty chart', y: 1 });
    pie.enableMouseTracking = false;
    pie.colors = [colors.gray5];
  }
  pie.data = data;
  return pie;
};
const Donut: FC<IProps> = ({ column }) => {
  // FIX ME
  // @ts-ignore
  const options: Highcharts.Options = {
    ...DEFAULT_OPTIONS,
    series: [getPie(column)],
    tooltip,
  };

  return (
    <>
      <label>{column.label}</label>
      <Chart options={options} />
    </>
  );
};

export default Donut;
