import React from 'react';
import moment from 'moment';
import { EuiFlexGroup, EuiPageHeader, EuiPanel, EuiSpacer, useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useCasualtyPageQuery } from '@app/graphql/casualty/queries/__generated__/casualtyPage.generated';
import { useOrgSummaryQuery } from '@app/graphql/queries/organization/__generated__/OrgSummary.generated';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { useInboxContext } from '@app/platform/Inbox/context/InboxContext';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import {
  FEATURE_TYPE_CASUALTY_ONLY,
  FEATURE_TYPE_ENTERPRISE_REPORT,
  FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
} from '../../platform/SystemSettings/Flags/types';
import { Charts } from '../Charts/Charts';
import { Helmet } from '../Helmet';
import { tabToPath } from '../Layout/Layout';
import { CASUALTY_PATH, PRECHECK_PATH, STREAMS_PATH } from '../Layout/SideNav/constants';
import { List } from './BrokerChecklist/List';
import { Card, CARD_CONFIG, CARD_CONFIG_CASUALTY } from './Card';
import { getHomepageTitle } from './utils';
import { Dashboard } from '../Dashboard/Dashboard';

export const HomePage: React.FC = () => {
  const { account } = useAuth();
  const toast = useToast();
  const { selectedOrganization } = useUserSession();
  const isAdmin = account?.permissions?.admin;

  const { loading: loadingInbox, inbox } = useInboxContext();
  const { loading: loadingOrgSummary, data: orgSummaryData } = useOrgSummaryQuery({
    skip: !selectedOrganization?.name,
    variables: { input: { orgName: selectedOrganization?.name } },
  });

  const isEnterpriseReportEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_ENTERPRISE_REPORT,
  );

  const isHubAnalyticsForAdminsEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
  );

  const casualtyOnly = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_CASUALTY_ONLY,
  );

  const { loading: loadingCasualty, data: dataCasualty } = useCasualtyPageQuery({
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !casualtyOnly,
    variables: {
      input: {
        offset: 0,
        orgName: selectedOrganization?.name,
        pageSize: 100,
      },
    },
  });

  const isAdminAndHubAnalyticsEnabled = isAdmin && isHubAnalyticsForAdminsEnabled;

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const orgInfoPermissions = orgInfoData?.orgInfo?.permissions.viewEnterpriseReports;

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties');

  const orgSummary = orgSummaryData?.orgSummary;
  const getConfig = (key) => {
    if (key === 'casualty') {
      const config = { ...CARD_CONFIG_CASUALTY[key] };
      config.to = tabToPath(CASUALTY_PATH, selectedOrganization?.name);
      config.data = dataCasualty?.casualtyPage?.casualtyList?.length || 0;
      config.loading = loadingCasualty;
      return config;
    }
    const config = CARD_CONFIG[key];

    if (key === 'tasks') {
      config.to = '/inbox';
      config.data = inbox?.all?.length;
      config.loading = loadingInbox;
    } else if (key === 'sov') {
      config.to = tabToPath(STREAMS_PATH, selectedOrganization?.name);
      config.data = orgSummary?.sovCount;
      config.loading = loadingOrgSummary;
    } else if (key === 'recommendations') {
      config.to = tabToPath(PRECHECK_PATH, selectedOrganization?.name);
      config.data = orgSummary?.propertyRecommendationsGrade;
      config.loading = loadingOrgSummary;
    }

    return config;
  };

  const title = getHomepageTitle(moment(), account);

  const renderCard = (configKey: string) => {
    const cardConfig = getConfig(configKey);
    return (
      <Card
        config={cardConfig}
        loading={!!cardConfig.loading}
        canManageProperties={canManageProperties}
      />
    );
  };

  if (casualtyOnly) {
    return (
      <EuiPanel paddingSize="l">
        <Helmet title="Home" />
        <EuiPageHeader pageTitle={title} />
        <EuiSpacer />
        <>
          <h4>Here's what's going on with {selectedOrganization?.name} account</h4>
          <EuiSpacer />
          <EuiFlexGroup responsive>
            {Object.keys(CARD_CONFIG_CASUALTY).map(renderCard)}
          </EuiFlexGroup>
          <List />
        </>
        <Charts />
      </EuiPanel>
    );
  }

  return (
    <EuiPanel paddingSize="l">
      <Helmet title="Home" />
      <EuiPageHeader pageTitle={title} />
      <EuiSpacer />
      {!orgInfoPermissions && !isEnterpriseReportEnabled && !isAdminAndHubAnalyticsEnabled && (
        <>
          <h4>Here's what's going on with {selectedOrganization?.name} account</h4>
          <EuiSpacer />
          <EuiFlexGroup responsive>{Object.keys(CARD_CONFIG).map(renderCard)}</EuiFlexGroup>
          <List />
        </>
      )}
      <Charts />
      <Dashboard />
    </EuiPanel>
  );
};
