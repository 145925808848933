import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { formatCurrency } from '@app/utils/format';

interface Props {
  title: string;
  TIV: number;
  currency?: string;
}

const Title: FC<Props> = ({ title, TIV, currency }) => {
  const formattedTIV = formatCurrency(TIV, { currency });
  return (
    <EuiFlexGroup gutterSize="none" justifyContent="spaceBetween">
      <EuiFlexItem grow={false}>
        <EuiText size="s">{title}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText size="s">{formattedTIV} TIV</EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Title;
