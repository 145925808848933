import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { GET_ACCESS_REQUESTS } from '@app/platform/StreamUsers/queries/accessRequests';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { REQUEST_ACCESS, RequestAccessData, RequestAccessVariables } from './requestAccessQuery';

interface Params {
  streamSlug: string;
}

interface Return {
  data: RequestAccessData;
  loading: boolean;
  requestAccess: () => Promise<any>;
}

export const useRequestAccess = (): Return => {
  const toast = useToast();
  const { email } = useAuth().account;
  const params = useParams<Params>();

  const { data: pendingAccessData } = useQuery(GET_ACCESS_REQUESTS, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        streamSlug: params?.streamSlug || '',
        userEmail: email,
      },
    },
  });
  const returnData: RequestAccessData = {
    accessPending: false,
    requestAccess: false,
  };

  returnData.accessPending = pendingAccessData?.accessRequests.length > 0;
  const [requestAccess, { data, loading }] = useMutation<RequestAccessData, RequestAccessVariables>(
    REQUEST_ACCESS,
    {
      onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
      variables: {
        input: {
          streamSlug: params?.streamSlug || '',
          userEmail: email,
        },
      },
    },
  );

  returnData.requestAccess = data?.requestAccess;

  return {
    data: returnData,
    loading,
    requestAccess,
  };
};
