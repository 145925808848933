import React from 'react';
import { Route, Router } from 'react-router-dom';
import { builder } from '@builder.io/react';
import { UIProvider } from 'ui';
import { QueryParamProvider } from 'use-query-params';
import Apollo from '@app/components/Apollo/Apollo';
import { ChatProvider } from '@app/components/Chat/ChatProvider';
import ErrorBoundary from '@app/components/ErrorBoundary/ErrorBoundary';
import { Helmet,HelmetProvider  } from '@app/components/Helmet';
import Tracker from '@app/components/Tracker/Tracker';
import { BUILDER_IO_KEY } from '@app/config';
import Auth from '@app/containers/Auth/Auth';
import { UserSessionProvider } from '@app/contexts/UserSessionContext';
import { InboxProvider } from '@app/platform/Inbox/context/InboxContext';
import { AuthProvider } from '../AuthProvider/AuthProvider';
import { UpdateProvider } from '../UpdateProvider/UpdateProvider';
import Routes from './Routes/Routes';
import history from './history';
import { Widgets } from './Widgets';
import '!style-loader!css-loader!sass-loader!ui/src/index.scss';

builder.init(BUILDER_IO_KEY);

const DefaultHelmet = () => <Helmet title="Archipelago" />;
// onRedirectCallback - redirect to targetUrl from the appstate, or the / from the current location
const onRedirectCallback = (appState: any) => {
  history.push(appState?.targetUrl ? appState.targetUrl : (window as any).location.pathname);
};

export default () => (
  <HelmetProvider>
    <ErrorBoundary>
      <UIProvider>
        <Tracker>
          <AuthProvider onRedirectCallback={onRedirectCallback}>
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Auth>
                  <Widgets>
                    <Apollo>
                      <UserSessionProvider>
                        {() => (
                            <UpdateProvider>
                              <ChatProvider>
                                <InboxProvider>
                                  <>
                                    <DefaultHelmet />
                                    <Routes />
                                  </>
                                </InboxProvider>
                              </ChatProvider>
                            </UpdateProvider>
                          )}
                      </UserSessionProvider>
                    </Apollo>
                  </Widgets>
                </Auth>
              </QueryParamProvider>
            </Router>
          </AuthProvider>
        </Tracker>
      </UIProvider>
    </ErrorBoundary>
  </HelmetProvider>
);
