import React, { useMemo } from 'react';
import { EuiButtonIcon } from '@elastic/eui';
import { EuiFlexGroup, EuiFlexItem, Icon, MultiSelect } from 'ui';
import FilterButton from '@app/components/Filter/FilterButton';
import { useModalState } from '@app/contexts/ModalContext';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import Distiller from '@app/cx/Stream/Distillers/Distiller';
import { addToGroupByV2 } from '@app/cx/Stream/groupbyv2';
import InviteStreamModal from '@app/cx/Stream/InviteModal/InviteStreamModal';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { getDefaultDistillers, getMetadataByType, KEYS, usePageState } from '@app/cx/Stream/utils';
import debounce from '@app/dice/Pages/SmartDocs/components/utils/debounce';
import formatProperties from '@app/dice/Pages/SmartDocs/components/utils/formatProperties';
import { useStreamSearchQuery } from '@app/graphql/queries/streams/__generated__/StreamSearch.generated';
import { BinaryOperator } from '@app/graphql/types';
import { FilterQuery } from '../Filter/types';

export const SearchFilter = () => {
  const { stream } = useStreamContext();
  const { showModal } = useModalState();
  const {
    refetch,
    data: propertyData,
    loading,
  } = useStreamSearchQuery({
    skip: !stream,
    variables: {
      input: {
        limit: 50,
        streamSlug: stream?.slug,
      },
    },
  });
  const [pageState, setPageState] = usePageState();

  // FIX ME
  // @ts-ignore
  const { propertyAttributeMetadata, setSelectedProperties, permissions } =
    usePropertiesPageContext();

  // FIX ME
  // @ts-ignore
  const groupable = getMetadataByType(propertyAttributeMetadata, 'groupable');

  const distillers = useMemo(
    () =>
      getDefaultDistillers(
        {
          groupable,
        },
        pageState,
        permissions,
      ),
    [JSON.stringify(groupable), pageState.groupByV2, pageState.sizeBy],
  );

  const groupByDistiller = distillers.groupBy;
  groupByDistiller.name = 'groupBy';
  const propertyOptions = formatProperties(propertyData?.streamPropertiesPage?.properties);

  const searchProperties = async (val: string | undefined) => {
    refetch({
      input: {
        filter: val
          ? [
              {
                name: 'propertySearch',
                operator: BinaryOperator.Equal,
                values: [val],
              },
            ]
          : [],
        streamSlug: stream?.slug,
      },
    });
  };

  // FIX ME
  // @ts-ignore
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');
  const filters = pageState.filters;

  const handleFilterQueryChange = (filterQuery: FilterQuery | undefined) =>
    setPageState({
      filters: filterQuery || null,
    });

  const setBlankIfOpposite = (target: string, opposite: string) => ({
    // FIX ME
    // @ts-ignore
    [target]: name === opposite ? '' : distillers[target]?.value || '',
  });

  const onGroupByChange = (value?: string[]) => {
    // Removes changesSince grouping from array when we change from "Changes since" to "Group by".
    const withoutChanges = pageState.groupByV2.filter((x) => x.attributeName !== 'changesSince');
    const newGroupByV2State = addToGroupByV2(withoutChanges, value);
    setPageState({
      ...setBlankIfOpposite(KEYS.groupByV2, 'changesSince'),
      ...setBlankIfOpposite('changesSince', KEYS.groupByV2),
      [KEYS.groupByV2]: newGroupByV2State,
    });
  };

  return (
    <EuiFlexGroup
      gutterSize="s"
      alignItems="center"
      justifyContent="flexStart"
      style={{ marginBottom: '8px' }}
    >
      <EuiFlexItem grow={2}>
        <MultiSelect
          onChange={setSelectedProperties}
          placeholder="Search properties (Client ID, Building Name, Address)"
          onSearchChange={debounce(searchProperties)}
          async
          options={propertyOptions}
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <Distiller
          distiller={groupByDistiller}
          handleChange={onGroupByChange}
          pageState={pageState}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div>
          <FilterButton
            filterQuery={filters}
            handleChange={handleFilterQueryChange}
            filterable={filterable}
          />
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div>
          <EuiButtonIcon
            color={'primary'}
            iconType={() => <Icon name={'userPlus'} size={'m'} />}
            onClick={() => showModal(<InviteStreamModal stream={stream} />)}
            size={'m'}
            style={{
              backgroundColor: 'rgb(233, 237, 243)',
            }}
          />
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
