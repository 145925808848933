import { Children, FC, ReactElement } from 'react';
import { useApolloClient } from '@apollo/client';
import { ExportCode } from '@onarchipelago/cx/Stream/types';
import { usePageState } from '@onarchipelago/cx/Stream/utils';
import { useToast } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import useLogger from '@app/hooks/useLogger';
import { IGraphQLStream } from '@app/queries/streams/types';
import {
  airExportHandler,
  archipelagoExportClickHandler,
  exportClickHandler,
  rmsExportHandler,
} from '@app/utils/export';
import { useStreamContext } from '../Stream/StreamProvider';
import { getCodingData, IExportedCoding } from './utils';

type HandleClick = (exportedCoding: IExportedCoding, isNewSchema?: boolean) => Promise<void>;
type Children = (params: { handleClick: HandleClick }) => ReactElement | Array<ReactElement>;
interface IProps {
  streamSlug: IGraphQLStream['slug'];
  streamId: IGraphQLStream['id'];
  children: Children;
  useDefaultSnapshot?: boolean;
}

const getErrorMessage = (e: any): string => {
  const defaultMsg = 'Error occured when exporting.';

  if (!(e instanceof Error)) {
    return defaultMsg;
  }

  const err = e as Error;
  return err.message || defaultMsg;
};

const DataExports: FC<IProps> = ({
  streamSlug,
  streamId,
  useDefaultSnapshot = false,
  children,
}) => {
  const { getIdToken } = useAuth();
  const [{ currentSnapshot: selectedSnapshot }] = usePageState();
  const { stream } = useStreamContext();
  const apolloClient = useApolloClient();
  const toast = useToast();
  const currentSnapshot = useDefaultSnapshot ? stream?.defaultSnapshot : selectedSnapshot;
  const logger = useLogger();

  const errHandler = (e: any) => {
    if (!e) {
      return;
    }

    toast({ title: getErrorMessage(e), type: 'danger' });
    logger.error('Error occured when exporting.', { e });
  };

  const handleClick = async (exportedCoding: IExportedCoding) => {
    const token = getIdToken ? getIdToken() : '';

    if (exportedCoding.exportCode === ExportCode.ARCHIPELAGO) {
      await archipelagoExportClickHandler({
        client: apolloClient,
        currentSnapshot,
        enabledFeatures: stream.enabledFeatures,
        exportedCoding: getCodingData(ExportCode.ARCHIPELAGO),
        orgName: stream.orgName,
        streamSlug,
      })
        .then((downloadUrl) => {
          window.location.href = downloadUrl;
        })
        .catch(errHandler);
    } else if (exportedCoding.exportCode === ExportCode.AIR) {
      await airExportHandler(
        stream.slug,
        currentSnapshot, // snapshotTo
        stream.enabledFeatures,
        stream.orgName,
        apolloClient,
      )
        .then((downloadUrl) => {
          window.location.href = downloadUrl;
        })
        .catch(errHandler);
    } else if (exportedCoding.exportCode === ExportCode.RMS) {
      await rmsExportHandler(
        stream.slug,
        currentSnapshot, // snapshotTo
        stream.enabledFeatures,
        stream.orgName,
        apolloClient,
      )
        .then((downloadUrl) => {
          window.location.href = downloadUrl;
        })
        .catch(errHandler);
    } else {
      await exportClickHandler(streamId, currentSnapshot, exportedCoding, token);
    }
  };

  return children({ handleClick }) as ReactElement;
};

export default DataExports;
