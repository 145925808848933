import { useExportPropertyRecommendationsLazyQuery } from '@app/graphql/mutations/propertyRecommendations/__generated__/exportRecommendations.generated';
import { useImportPropertyRecommendationsMutation } from '@app/graphql/mutations/propertyRecommendations/__generated__/importRecommendations.generated';
import { usePrecheckClient } from '../hooks/usePrecheckClient';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import React, { useState } from 'react';
import {
  Button,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
  useGeneratedHtmlId,
  useToast,
} from 'ui';

export const ImportExportRecommendations = ({ snapshots, stream }) => {
  if (!snapshots) {
    return null;
  }

  const [files, setFiles] = useState({});

  const client = usePrecheckClient();

  const filePickerId = useGeneratedHtmlId({ prefix: 'filePicker' });

  const toast = useToast();

  const onChange = (newFiles) => {
    setFiles(newFiles.length > 0 ? Array.from(newFiles) : []);
  };

  const availableSnapshots = snapshots?.snapshotConfig?.snapshotsInfo.filter(
    (info) => info.propertyRecommendationStatus?.mlRecommendationsStatus === 'READY',
  );

  const [value, setValue] = useState(availableSnapshots[0]?.snapshot?.id);

  const [exportQuery, { loading }] = useExportPropertyRecommendationsLazyQuery({
    client,
    onCompleted: (data) => (window.location.href = data.exportPropertyRecommendations.downloadURL),
  });

  const [importMutation, { loading: importLoading }] = useImportPropertyRecommendationsMutation({
    client,
    onCompleted: () => {
      toast({ title: 'Import successful' });
    },
    onError: (err) => {
      toast({ title: getErrorMessage(err), type: 'danger' });
    },
  });

  const handleClick = () => {
    const availableSnap = availableSnapshots.find((snap) => snap.snapshot.id === value);
    exportQuery({
      variables: {
        input: {
          snapshotName: availableSnap?.snapshot?.name || '',
          streamSlug: stream.slug,
        },
      },
    });
  };

  const handleImportClick = () => {
    importMutation({
      variables: {
        input: {
          document: files[0],
          streamSlug: stream.slug,
        },
      },
    });
  };

  return (
    <div>
      <EuiTitle size="xs">
        <h2>Export Recommendations</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <Button loading={loading} label="Export recommendations" onClick={handleClick} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <EuiSpacer size="l" />
      <EuiSpacer size="l" />
      <EuiTitle size="xs">
        <h2>Import Recommendations</h2>
      </EuiTitle>
      <div>
        <EuiFilePicker
          id={filePickerId}
          multiple
          initialPromptText="Select or drag and drop multiple files"
          onChange={onChange}
          display="large"
          aria-label="Use aria labels when no actual label is in use"
        />
      </div>
      <EuiSpacer size="l" />
      <Button
        disabled={Object.keys(files).length === 0}
        loading={importLoading}
        label="Import recommendations"
        onClick={handleImportClick}
      />
    </div>
  );
};
