import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiPageHeader, EuiPanel } from 'ui';
import { StringParam, useQueryParam } from 'use-query-params';
import Flyout from '@app/components/Flyout/Flyout';
import { Helmet } from '@app/components/Helmet';
import { PRECHECK_LABEL } from '@app/components/Layout/SideNav/constants';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SIMPLIFIED_SOV } from '@app/platform/SystemSettings/Flags/types';
import { WorkflowProvider } from '@app/platform/Workflow/context/WorkflowContext';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DataInsightPanel } from '../Components/DataInsightPanel';
import { Recommendations } from '../Components/Recommendations/Recommendations';
import { ShareReport } from '../Components/ShareReport';
import { PrecheckProvider, usePrecheckContext } from '../context/PrecheckContext';

interface StyleProps {
  compact?: boolean;
}

const Container = styled.div<StyleProps>(({ compact }) =>
  compact
    ? `
  margin: 24px auto 24px auto;
  max-width: 1232px;
  width: 75%;
  @media (max-width: 900px) {
    width: 100%;
  }
`
    : '',
);

const StyledPanel = styled(EuiPanel)`
  border-radius: unset;
  border: none;
  box-shadow: none;
  max-width: 1232px;
  width: 75%;
  margin: auto;
  padding: 16px 0 16px 0 !important;
`;

const StyledOrg = styled(EuiPageHeader)`
  h1 {
    font-size: 1.375rem;
  }
  @media only screen and (max-width: 900px) {
    h1 {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

const DashboardWithoutContext = () => {
  if ((window as any)?.zE) {
    (window as any)?.zE('webWidget', 'hide');
  }
  const { selectedOrganization } = useUserSession();
  const { stream } = usePrecheckContext();

  if (!selectedOrganization) {return <></>;}

  const [referrer, setReferrer] = useQueryParam('referrer', StringParam);

  const isSimplifiedSOVEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SIMPLIFIED_SOV,
  );

  const { precheckUser, canSharePreCheck } = usePrecheckContext();

  const mixpanel = useTracker('mixpanel');

  useEffect(() => {
    mixpanel.track('Pre-Check: Page Viewed', {
      event_surface: 'PreCheck',
      organization_id: selectedOrganization.id,
      precheck_user_type: precheckUser.type,
      referrer,
      stream_id: stream.id,
      stream_slug: stream.slug,
    });
  }, []);

  if (!isSimplifiedSOVEnabled) {
    return (
      <Flyout>
        <div style={{ background: 'white', border: '1px solid rgb(224, 230, 235)' }}>
          <StyledPanel>
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem>
                <StyledOrg pageTitle={selectedOrganization.name} />
              </EuiFlexItem>
              {canSharePreCheck && (
                <EuiFlexItem grow={false}>
                  <ShareReport />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </StyledPanel>
        </div>
        <Container compact>
          <DataInsightPanel />
          <Recommendations />
        </Container>
      </Flyout>
    );
  }

  return (
    <Flyout>
      <EuiPanel paddingSize="l" hasBorder={false} hasShadow={false}>
        <Helmet title={PRECHECK_LABEL} />
        <EuiPageHeader
          pageTitle={PRECHECK_LABEL}
          rightSideItems={[
            canSharePreCheck && (
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem data-testid="share-precheck-btn" grow={false}>
                  <ShareReport />
                </EuiFlexItem>
              </EuiFlexGroup>
            ),
          ]}
        />
        <Container>
          <DataInsightPanel />
          <Recommendations />
        </Container>
      </EuiPanel>
    </Flyout>
  );
};

export const PrecheckReport = () => (
  <PrecheckProvider>
    <WorkflowProvider>
      <DashboardWithoutContext />
    </WorkflowProvider>
  </PrecheckProvider>
);
