import React, { FC } from 'react';
import { Spacer } from 'ui';
import ChartTitle from '../Charts/ChartTitle/ChartTitle';
import ChartAxisLabel from '../Charts/Components/ChartAxisLabel';
import AttributeBarSmall from './AttributeBarSmall/AttributeBarSmall';
import { DataQualityAttribute } from './types';

interface Props {
  title: string;
  attributes: DataQualityAttribute[];
  size?: number;
  axisLabel: string;
  currency?: string;
}

const AttributeSection: FC<Props> = ({ title, attributes, size, axisLabel, currency }) => {
  const sliced = attributes.slice(0, size);

  return (
    <>
      <ChartTitle>{title}</ChartTitle>
      <Spacer size="s" />
      {sliced.map((item) => (
        <AttributeBarSmall
          key={item.label}
          title={item.label}
          scores={item.scores}
          currency={currency}
        />
      ))}
      <Spacer size="s" />
      <ChartAxisLabel>{axisLabel}</ChartAxisLabel>
    </>
  );
};
export default AttributeSection;
