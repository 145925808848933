import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import QualityProgressBar from '../QualityProgressBar';
import { AttributeScore } from '../types';
import Title from './Title';

interface Props {
  title: string;
  scores: AttributeScore[];
  currency?: string;
}

const AttributeBarSmall: FC<Props> = ({ title, scores, currency }) => (
  <EuiFlexGroup direction="column" gutterSize="xs">
    <EuiFlexItem grow={false}>
      <Title title={title} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <QualityProgressBar scores={scores} size="s" currency={currency} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default AttributeBarSmall;
