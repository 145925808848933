import React, { useContext, useEffect, useState } from 'react';
import createAuth0Client, {
  Auth0Client,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import axios from 'axios';
import { useToast } from 'ui';
import { API_BASE_URL, AUTH0 } from '@app/config';
import { getExpiration, getIdToken, setExpiration, setIdToken } from '@app/containers/Auth/idToken';
import { initMixpanel } from '@app/containers/Auth/utils';
import { IAccountState, UserSystemInfo } from '@app/containers/AuthProvider/types';
import { useTracker } from '@app/hooks/useTracker';
import { IS_LOGGED_IN_LOCAL_STORAGE_KEY } from '../Auth/constants';
import { makeAccountOrgs, makeIAccountState } from './helper';

export interface IAuth0Context {
  isAuthenticated?: boolean;
  account?: IAccountState;
  loading?: any;
  error?: any;
  isExpired?: () => boolean;
  loginWithRedirect?: (o: RedirectLoginOptions) => void;
  logout?: (o: LogoutOptions) => void;
  getIdToken?: () => string;
  checkSession?: (silent?: boolean) => void;
  initAuth0?: () => void;
  clearAuth0?: () => void;
  refresh?: () => void;
}

export const Auth0Context = React.createContext<IAuth0Context>({});
export const useAuth = () => useContext(Auth0Context);

interface IProps {
  children: JSX.Element | string | null | undefined | boolean;
  onRedirectCallback: (appState: any) => void;
}

interface IStateProps {
  isAuthenticated: boolean;
  loading: boolean;
  account: IAccountState | undefined;
  error: any;
}

export const AuthProvider: React.FC<IProps> = ({ children, onRedirectCallback }) => {
  const mixpanel = useTracker('mixpanel');
  const toast = useToast();

  const [data, setData] = useState<IStateProps>({
    account: undefined,
    error: undefined,
    isAuthenticated: false,
    loading: true,
  });

  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>(undefined);

  // TODO - refactor this out and only use the method in idToken.ts
  const isExpired = (): boolean =>
    getExpiration() < 0 || getExpiration() - new Date().getTime() < 0;

  const getCurrentUser = async (): Promise<UserSystemInfo> =>
    axios({
      data: {
        query: `query currentUser {
                    currentUser {
                      userId
                      emailVerified
                      chatUserID
                      chatToken
                      viewedHighlights { 
                        slug
                        label
                      }
                      intent
                      createdAt

                      organization {
                        id
                        name
                        enableUploadPortal
                      }

                      managedOrgs {
                        org {
                          name
                          id
                          enableUploadPortal
                          enabledFeatures
                          enableRedirectOnSOVUpload
                          enableUnderwriterEdit
                          enableSubmissionDashboard
                          orgLevel
                          enableSOVManager
                          enablePreCheck
                        }
                        editProperties
                        editDocuments
                        editSubmissions
                        manageUsers
                        viewAccounts
                        editProjects
                        hasStreamInvites
                        viewRAPIDExport
                        importCarrierMetrics
                      }

                      profile {
                        userCode
                        email
                        pictureURL
                        title
                        phone
                        givenName
                        familyName
                      }

                      globalPermissions {
                        admin
                        myJobs
                        viewOnly
                      }
                    }
                  }`,
      },
      method: 'POST',
      url: `${API_BASE_URL}/graphql/query`,
    })
      .then((result) => result?.data?.data?.currentUser)
      .catch((error) =>
        toast({ title: `Could not load user profile: ${error?.message}`, type: 'danger' }),
      );

  const addViewedHighlight = (streamSlug: string, label: string) => {
    if (streamSlug) {
      setData((prev: IStateProps) => {
        const newData = prev;
        const viewed = newData.account?.viewedHighlights[streamSlug];
        if (viewed) {
          viewed.push(label || '');
        } else if (newData?.account?.viewedHighlights) {
          newData.account.viewedHighlights[streamSlug] = [label || ''];
        }
        return newData;
      });
    }
  };

  // isCallback returns whether or not this is the callback url
  const isCallback = (): boolean => window.location.href.endsWith('/callback');

  const initAuth0 = async () => {
    const options = AUTH0(window.location.origin);
    let auth0FromHook;
    try {
      auth0FromHook = await createAuth0Client(options);
    } catch (e) {
      console.error(e);
      // Scenario: refresh_token invalid or expired.
      // Shameless copy from: https://github.com/auth0/auth0-spa-js/issues/449#issuecomment-624192153
      // Something has gone wrong when the SDK has attempted to create an
      // Auth0 client and have it set up the correct authentication status for
      // the user. In this bad state, there's not much we can do but force a
      // log out on the user so that they can log in again.
      auth0FromHook = new Auth0Client(options);
      auth0FromHook.logout({
        returnTo: window.location.origin,
      });
      return;
    }
    setAuth0(auth0FromHook);

    try {
      if (
        (window.location.search.includes('code=') && window.location.search.includes('state=')) ||
        window.location.search.includes('error=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);

        // if error then don't bother continueing the codeflow.
        if (window.location.search.includes('error=')) {
          return;
        }
      }
    } catch (e) {
      // ignoreErrors contains a list of errors that should not be logged - but should
      // be shown to the user.
      const ignoreErrors = [
        // Error when user does not accept Terms and Conditions. Error is set by Auth0 Rules.
        'user did not consent',
      ];

      // This sets the error property for all the users of `useAuth`.
      setData({
        ...data,
        error: e,
      });
      return;
    }

    const authenticated = await auth0FromHook.isAuthenticated();
    let exp = 0;
    let acc: IAccountState | undefined;
    if (authenticated) {
      localStorage.setItem(IS_LOGGED_IN_LOCAL_STORAGE_KEY, 'true');
      const authUser = await auth0FromHook.getUser();
      const claims = await auth0FromHook.getIdTokenClaims();
      // eslint-disable-next-line
      if (claims?.__raw) {
        const token = claims.__raw; // eslint-disable-line no-underscore-dangle
        const currentUser = await getCurrentUser();

        acc = makeIAccountState(authUser, currentUser);
        acc.addViewedHighlight = addViewedHighlight;
        // To track the user login we need a way to identify what a log in is.
        // If the user goes through /callback then we are 99.99% sure that he got redirected from
        // the Universal Login and hence performed a successful login
        const trackLogin = isCallback();
        await initMixpanel(mixpanel, acc, trackLogin);
        exp = claims.exp ? claims.exp * 1000 : 0;

        setIdToken(token);
        setExpiration(exp);
      }
    }
    setData({
      ...data,
      account: acc,
      isAuthenticated: authenticated,
      loading: false,
    });
  };

  const refresh = async () => {
    const currentUser = await getCurrentUser();

    setData({
      ...data,
      account: {
        ...data.account,
        managedOrgs: currentUser?.managedOrgs,
        orgs: makeAccountOrgs(currentUser),
        submissionCreateOrgs: data.account.submissionCreateOrgs,
      },
    });
  };

  const checkSession = async (silent: boolean = false) => {
    if (auth0Client) {
      if (!silent) {
        setData({
          ...data,
          loading: true,
        });
      }

      return auth0Client
        .checkSession({ ignoreCache: silent }) // On silent requests always requery Auth0
        .then(() => auth0Client.getIdTokenClaims())
        .then((claims: any) => {
          // eslint-disable-next-line
          if (claims?.__raw) {
            setIdToken(claims.__raw); // eslint-disable-line no-underscore-dangle
            setExpiration(claims.exp ? claims.exp * 1000 : 0);
            if (!silent) {
              setData({
                ...data,
                isAuthenticated: true,
                loading: false,
              });
            }
          }

          return getIdToken();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    // If no auth0Client
    return undefined;
  };

  const clearAuth0 = () => {
    const auth0Key = Object.keys(localStorage).find((key) => key.includes('@@auth0spajs@@')) || '';
    localStorage.removeItem(auth0Key);
  };

  // Included here because it is the highest part of the react tree with authenticated calls
  // This sets the global request headers for all axios calls to ensure they have a current token
  axios.interceptors.request.use(async (request) => {
    const token = isExpired() ? await checkSession?.(true) : getIdToken();
    request.headers.authorization = `Bearer ${token}`;

    return request;
  });

  // Runs once at initialization
  useEffect(() => {
    initAuth0();
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        account: data.account,
        checkSession,
        clearAuth0,
        error: data.error,
        getIdToken,
        initAuth0,
        isAuthenticated: data.isAuthenticated,
        isExpired,
        loading: data.loading,
        loginWithRedirect: (o: RedirectLoginOptions) =>
          auth0Client ? auth0Client.loginWithRedirect(o) : undefined,
        logout: (o: LogoutOptions) => (auth0Client ? auth0Client.logout(o) : undefined),
        refresh,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
