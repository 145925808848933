import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { ButtonIcon, ContextMenu } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { integrationsApolloClient } from '@app/cx/Organizations/Tabs/IntegrationsTab/IntegrationsApolloClient';
import useEditPropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useEditPropertiesPanel';
import useLossDataPanel from '@app/cx/Stream/ManageStreamButtonPanels/useLossDataPanel';
import { useManageExportsPanel } from '@app/cx/Stream/ManageStreamButtonPanels/useManageExportsPanel';
import useMarketAccessPanel from '@app/cx/Stream/ManageStreamButtonPanels/useMarketAccessPanel';
import useRestorePropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestorePropertiesPanel';
import useRunIntegrationsPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRunIntegrationsPanel';
import useStreamSettingsPanel from '@app/cx/Stream/ManageStreamButtonPanels/useStreamSettingsPanel';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { ReportCode } from '@app/cx/Stream/types';
import { E2VALUE } from '@app/platform/Integrations/consts';
import {
  FEATURE_TYPE_AIRTRAFFICCONTROL,
  FEATURE_TYPE_CONFIGURABLE_EXPORTS,
  FEATURE_TYPE_SELF_SERVICE_SNAPSHOTS,
} from '@app/platform/SystemSettings/Flags/types';
//@ts-ignore
import GET_USAGE from '@app/queries/integrations/getUsage.gql';
import { IUsageData, IUsageVariables } from '@app/queries/integrations/types';
import { EJobType } from '@app/queries/organizations/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { usePropertiesPageContext } from '../PropertiesPage.context';


export const OtherSettingsButton = () => {
  const { stream } = usePropertiesPageContext();
  const { permissions, marketVisibleToggle, lock, refetch } = useStreamContext();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { selectedOrganization } = useUserSession();
  const [open, setOpen] = useState(false);
  const { search } = useLocation();

  const history = useHistory();


  const { data } = useQuery<IUsageData, IUsageVariables>(GET_USAGE, {
    client: integrationsApolloClient,
    variables: {
      input: {
        organizationID: stream?.organizationId,
        partnerName: E2VALUE,
      },
    },
  });

  const inProgressJobs: Partial<Record<EJobType, Lock>> = {};
  (lock?.locks || []).forEach((jobInfo) => {
    if (!!inProgressJobs[jobInfo.type] || !jobInfo.canReuse) {
      return;
    }
    // FIX ME
    // @ts-ignore
    inProgressJobs[jobInfo.type] = jobInfo;
  });
  const EditPropertiesPanel = useEditPropertiesPanel({
    // FIX ME
    // @ts-ignore
    inProgressJobs,
    refetch,
    setOpen,
  });

  // get org level permissions (risk manager)
  const orgPermissions = getOrgPermissions(account, stream?.organizationId);

  const canConfigureExports =
    isFeatureEnabled(stream?.enabledFeatures, FEATURE_TYPE_CONFIGURABLE_EXPORTS) &&
    orgPermissions.includes('canManageProperties') &&
    marketVisibleToggle !== true;

  const isAirTrafficControlEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_AIRTRAFFICCONTROL,
  );

  const isSelfServiceEnabked = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_SELF_SERVICE_SNAPSHOTS,
  );

  const manageExportsPanel = useManageExportsPanel({ setOpen });
  const StreamSettingsPanel = useStreamSettingsPanel();
  const marketAccessPanel = useMarketAccessPanel();
  // FIX ME
  // @ts-ignore
  const lossDataPanel = useLossDataPanel({ inProgressJobs, setOpen });
  const items = [];

  if (
    !stream?.isMyProperties &&
    ((isAirTrafficControlEnabled && isAdmin) ||
      permissions?.canManageUserManagers ||
      permissions?.canManageViewers)
  ) {
    items.push(marketAccessPanel);
  }
  if (isAdmin || (permissions.canManageSnapshots && isSelfServiceEnabked && !marketVisibleToggle)) {
    items.push(StreamSettingsPanel);
  }
  if (canConfigureExports) {items.push(manageExportsPanel);}

  if (permissions.canEditProperties) {
    items.push(EditPropertiesPanel);
  }

  if (permissions.canDeleteProperties || isAdmin) {
    const RestorePropertiesPanel = useRestorePropertiesPanel({ setOpen });
    items.push(RestorePropertiesPanel);
  }

  if (permissions.canAddLosses) {
    items.push(lossDataPanel);
  }

  const RunIntegrationsPanel = useRunIntegrationsPanel();

  if (isAdmin && data?.usage?.enabled) {
    items.push(RunIntegrationsPanel);
  }

  if (permissions?.canViewReports && (stream?.allowedReports as string[])?.includes('DQD' as ReportCode)) {
    items.push({
      'data-testid': 'readiness-report-button',
      label: 'Readiness Report',
      onClick: () => {
        history.push({
          pathname: encodeUrl`/reports/${stream?.slug}/readiness`,
          search,
        });
      },
      ...{
        toolTipContent: 'Overall health of this portfolio’s property data',
        toolTipPosition: 'right',
      },
    });
  }

  if(permissions?.canViewReports  && (stream?.allowedReports as string[])?.includes('ValuationSummary')) {
    items.push({
      'data-testid': 'valuations-report-button',
      label: 'Valuation Summary',
      onClick: () => {
        history.push({
          pathname: encodeUrl`/reports/${stream?.slug}/valuations`,
          search,
        });
      },
      ...{
        toolTipContent: 'How this portfolio’s building values compare to market expectations',
        toolTipPosition: 'right',
      },
    });
  }

  const button = (
    <ButtonIcon
      iconName="moreHorizontal"
      size="l"
      border
      color="text"
      onClick={() => setOpen(!open)}
    />
  );

  if (items.length < 1) {
    return null;
  }
  return (
    <ContextMenu button={button} panel={{ items }} onClose={() => setOpen(false)} open={open} />
  );
};
