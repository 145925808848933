import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useToast } from 'ui';
import { useRedirectURL } from '@app/containers/Auth/RedirectURL';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
// FIX ME
// @ts-ignore
import CONVERT_USER from '@app/queries/user/convertUser.gql';
import { IConvertUserData, IConvertUserVariables } from '@app/queries/user/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import StreamMessagePage from '../StreamMessagePage';

const PasswordAccountRequired: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { account, logout } = useAuth();
  const toast = useToast();

  const [convertUserMutation, convertUserResult] = useMutation<
    IConvertUserData,
    IConvertUserVariables
  >(CONVERT_USER, {
    onCompleted: (data) => {
      if (data?.convertUser?.passwordResetLink) {
        if (logout) {
          localStorage.removeItem('browserCompatibilityWarningAccepted');

          // This should make sure that the users get redirected to the stream they tried to access.
          if (useRedirectURL.isPathAllowed()) {
            useRedirectURL.set(window.location.href);
          }

          logout({
            returnTo: data.convertUser.passwordResetLink,
          });
        }
      }
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const onConvertUserHandler = () => {
    setIsLoading(true);
    convertUserMutation({
      variables: {
        input: {
          desiredConnection: 'PASSWORD_WITH_MFA',
          email: account.email,
        },
      },
    });
  };

  return (
    <StreamMessagePage
      buttonLabel="Set password"
      buttonLoading={isLoading}
      handleClick={onConvertUserHandler}
      subTitle={`
        Due to security requirements, you must add a password and multi-factor authentication to your account to view
        this Stream. Click the button below to get started.
      `}
      title="You need to set a password to access this stream."
    />
  );
};

export default PasswordAccountRequired;
