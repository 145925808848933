import styled from '@emotion/styled';
import { EuiFlexGroup } from 'ui';

export const Divider = styled.div`
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  border-top: 1px solid #e0e6eb;
`;

export const ContentContainer = styled(EuiFlexGroup)`
  height: 90vh;
  overflow-y: scroll;
  margin: 0px;
`;

export const ReportsContainer = styled(EuiFlexGroup)`
  margin: 0px auto;
  padding: 0px 32px;
  height: 100%;
  // Desired panel width is 1164 + 32 padding each side + 12 each side to offset flexgroup margins
  width: 1252px;
`;

export const Column = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
`;
