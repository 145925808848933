import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { IMarker } from '@app/components/GoogleMap/types';
import PropertyImg from '@app/components/PropertyImg/PropertyImg';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { IPropertyWithStream, SizeBy } from './types';
import { getMarkerData } from './utils';
import ValueMarker from './ValueMarker';

const getStyle = (imgCount: number) => {
  const size = imgCount > 0 ? 36 : 0;
  const display = imgCount > 0 ? 'flex' : 'none';

  return {
    display,
    height: size,
    width: size,
  };
};

interface IProps {
  sizeBy: SizeBy;
  streamSlug: string;
  marker: IMarker<IPropertyWithStream>;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
}

const Tooltip = styled.div<{
  hovering?: boolean;
}>`
  label: Tooltip;

  ${({ hovering }) =>
    hovering
      ? `
    opacity: 1;
    display: block;
  `
      : `
    opacity: 0;
    display: none;
  `}
  position: absolute;
  bottom: -30px;
  left: 0;
  min-height: 24px;
  width: 128px;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(52, 55, 65, 1);
  font-size: 8px;
  text-align: center;
  padding: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 6px;
  z-index: 2;
`;

const Container = styled.div`
  label: PropertyMarker;
  position: relative;
  cursor: pointer;
  transition-duration: 0.4s;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate(-50%, -50%);
`;

const ImgContainer = styled.div`
  label: PropertyMarkerImg;
  cursor: pointer;
  transition-duration: 0.4s;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background: white;
  border: 1px solid white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const PropertyMarker: FC<IProps> = ({ sizeBy, marker, propertyAttributeMetadata, streamSlug }) => {
  const property = marker.attribute;
  const { metadataMap } = useStreamContext();
  const [hovering, setHovering] = useState(false);

  if (!property) {
    // TODO: This is a Typescript bug; it's not able to infer
    // that property _must_ be set
    throw new Error('property for marker has not been specified');
  }

  const { tooltipMeta, value } = getMarkerData(sizeBy, property, metadataMap);

  return (
    <>
      <Container onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        <Link
          to={{
            pathname: `/streams/${streamSlug}/properties/${property?.id}`,
            search: window.location.search,
            state: { propertyAttributeMetadata },
          }}
        >
          <ValueMarker sizeBy={sizeBy} value={value} />
          <ImgContainer style={getStyle(property.pictures?.length)}>
            <PropertyImg
              locationName={property.locationName}
              pictures={property.pictures}
              width={36}
              height={36}
            />
          </ImgContainer>
        </Link>
        <Tooltip hovering={hovering}>
          <div>{property.locationName}</div>
          {(tooltipMeta || []).map(({ label, value }) => (
            <div>
              {label}:{value}
            </div>
          ))}
        </Tooltip>
      </Container>
    </>
  );
};

export default PropertyMarker;
