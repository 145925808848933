import React, { FC,useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import moment from 'moment';
import {
  Button,
  EuiBadge,
  EuiLink,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPageHeader,
  EuiSpacer,
  EuiSteps,
  EuiToolTip,
  Icon,
  useEuiTheme,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import {
  ChildrenContainer,
  Modal,
} from '@app/components/ModalWithButtons/ModalWithButtons.emotion';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_TYPE_ACTIVITY_LOG } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';

const StyledSteps = styled(EuiSteps)`
  .euiLink {
    text-decoration: underline !important;
  }

  .euiTitle {
    font-size: 0.9rem;
  }

  .euiStep__content {
    padding-block-start: 0 !important;
    padding-block-end: 16px !important;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px;
  marginn-left: 4px;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  clear: both;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const StyledText = styled.span`
font-weight: 500;

font-size: 0.8750rem;

}
`;

const ORG_ACTIVITES = gql`
  query organizationActivities($input: OrganizationActivitiesInput!) {
    organizationActivities(input: $input) {
      activities {
        organizationID
        streamSlugRandomIdentifier
        eventID
        eventName
        userID
        email
        name
        timestamp
        propertiesCount
        eventDetails
        properties {
          locationName
          streetAddress
          city
          state
          id
        }
        attributes {
          displayName
        }
      }
      cursor
    }
  }
`;

interface IExtraAttributeDisplayProps {
  attributes?: [{ displayName: string }];
  propertyCount?: number;
  properties?: [{ locationName: string }];
}

interface ISteps {
  title: string;
  status: 'warning' | 'complete';
  children: JSX.Element;
}

const ExtraAttributeDisplay: FC<IExtraAttributeDisplayProps> = ({
  attributes = [],
  propertyCount,
  properties,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { euiTheme } = useEuiTheme();

  const count = properties ? propertyCount : attributes.length;
  const labels = properties
    ? properties.map((property) => property.locationName)
    : attributes.map((attribute) => attribute.displayName);

  if (propertyCount > 5) {
    return <>{propertyCount}</>;
  }

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
        style={{ color: euiTheme.colors.primary }}
      >
        {count}
      </a>
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle />
          </EuiModalHeader>
          <EuiModalBody>
            <ChildrenContainer>
              <div>
                {labels.map((label) => (
                  <EuiBadge style={{ color: 'black', margin: '3px' }} color="lightgrey">
                    <span style={{ marginRight: '8px' }}>{label}</span>
                  </EuiBadge>
                ))}
              </div>
            </ChildrenContainer>
          </EuiModalBody>
          <EuiModalFooter>
            <Button
              type="button"
              size="m"
              fill
              label="Close"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </EuiModalFooter>
        </Modal>
      )}
    </>
  );
};

export const List = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties');
  const [items, setItems] = useState([]);
  const { euiTheme } = useEuiTheme();

  const hasActivityLogEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_ACTIVITY_LOG,
  );

  if (!canManageProperties || !hasActivityLogEnabled) {return <></>;}

  const { data, loading, refetch, networkStatus } = useQuery(ORG_ACTIVITES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItems((prevState) => [...prevState, ...data?.organizationActivities?.activities]);
    },
    variables: {
      input: {
        cursor: null,
        limit: 25,
        organizationName: selectedOrganization.name,
      },
    },
  });

  const loadMore = async () => {
    await refetch({
      input: {
        cursor: data?.organizationActivities?.cursor,
        limit: 25,
        organizationName: selectedOrganization.name,
      },
    });
  };

  const handleScroll = async (e) => {
    const bottom =
      Math.round(e.target.scrollHeight - e.target.scrollTop) === Math.round(e.target.clientHeight);
    if (bottom) {
      await loadMore();
    }
  };

  const steps: ISteps[] = items
    .filter((item) => {
      if (item.eventName.includes('Edit Property') || item.eventName.includes('Edit Properties')) {
        return (
          item.attributes.length !== 0 && item.propertiesCount !== 0 && item.properties.length > 0
        );
      }
      return item.propertyCount !== 0;
    })
    .map((item) => {
      let title = item.eventName;
      const propertyCount = item.propertiesCount;
      const attributes = item.attributes;

      const pluralizedProperty = propertyCount > 1 ? 'properties' : 'property';
      const pluralizedAttributes = attributes.length > 1 ? ' attributes were' : ' attribute was';
      if (item.eventName.includes('Edit Property') || item.eventName.includes('Edit Properties')) {
        title = (
          <>
            {attributes.length > 3 ? (
              <>
                <ExtraAttributeDisplay attributes={attributes} />
                {pluralizedAttributes} edited on{' '}
              </>
            ) : (
              <>
                {attributes.map((attribute, index) => (
                  <StyledText>
                    {attribute.displayName}
                    {index === attributes.length - 1 ? null : ', '}
                  </StyledText>
                ))}{' '}
                was edited on{' '}
              </>
            )}
            {propertyCount.length > 3 ? (
              <>
                <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
                {pluralizedProperty}
              </>
            ) : (
              <>
                {item.properties.map((property, index) => (
                  <a
                    href={`/streams/${item.streamSlugRandomIdentifier}/property/${property.id}`}
                    style={{ color: euiTheme.colors.primary }}
                  >
                    {property.streetAddress}, {property.city}, {property.state}{' '}
                    {index === item.properties.length - 1 ? null : ', '}
                  </a>
                ))}
              </>
            )}
          </>
        );
      }

      if (item.eventName.includes('Add Properties')) {
        title = (
          <>
            <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
            {pluralizedProperty} were added
          </>
        );
      }

      if (item.eventName.includes('Dispose')) {
        title = (
          <>
            <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
            {pluralizedProperty} {propertyCount > 1 ? 'were' : 'was'} diposed
          </>
        );
      }

      if (item.eventName.includes('Delete')) {
        title = (
          <>
            <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
            {pluralizedProperty} {propertyCount > 1 ? 'were' : 'was'} deleted
          </>
        );
      }

      if (item.eventName.includes('Add Losses')) {
        title = (
          <>
            <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
            {pluralizedProperty} had losses added
          </>
        );
      }

      if (item.eventName.includes('Edit Losses')) {
        title = (
          <>
            <ExtraAttributeDisplay properties={item.properties} propertyCount={propertyCount} />{' '}
            {pluralizedProperty} had losses eddited
          </>
        );
      }

      return {
        children: (
          <div>
            <span>
              <EuiBadge color={item.color || 'default'}>
                {moment(item.timestamp).format('MMM Do')}
              </EuiBadge>{' '}
              <StyledSpan>{item.name}</StyledSpan>
            </span>
          </div>
        ),
        status: 'complete',
        title,
      };
    });

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <EuiSpacer size="xl" />
      <EuiPageHeader pageTitle="Activity Feed" />
      <EuiSpacer size="xl" />
      <div
        style={{ height: '50vh', overflow: 'auto', width: '50%' }}
        onScroll={async (e) => handleScroll(e)}
      >
        {items.length > 0 && <StyledSteps titleSize="xs" steps={steps} />}
      </div>
      <EuiSpacer size="xl" />
      {isLoading && (
        <div style={{ width: '300px' }}>
          <LoadingSpinnerV2 />
        </div>
      )}
    </>
  );
};
