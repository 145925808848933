export const STREAM_VIEWER_PERMISSIONS = {
  canAddLosses: false,
  canAddProperties: false,
  canChat: false,
  canDeleteProperties: false,
  canEditAllProperties: false,
  canEditLosses: false,
  canEditProperties: false,
  canExportLosses: true,
  canExportPolicies: false,
  canManageDomainAllowList: false,
  canManageUserManagers: false,
  canManageViewers: false,
  canSharePreCheck: true,
  canViewLosses: true,
};
