import { Dispatch, SetStateAction } from 'react';
import { FEATURE_TYPE_CONFIGURABLE_EXPORTS } from '@onarchipelago/platform/SystemSettings/Flags/types';
import { ContextMenuPanel } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { useStreamContext } from '../StreamProvider';
import { StreamPageOptions } from '../types';
import useEditStreamPanel from './useEditStreamPanel';
import useLossDataPanel from './useLossDataPanel';
import { useManageExportsPanel } from './useManageExportsPanel';
import useMarketAccessPanel from './useMarketAccessPanel';
import usePropertiesPanel from './usePropertiesPanel';
import useRunIntegrationsPanel from './useRunIntegrationsPanel';
import useStreamSettingsPanel from './useStreamSettingsPanel';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  options: StreamPageOptions;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export default ({ inProgressJobs, options, setOpen, refetch }: Props): ContextMenuPanel => {
  const { permissions, stream } = useStreamContext();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;

  // get org level permissions (risk manager)
  const orgPermissions = getOrgPermissions(account, stream?.organizationId);

  const canConfigureExports =
    isFeatureEnabled(stream?.enabledFeatures, FEATURE_TYPE_CONFIGURABLE_EXPORTS) &&
    orgPermissions.includes('canManageProperties');

  const hasEditPropertyPanels = permissions?.canEditProperties || permissions?.canAddProperties;
  const hasEditLossPanels = permissions?.canEditLosses || permissions?.canAddLosses;
  const hasEditDataPanels = hasEditPropertyPanels || hasEditLossPanels;

  const airTrafficControlEnabled = isFeatureEnabled(stream.enabledFeatures, 'AirTrafficControl');
  const canViewMarketAccessPanel =
    !!airTrafficControlEnabled &&
    !stream.isMyProperties &&
    (isAdmin || permissions?.canManageUserManagers || permissions?.canManageViewers);

  const manageExportsPanel = useManageExportsPanel({ setOpen });
  const EditStreamPanel = useEditStreamPanel();
  const LossDataPanel = useLossDataPanel({ inProgressJobs, refetch, setOpen });
  const RunIntegrationsPanel = useRunIntegrationsPanel();
  const MarketAccessPanel = useMarketAccessPanel();
  const PropertiesPanel = usePropertiesPanel({ inProgressJobs, refetch, setOpen });
  const StreamSettingsPanel = useStreamSettingsPanel();

  if (hasEditDataPanels) {
    const mainGroupItems = [PropertiesPanel];
    if (hasEditLossPanels) {
      mainGroupItems.push(LossDataPanel);
    }
    if (isAdmin && options.enabledRunIntegrationsBtn) {
      mainGroupItems.push(RunIntegrationsPanel);
    }
    if (canViewMarketAccessPanel) {
      mainGroupItems.push(MarketAccessPanel);
    }
    if (isAdmin || orgPermissions?.includes('canManageProperties')) {
      mainGroupItems.push(StreamSettingsPanel);
    }
    if (canConfigureExports) {
      mainGroupItems.push(manageExportsPanel);
    }

    return { items: mainGroupItems };
  }

  const editGroupItems = [];
  if (canViewMarketAccessPanel) {
    editGroupItems.push(MarketAccessPanel);
  }
  if (options.enableStreamEditBtn) {
    editGroupItems.push(EditStreamPanel);
  }

  return { items: editGroupItems };
};
