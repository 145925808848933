import React, { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from '@emotion/styled';
import { ButtonEmpty, ButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFlyoutHeader, EuiText } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { getGroupedList } from './utils';

export const Menu = styled.div`
  label: menu;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(13, 22, 38, 0.1) 0px 0px 0px 1px, rgba(13, 22, 38, 0.1) 0px 4px 11px;
  margin-top: 0px;
  margin-bottom: 24px;
  padding: 16px;
`;

function VirtualizedWrapper({
  items,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  RenderItem,
  GroupItem,
}: any) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items?.length + 1 : items?.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  // Render an item or a loading indicator.
  const Item = ({ index, style }: { index: number; style: any }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <LoadingSpinnerV2 />;
    } else {
      content = (
        // Since the sections aren't particularly gigantic, we can render the
        // entire list of children under each section at once
        <>
          <GroupItem item={items[index]} key={items[index].displayName} />
          {items[index].children.map((row: any) => (
            <>
              <RenderItem item={row} key={row.name} />
              {row?.children?.map((grandChildrenRow: any) => (
                <RenderItem item={grandChildrenRow} key={grandChildrenRow.name} />
              ))}
            </>
          ))}
        </>
      );
    }

    return <div style={style}>{content}</div>;
  };

  // The item size will be calculated based on how many children
  // and grand children plus the height of the Section component
  const itemSize = (index: number) => {
    const item = items[index];
    const childrenSize = item.children.length;
    let grandChildrenSize = 0;
    item.children?.forEach((childrenItem) => {
      if (childrenItem?.children?.length) {
        grandChildrenSize += childrenItem?.children?.length;
      }
    });
    const totalSize = childrenSize + grandChildrenSize;

    return totalSize * 40 + 40;
  };

  return (
    <div
      className="propFlyout"
      style={{ flex: '1 1 auto', height: '100%', margin: '12px 0px', position: 'relative' }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={15}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                height={height}
                width={width}
                itemSize={itemSize}
              >
                {Item}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}

const attributesToExclude = ['airModifiers', 'rmsModifiers', 'ownerAttributes', 'losses'];
interface IProps {
  handleSelectAll?: () => void;
  hide: any;
  list: Array<IGraphQLAttributeMetadata>;
  selectedItems: Array<IGraphQLAttributeMetadata>;
  rowComponent: any;
  groupRowComponent: any;
}

const SelectAttributesFlyout: FC<IProps> = ({
  handleSelectAll,
  hide,
  list = [],
  selectedItems,
  rowComponent,
  groupRowComponent,
}) => {
  const buildMetadataWithSections = () => {
    // Build a list of attributes, grouped by the parents
    const groupedList = getGroupedList(list);

    // Remove any parents that don't contain children as these won't have selections
    // eslint-disable-next-line
    for (const key in groupedList) {
      if (Object.prototype.hasOwnProperty.call(groupedList, key)) {
        if (groupedList[key].children.length === 0) {
          delete groupedList[key];
        }
      }
    }

    groupedList.forEach((parent) => {
      if (attributesToExclude.includes(parent.name)) {
        parent.children.shift();
      }
    });

    return groupedList;
  };

  const metadataGroupedByParent = buildMetadataWithSections();

  return (
    <>
      <EuiFlyoutHeader data-testid="select-attributes-flyout">
        <EuiFlexGroup justifyContent="flexStart">
          <EuiFlexItem grow={false} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <ButtonIcon
              data-testid="select-attributes-back"
              iconName="arrowLeft"
              onClick={hide}
              aria-label="Back"
              color="text"
              size="m"
            />
          </EuiFlexItem>
          <EuiFlexItem style={{ marginLeft: '0px' }}>
            <EuiText>
              <h2>Select Attributes</h2>
            </EuiText>
          </EuiFlexItem>
          {handleSelectAll && (
            <EuiFlexItem grow={false} style={{ marginLeft: '0px' }}>
              <ButtonEmpty
                data-testid="select-all"
                onClick={handleSelectAll}
                label={selectedItems.length > 0 ? 'Select None' : 'Select All'}
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <VirtualizedWrapper
        items={Object.values(metadataGroupedByParent)}
        hasNextPage={false}
        isNextPageLoading={false}
        loadNextPage={null}
        RenderItem={rowComponent}
        GroupItem={groupRowComponent}
        searchText={null}
        selectedItems={selectedItems}
      />
    </>
  );
};

export default SelectAttributesFlyout;
