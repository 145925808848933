import React, { FC } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import { useTracker } from '@app/hooks/useTracker';

const Filters: FC = () => {
  const mixpanel = useTracker('mixpanel');

  const {
    snapshots,
    stream: { streamId, streamName, streamSlug, ...streamRest },
  } = useReportsContext();
  const {
    currentHazard,
    currentSnapshot,
    hazardOptions,
    loading,
    setCurrentHazard,
    setCurrentSnapshot,
    snapshotOptions,
  } = snapshots;

  const onChangeCallback = () => {
    mixpanel.track('Open Report', {
      report_name: 'Readiness Report',
      snapshot: currentSnapshot?.snapshotName,
      stream_id: streamId,
      stream_name: streamName,
      stream_slug: streamSlug,
      ...streamRest,
      ...currentSnapshot,
    });
  };

  return (
    <EuiFlexGroup responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiText style={{ lineHeight: '40px' }}>View report for</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '160px' }}>
        <Select
          options={snapshotOptions}
          value={currentSnapshot?.snapshotName || ''}
          onChange={(value) => {
            setCurrentSnapshot(value);
            onChangeCallback();
          }}
          loading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '320px' }}>
        <Select
          options={
            hazardOptions.map((option) => ({ label: option.inputDisplay, value: option.value })) ||
            []
          }
          value={currentHazard}
          onChange={(value) => {
            // FIX ME
            // @ts-ignore
            setCurrentHazard(value);
            onChangeCallback();
          }}
          loading={loading}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Filters;
