import React, { FC, useContext } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { HazardExposureType } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import { EuiText, EuiTextColor, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../components/ErrorState';
import AttributeSection from './AttributeSection';
import { attributeMappings, attributeOrder } from './const';
import QualityScoreKey from './QualityScoreKey';
import { ReadinessReportContext } from './ReadinessReportContext';

const SecondaryAttributes: FC = () => {
  const {
    attributeScoresData: data,
    attributeScoresError: error,
    attributeScoresLoading: loading,
    earthquakeAttributes,
    windAttributes,
    floodAttributes,
    displayCurrency,
  } = useContext(ReadinessReportContext);
  const { currentHazard } = useReportsContext().snapshots;

  const getScoresByKey = (key: string, dataSource: any) => {
    const sortByOrder = (a: any, b: any) =>
      attributeOrder[key].indexOf(a.attributeName) - attributeOrder[key].indexOf(b.attributeName);
    return (dataSource || [])
      .filter((item: any) => Object.keys(attributeMappings[key]).includes(item.attributeName))
      .map((item: any) => ({
        ...item,
        label: attributeMappings[key][item.attributeName],
      }))
      .sort(sortByOrder);
  };

  const attributes = {
    EARTHQUAKE: getScoresByKey('EARTHQUAKE', earthquakeAttributes.data?.qualityAttribute),
    FIRE: getScoresByKey('FIRE', data),
    FLOOD: getScoresByKey('FLOOD', floodAttributes.data?.qualityAttribute),
    WIND: getScoresByKey('WIND', windAttributes.data?.qualityAttribute),
  };

  const show = (exp: HazardExposureType) => currentHazard === 'All' || currentHazard === exp;

  const title = (display: string) => `${show('All') ? 'Key' : ''} ${display} Attributes`;

  const isLoading =
    loading && earthquakeAttributes.loading && windAttributes.loading && floodAttributes.loading;
  const hasErrors =
    error && earthquakeAttributes.error && windAttributes.error && floodAttributes.error;

  return (
    <>
      {hasErrors && <ErrorState />}
      {isLoading && <LoadingSpinnerV2 />}
      {data && (
        <>
          <EuiText>
            <h3>Secondary Attributes Data Quality</h3>
          </EuiText>
          <EuiText size="s">
            <p>
              <EuiTextColor color="subdued">
                Secondary attributes &quot;flex&quot; the primary vulnerability curve up or down
                given significant factors for the resiliency of a building. In cat-exposed zones,
                secondary attributes can swing results by +/- 50%.
              </EuiTextColor>
            </p>
          </EuiText>
          <Spacer size="m" />
          <QualityScoreKey gutterSize="m" />
          {show('All') && (
            <>
              <Spacer size="l" />
              <AttributeSection
                title="Fire Attributes"
                attributes={attributes.FIRE}
                size={show('All') ? 5 : undefined}
                axisLabel="% of TIV"
                currency={displayCurrency}
              />
            </>
          )}
          {show('Earthquake') && (
            <>
              <Spacer size="l" />
              <AttributeSection
                title={title('Earthquake')}
                attributes={attributes.EARTHQUAKE}
                size={show('All') ? 5 : undefined}
                axisLabel="% of Earthquake Exposed TIV"
                currency={displayCurrency}
              />
            </>
          )}
          {show('Wind') && (
            <>
              <Spacer size="l" />
              <AttributeSection
                title={title('Wind')}
                attributes={attributes.WIND}
                size={show('All') ? 5 : undefined}
                axisLabel="% of Wind Exposed TIV"
                currency={displayCurrency}
              />
            </>
          )}
          {show('Flood') && (
            <>
              <Spacer size="l" />
              <AttributeSection
                title={title('Flood')}
                attributes={attributes.FLOOD}
                size={show('All') ? 5 : undefined}
                axisLabel="% of Flood Exposed TIV"
                currency={displayCurrency}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SecondaryAttributes;
