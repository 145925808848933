import gql from 'graphql-tag';

export const PROPERTY_FRAGMENT = gql`
  fragment property on Property {
    region
    archipelagoId
    acquiredOrBuilt

    buildingReplacementCost
    buildingReplacementCostDisplay
    buildingReplacementCostPartner
    buildingReplacementCostPercentDifference
    buildingReplacementCostPercentDifferenceRange
    buildingReplacementCostValueDifference

    buildingValue
    buildingValueDisplay
    businessIncomeValue
    businessIncomeValueDisplay
    businessInterruptionCost
    businessInterruptionCostDisplay

    annualBaseRent
    annualBaseRentDisplay
    annualEstimatedRent
    annualEstimatedRentDisplay
    annualServiceCharge
    annualServiceChargeDisplay
    annualGrossProfit
    annualGrossProfitDisplay
    annualLossOfRevenue
    annualLossOfRevenueDisplay
    rentAndServiceIndemnityPeriod

    constructionType
    constructionTypeATC
    constructionTypeISO
    constructionTypeRMS
    contentsReplacementCost
    contentsReplacementCostDisplay

    attributeEdits {
      attributeName
      author
    }
    attributeProvenance {
      attributeName
      sources
      sourceDocuments {
        document {
          id
          httpURL
          filename
          extension
          thumbnailURLs {
            original
          }
        }
      }
      externalSourceURLs
    }

    electronicsValue
    electronicsValueDisplay
    extraExpenseValue
    extraExpenseValueDisplay
    fineArtJewelryValue
    fineArtJewelryValueDisplay
    fireEstimatedMaximumLoss
    fireProbableMaximumLoss
    floorArea

    geoCodeType

    improvementsValue
    improvementsValueDisplay
    inventoryValue
    inventoryValueDisplay
    machineryValue
    machineryValueDisplay
    miscBuildingReplacementCostValue
    miscBuildingReplacementCostValueDisplay
    miscBusinessInterruptionValue
    miscBusinessInterruptionValueDisplay
    miscContentsValue
    miscContentsValueDisplay
    otherContentsValue
    otherContentsValueDisplay
    payrollValue
    payrollValueDisplay

    hazardSummary
    seismicZone
    floodZone
    windZone
    archipelagoFloodZone
    archipelagoSeismicZone
    archipelagoWindZone

    numberOfHighPriorityRecommendations
    id
    lastEngineeringVisitDate

    location
    locationId
    locationName
    city
    county
    country
    latitude
    longitude
    streetAddress
    state
    postalCode

    pictures {
      filename
      httpURLs {
        small
      }
    }

    lossEngineeringReportPresent
    propertyManager
    modelingAvailable

    numberOfBuildings
    numberOfStoriesAboveGround

    occupancyType
    occupancyTypeATC
    occupancyTypeSIC
    occupancyTypeNAICS
    multiTenant
    primaryTenantNAICS

    businessContinuityPlans
    commodityHazardClass
    fireProtectionDescription
    leasedOrOwned
    securityDescription

    quakeScenarioEstimatedLoss
    quakeScenarioUpperLoss

    replacementCostMethodology
    replacementCostPerSquareFootage
    replacementCostPerSquareFootageDisplay
    replacementCostPerFloorAreaPartner
    replacementCostPerFloorAreaPartnerDifference

    roofInspectionProgram
    roofImage
    roofSystem
    roofInstallYear
    roofEstimatedReplacementYear

    seismicStatus

    buildingSprinklered
    buildingSprinklerType
    percentageSprinklered

    structuralDescription
    totalInsuredValue
    totalInsuredValueDisplay

    yearBuilt
    yearLastUpgraded
    yearsInPortfolio

    propertyName
    buildingDescription
    seismicHazardClass
    windHazardClass
    distanceToCoast
    baseFloodElevation
    specificOccupancy
    acquisitionOrOccupancyDate
    numberOfStoriesBelowGround
    buildingFootprintClass
    firstFloorHeight
    distanceToNearestBuilding
    constructionTypeCluster
    constructionDescription
    constructionTypeArchipelago
    constructionTypeAIR
    buildingFoundationType
    constructionQuality
    structuralSystemUpgraded
    roofDescription
    roofDeckType
    roofGeometry
    roofParapets
    roofDrainageType
    roofingMaterialAnchorage
    roofDeckAnchorage
    roofSystemAnchorage
    roofEquipmentAnchorage
    rooftopSolarPanels
    wallCladdingSystem
    windowType
    windowProtection
    glassPercentage
    sprinklerPipesSeismicallyBraced
    backupGenerator
    permanentFloodMeasuresPresent
    emergencyFloodProtectionMeasures
    fireProtectionInspectionProgram
    generatorTestingProgram
    owner
    portfolio
    enriched

    liquefactionSusceptibility
    landslideSusceptibility
    siteSoilClassification
    distanceToNearestFault
    hailHazardClass
    tornadoHazardClass
    lightningHazardClass
    wildfireHazardClass
    convectiveStormZone
    wildfireZone
    tsunamiHazardClass
    seismicDesignQuality
    seismicDesignRValue
    roofHailMitigation
    roofSolarPanelOwnership
    roofSolarPanelCoverage
    roofSolarPanelDescription
    naturalGasAutomaticShutoff
    backupGeneratorLocation
    firstFloorElevation

    sprinklerLeakDetectionSystem
    remoteMonitoringOfSprinklerSystem
    waterSupplyFlowRate
    suitabilityOfFireProtectionMeasures
    backupGeneratorDieselStorageCapacity
    naturalGasPipesSeismicallyBraced
    originalAddress

    roofChimneysAnchorage
    rooftopWaterTanks

    contingency
    exclusions
    occupancyDescription

    airEqBrickVeneer
    airEqBuildingCondition
    airEqBuildingExteriorOpening
    airEqBuildingShape
    airEqChimney
    airEqColdFormedTub
    airEqColumnBasementType
    airEqEquipment
    airEqFoundationConnection
    airEqFoundationType
    airEqInternalPartitions
    airEqIsFireSprinklerAvailable
    airEqIsValueType
    airEqLatticeType
    airEqMultiStoryHallType
    airEqOrnamentation
    airEqPounding
    airEqRedundancy
    airEqRetrofitMeasures
    airEqShortColumn
    airEqSoftStory
    airEqSpecialEQResistiveSystems
    airEqStructuralIrregularity
    airEqTallOneStory
    airEqTank
    airEqTorsion
    airEqTransitionInSRCType
    airEqWallSiding
    airEqWallType
    airEqWaterHeater
    airEqWeldingDetail
    airModifiers
    airWindAdjacentBuildingHeight
    airWindAppurtenantStructures
    airWindBaseFloodElevation
    airWindBaseFloodElevationUnit
    airWindBasementFinishType
    airWindBasementLevelCount
    airWindCertifiedStructuresIbhs
    airWindContentVulnerability
    airWindCustomElevation
    airWindCustomElevationUnit
    airWindCustomFloodStandard
    airWindCustomFloodZone
    airWindExteriorDoors
    airWindFirmCompliance
    airWindFirstFloorHeight
    airWindFirstFloorHeightUnit
    airWindFloorOfInterest
    airWindGlassPercentage
    airWindGlassType
    airWindLargeMissiles
    airWindRoofAnchorage
    airWindRoofAttachedStructures
    airWindRoofCovering
    airWindRoofCoveringAttachment
    airWindRoofDeck
    airWindRoofDeckAttachment
    airWindRoofGeometry
    airWindRoofPitch
    airWindRoofYearBuilt
    airWindSealOfApproval
    airWindServiceEquipmentProtection
    airWindSmallDebris
    airWindTerrainRoughness
    airWindTreeExposure
    airWindWallAttachedStructure
    airWindWindowProtection
    rmsEqAppendagesAndOrnamentation
    rmsEqBaseIsolation
    rmsEqConstructionQuality
    rmsEqContentsRateGradeEQ
    rmsEqCrippleWalls
    rmsEqEarthquakeSprinklerCoverageFlag
    rmsEqEngineeredFoundation
    rmsEqEnvelopeOpening
    rmsEqEquipmentEQBracing
    rmsEqEquipmentSupportMaintenance
    rmsEqExteriorWallsCladding
    rmsEqFrameBoltedtoFoundation
    rmsEqPounding
    rmsEqPurlinAnchoringTiltUpRetrofit
    rmsEqRedundancy
    rmsEqShapeConfiguration
    rmsEqShortColumnCondition
    rmsEqSoftStory
    rmsEqSprinklerLeakageSusceptibility
    rmsEqSprinklerType
    rmsEqStructuralUpgradeNonURM
    rmsEqTank
    rmsEqTorsion
    rmsEqUnreinforcedMasonryPartition
    rmsEqUnreinforcedMasonryRetrofit
    rmsEqVerticalIrregularitySetbackandOverhangs
    rmsModifiers
    rmsOtherBIPreparedness
    rmsOtherBIRedundancy
    rmsOtherPercentageContentsBelowGrade
    rmsRoofSheathingAttachment
    rmsWindBIDependency
    rmsWindBasementProtection
    rmsWindCauseOfLossFlag
    rmsWindCladding
    rmsWindCommercialAppurtenantStructures
    rmsWindConstructionQuality
    rmsWindContentsRateGradeWind
    rmsWindContentsVulnerabilityDueToFlood
    rmsWindContentsVulnerabilityDueToWind
    rmsWindCopingAndFlashingQuality
    rmsWindFinishedFloorHeightAboveGround
    rmsWindFinishedFloorHeightMatchLevel
    rmsWindFirstFloorElevation
    rmsWindFloodMissiles
    rmsWindFloodProtection
    rmsWindFloorType
    rmsWindFrameFoundationConnection
    rmsWindGroundLevelEquipment
    rmsWindOpeningProtection
    rmsWindResidentialAppurtenantStructures
    rmsWindResistanceDoors
    rmsWindRoofAge
    rmsWindRoofAnchorage
    rmsWindRoofEquipmentWindBracing
    rmsWindRoofFramingType
    rmsWindRoofGeometry
    rmsWindRoofMaintenance
    rmsWindRoofParapetsChimneys
    rmsWindRoofSystemCovering
    rmsWindVerticalExposureDistribution
    rmsWindWindMissiles

    lastValuationDate
    valuationCurrency

    numberOfUnits
    hasSeismicInspectionReport
    seismicInspectionDate

    orgName
    organizationId
    organizationLevelOne
    organizationLevelTwo
    organizationLevelThree
    organizationLevelFour

    buildingExteriorOpening
    doorAndFrameType
    equipmentEarthquakeBracing
    elevationOfGroundOrBasementLevelMEPEquipment
    ornamentation
    roofScreensSignsAnchorageandBracing
    sovSortOrder

    buildingCondition
    buildingRedundancy
    contentsVulnerabilityFlood
    contentsVulnerabilityVerticalDistributionOfContents
    contentsVulnerabilityWind
    crippleWalls
    eqSpecialSystems
    floodMissiles
    floorSystem
    foundationToFrameConnection
    glassType
    interiorPartitions
    roofCopingAndFlashing
    roofStructures
    shortColumnConcrete
    softStory
    structuralUpgradeType
    surfaceRoughnessWind
    torsion
    verticalIrregularity
    waterHeaterBracing
    windMissiles

    exchangeRate
    exchangeRateDate
    currencyPair

    contingentBusinessInterruptionFlag
    contingentBusinessInterruptionComments
    dependencyFlag
    dependencyCoveragePercentage
    dependencyValue
    dependencyValueDisplay
    dependencyComments
    dispositionDate
    numberOfFireHazardAreas
    highHazardAreaSprinklerType
    sprinklerHeadSize
    sprinklerHeadTemperatureRating
    flammableLiquidDescription
    flammableLiquidStorageLocation
    flammableLiquidCapacity
    flammableLiquidsOnsite
    flammableLiquidProgram
    waterSupplyTime
    firePumpFlowRate
    firePumpPowerSupply
    firePumpBackupPeriod
    firePumpTestResults
    firePumpChurnRate
    firePumpExcessCapacity
    hydrantFlowTestResultsStatic
    hydrantFlowTestResultsResidual
    rackStoragePresent
    heightOfRackStorage
    storageArrangementDescription
    inRackSprinklerType
    hotWorkProgram
    controlOfCombustiblesProgram
    smokingControlsProgram
    valueInspectionProgram
    fireDetectionSystems
    flammableLiquidGasLineManagement
    portableFireExtinguisherProgram
    stockThroughputFactor
    stockThroughputInventoryValue
    stockThroughputInventoryValueDisplay
    stockThroughputExcessInventoryValue
    stockThroughputExcessInventoryValueDisplay

    ownerAttributes {
      ownerDecimal1
      ownerDecimal2
      ownerDecimal3
      ownerDecimal4
      ownerDecimal5
      ownerDecimal6
      ownerDecimal7
      ownerDecimal8
      ownerDecimal9
      ownerDecimal10
      ownerDecimal11
      ownerDecimal12
      ownerDecimal13
      ownerDecimal14
      ownerDecimal15
      ownerDecimal101
      ownerDecimal102
      ownerDecimal103
      ownerDecimal104
      ownerDecimal105
      ownerDecimal106
      ownerDecimal107
      ownerDecimal108
      ownerDecimal109
      ownerDecimal110
      ownerDecimal111
      ownerDecimal112
      ownerDecimal113
      ownerDecimal114
      ownerDecimal115
      ownerDecimal116
      ownerDecimal117
      ownerDecimal118
      ownerDecimal119
      ownerDecimal120
      ownerDecimal121
      ownerDecimal122
      ownerDecimal123
      ownerDecimal124
      ownerDecimal125
      ownerDecimal126
      ownerDecimal127
      ownerDecimal128
      ownerDecimal129
      ownerDecimal130
      ownerText1
      ownerText2
      ownerText3
      ownerText4
      ownerText5
      ownerText6
      ownerText7
      ownerText8
      ownerText9
      ownerText10
      ownerText11
      ownerText12
      ownerText13
      ownerText14
      ownerText15
      ownerText101
      ownerText102
      ownerText103
      ownerText104
      ownerText105
      ownerText106
      ownerText107
      ownerText108
      ownerText109
      ownerText110
      ownerText111
      ownerText112
      ownerText113
      ownerText114
      ownerText115
      ownerText116
      ownerText117
      ownerText118
      ownerText119
      ownerText120
      ownerText121
      ownerText122
      ownerText123
      ownerText124
      ownerText125
      ownerText126
      ownerText127
      ownerText128
      ownerText129
      ownerText130
    }

    consumerAttributes {
      consumerDecimal1
      consumerDecimal2
      consumerDecimal3
      consumerDecimal4
      consumerDecimal5
      consumerDecimal6
      consumerDecimal7
      consumerDecimal8
      consumerDecimal9
      consumerDecimal10
      consumerDecimal11
      consumerDecimal12
      consumerDecimal13
      consumerDecimal14
      consumerDecimal15
      consumerText1
      consumerText2
      consumerText3
      consumerText4
      consumerText5
      consumerText6
      consumerText7
      consumerText8
      consumerText9
      consumerText10
      consumerText11
      consumerText12
      consumerText13
      consumerText14
      consumerText15
    }

    lossAttributes {
      lossCountTotal
      lossCountSinceLastBound
      totalGrossLoss
      largestLoss
    }
  }
`;

export const PROPERTY_WITH_LINKED_DOCUMENT_FRAGMENT = gql`
  ${PROPERTY_FRAGMENT}
  fragment propertyWithLinkedDocuments on PropertyWithLinkedDocuments {
    property {
      ...property
    }
    linkedDocuments {
      id
      httpURL
      filename
      extension
      contentType
      fileType
      tags
      classification
      sourcedAttributes {
        name
        displayName
        source
        parent
        dataType
        filterable
        groupable
      }
      sizeInBytes
      createdAt
      createdBy {
        userID
        email
      }
    }
    attributeComments {
      attributeName
      comments {
        message
        userID
        givenName
        familyName
        createdAt
      }
    }
    sharedWithPartialGroupDocuments
    exclusiveDocuments
  }
`;
